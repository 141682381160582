import React from "react";
import papers from '../../config/researchs'

export default function ArticlePd() {
  return (
    <div>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto">
            <a href="/">
              <h2>Textplor</h2>
            </a>
          </h1>
        </div>
      </header>
      <section className="d-flex align-items-center">
        <div className="container" data-aos="fade-up" style={{width:'90%'}}>
            <div className="row prod-detail">
              <h3 className="center center-align">
              Predicting Corporate Distress: A Textual Analysis
              </h3>  
            </div>
            <div className="row">
            <div className="col s12">
              <div className="col s4">
                <div className="row">
                  <div className="col s4">
                    <img 
                      type="image"
                      className="circle"
                      height="70"
                      width="70"
                      src="assets/img/team/ashok.jpg"alt=""/>
                  </div>
                  <div className="col s6"> 
                    <span><strong>Ashok Banerjee</strong></span>
                    <br/>
                    <span><strong>21 Aug 2021</strong></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                      <span className="mybadge-info">Textplor</span>
                      <span className="mybadge-primary">Annual Report Analysis</span>
                      <span className="mybadge-warning">Finance</span>
                  </div>
                </div>
              </div>
              <div className="col s8">
                <h5>
                    TIt uses textual information from corporate annual reports to predict distress (PD) 
                    and finds that PD has a predictive power for eventual downgrade/ upgrade in credit ratings.
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <h2>
              Predicting Corporate Distress: A Textual Analysis
            </h2>
            <p>
                We look at firm-level news in this paper and hence ignore any news on economy in general.
                Since news is sourced from business dailies (e.g Economic Times, Mint, Money Control etc.),
                NIS does not capture sentiments of any general, value-neutral news. We have looked at top
                500 NS-listed companies (NSE 500 Index) and analysed the NIS data for these companies over
                11 years (2010 to 2020).We have removed companies for which stock price data were not
                1 Geert Bekaert, and Guojun Wu. Asymmetric Volatility and Risk in Finance Markets. 
                The Review of Finance available, leaving us with 399 of the Nifty 500 companies. 
                These companies are sorted on the basis of aggregate news frequency in a particular year and 
                the top decile represents top ten percent companies which are heavily reported in the media in that year.
            </p>
          </div>
          <div className="row">
            <h2>
            Data and Methodology:
            </h2>
            <p>
              Our initial data-set consisted of annual reports of both public and privately held 
              companies operating and registered in India. We have selected the companies functioning 
              in around 36 different sectors except financial and insurance sector. 
              Due to special business nature and financial structure, insurance and banking sector 
              firms are excluded. We followed two-stage approach to estimate a probability of 
              distress (PD) from text of any annual report. First, we used natural language processing 
              (NLP) to extract sentiment scores. Second, we used a logistic regression to convert 
              sentiment scores to a probability estimate of distress, which we call PD.
        
            </p>
          </div>
          <div className="row">
            <h2>
            Results and Analysis:
            </h2>
            <p>
              PD is negatively correlated with Credit Ratings and ROI. High PD means higher probability of distress, 
              which obviously means credit ratings would fall and so will the return on investment (ROI). Also, PD is
              positively correlated with Debt-Equity ratio, which is again ideally correct.
              <br/>
              <br/>
              There are two types of effects in a panel model: Fixed or Random effect. 
              A model is a fixed effect model if the variables are constant across individuals, random otherwise.
              To check which effect is best suited for the given data, we have conducted the Hausman test. 
              Results show that it is better to use random effect models
              <br/>
              The rising corporate debt and higher default rates have led to a continuous increase 
              in distressed loans in Indian financial system. Our system uses textual information
              from corporate annual reports to predict distress (PD) and finds that PD has a predictive 
              power for eventual downgrade/ upgrade in credit ratings.
            </p>
          </div>
          <div className="row">
            <a href={papers.PD}
              target="_blank"
              rel="noopener noreferrer"
              >Read more...</a>
          </div>
        </div>
      </section>
      <a href="#" className="back-to-top">
        <i className="ri-arrow-up-line"></i>
      </a>
    </div>
  );
}
