import React from "react";
import "./style.css";

export const TimelineHorizontal = ({ data }) => {
  return (
    <div className="col s12 m12 l12">
      <div className="t-container">
        {data.map((d, i) => (
          <div
            className="t-box"
            style={
              i < data.length - 1
                ? d?.auditFirm?.id != data[i + 1]?.auditFirm?.id
                  ? { backgroundColor: "#2d49a6", color: "white" }
                  : {}
                : {}
            }
          >
            {/* {i<data.length-1
                    ?d.auditFirm.id != data[i+1].auditFirm.id?<p>Auditor Change</p>
                    :null
                    :null} */}
            <h5>{d.year}</h5>
            <p style={{ whiteSpace: "break-spaces" }}>
              {d?.auditFirm?.name ? d?.auditFirm?.name : "Not Available"}
            </p>
            <p style={{ whiteSpace: "break-spaces" }}>
              {d?.auditPartner?.name ? d?.auditPartner?.name : "Not Available"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
