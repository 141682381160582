import React from "react";
import { useHistory } from "react-router";
import M from 'materialize-css'
import { ToolCard } from "./ToolCard";
import Description from "./DescriptionModal";

export default function Stocks() {
  const history = useHistory();
  const [setTool, SetTool] = React.useState(null)

  const onSubmit = (e) => {
    // e.target.reset();
    history.push({
      pathname: "/SignIn",
    });
  };
  const onRegister = (e) => {
    // e.target.reset();
    history.push({
      pathname: "/register",
    });
  };

  const ModalClick = (toolId) =>{
    SetTool(toolId)
  }

  React.useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { dismissible: false });
  },[])
  return (
    <div>
    <Description toolId={setTool}/>
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto">
          <a href="/">
            <h2>Textplor</h2>
          </a>
        </h1>
      </div>
    </header>
    <section className="d-flex align-items-center">
      <div className="container" data-aos="fade-up" style={{width:'90%'}}>
          <div className="row prod-detail">
            <h3 className="center center-align">Hedge Fund Dashboard</h3>
            <div className="row  prod-detail">
              <ToolCard
                ModalClick={ModalClick} 
                text={"Stock Pulse"}
                toolId={"stock pulse"}
                color={"#bf750d"} 
                image={"candlestick.png"} 
                modal="description-modal"
              />
              <ToolCard
                ModalClick={ModalClick}
                text={"News Impact"}
                toolId={"news impact"}
                color={"#2d49a6"} 
                image={"line-bar.jpg"} 
                modal="description-modal"
              />
              
              <ToolCard
                ModalClick={ModalClick}
                text={"Corporate Announcements"}
                toolId={"announcement"}
                color={"#bfbf0d"} 
                image={"order-1.jpg"} 
                modal="description-modal"
              />

              <ToolCard
                ModalClick={ModalClick}
                toolId={"audit-matter"}
                text={"Audit Matters"}
                color={"#2d49a6"} 
                image={"account-1.jpg"} 
                modal="description-modal"
              />

              <ToolCard
                ModalClick={ModalClick} 
                text={"Concall"}
                toolId={"concall"}
                color={"#5d1b8f"} 
                image={"call-1.jpg"} 
                modal="description-modal"
              />

              <ToolCard
                ModalClick={ModalClick} 
                text={"Sector Meter"}
                toolId={"sector meter"}
                color={"#3f8c23"} 
                image={"speedometer.png"} 
                modal="description-modal"
              />
            </div>
        </div>
      </div>
    </section>
    <main id="main">
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/stock.gif"
                  className="img-fluid"
                  alt=""
                  style={{ width: 900 }}
                />
              </div>
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Stock Pulse</h3>
                <p className="font-italic">
                Stock pulse does not recommend any buy, hold, or sell decisions. It simply classifies a news in terms of its likely impact on the future share price
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Captures stock market related news on Companies / Sector /overall Market
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Real time analysis
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Generates sentiment of news in three unambiguous categories
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Also captures data on brokers, and borking houses
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Full text of a news item can be viewed.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>No recommendation- only sentiments.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      <section id="about-video" className="about-video">
        <div className="container" data-aos="fade-up">
        <div className="row">
          <div
            className="col s12 m6 l6 prod-detail"
            data-aos="fade-left"
            data-aos-delay="100"
            style={{ paddingLeft: 20 }}
          >
            <h3>News Impact Score</h3>
            <p className="font-italic">
              Aggregates news sentiments for any company from online
              structured news of reliable media house.
            </p>
            <ul>
              <li>
                <i className="bx bx-check-double"></i>There is a saying that ‘no
                news is a good news’
              </li>
              <li>
                <i className="bx bx-check-double"></i>NIS tracks every news
                about a company that appears in the media (excluding social
                media and TV).
              </li>
              <li>
                <i className="bx bx-check-double"></i> It assigns sentiments to
                every news article that is relevant for a given company and
                then aggregates at daily, weekly or monthly level.
              </li>
              <li>
                <i className="bx bx-check-double"></i> Every article is also
                checked for its novelty (uniqueness).
              </li>
              <li>
                <i className="bx bx-check-double"></i> Once relevance and
                novelty is ensured, NIS estimates sentiments for every news
                article.
              </li>
              <li>
                <i className="bx bx-check-double"></i> We use cutting edge
                machine learning techniques to generate sentiment scores.
              </li>
            </ul>
          </div>
          <div
            className="col s12 l6 m6"
            data-aos="fade-right"
            data-aos-delay="100"
            style={{ paddingTop: 10 }}
          >
            <img src="assets/img/nis-sc.jpeg" className="img-fluid" alt="" />
          </div>
        </div>
        </div>
      </section>
      
      <section id="about-video" className="about-video">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col s12 l6 m6"
              data-aos="fade-right"
              data-aos-delay="100"
              style={{ paddingTop: 10 }}
            >
              <img src="assets/img/announcement-sc.jpeg" className="img-fluid" alt=""/>
            </div>
            <div
              className="col s12 m6 l6 prod-detail"
              data-aos="fade-left"
              data-aos-delay="100"
              style={{ paddingLeft: 20 }}
            >
              <h3>Corporate Announcement</h3>
              <p className="font-italic">
              It captures all the announcement filings with the stock exchanges as and when these are filed with the exchange
              </p>
              <ul>
                <li>
                  <i className="bx bx-check-double"></i>Captures all announcements by listed companies
                </li>
                <li>
                  <i className="bx bx-check-double"></i>Identifies a topic for every announcement
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Labels every announcement in terms of importance- HIGH, MODERATE and LOW
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Users can choose one or more topics for a company for a given period.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> If required, users can access the full text of any corporate action.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <img src="assets/img/audit-matter-sc.png" className="img-fluid" alt="" />
              </div>

              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Audit Matters</h3>
                <p className="font-italic">
                Explores audit reports to highlight essential information 
                and uses deep learning to identify audit
                alerts.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Name of auditors
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Peer companies under same auditor
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Change of auditor alert
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Audit report alerts
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/concall-sc-1.jpeg"
                  className="img-fluid"
                  alt=""
                  style={{ width: 900 }}
                />
              </div>
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Concall</h3>
                <p className="font-italic">
                  Captures Concall annoucement data with importrant set of sentences.
                  Sentimantal score of concall report
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>Grpahical Represent of over all Quarte
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Impotant sentence tagging
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Peer group analysis
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Sentimental score
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="about-video" className="about-video">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-right"
                data-aos-delay="100"
                style={{ paddingTop: 10 }}
              >
                <img
                  src="assets/img/sm.gif"
                  className="img-fluid"
                  alt=""
                  style={{ width: 900 }}
                />
              </div>
              <div
                className="col s12 m6 l6 prod-detail"
                data-aos="fade-left"
                data-aos-delay="100"
                style={{ paddingLeft: 20 }}
              >
                <h3>Sector Meter</h3>
                <p className="font-italic">
                  Captures sentiment of a sector using online news about a
                  sector. SM indicates stress of a sector.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-check-double"></i>The Sector Meter captures
                    the heartbeat of a sector.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>The stress level of each
                    sector varies.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>It uses news on sectors
                    and also leading companies within a sector.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>SM uses machine learning
                    technique to generate sentiment scores of a sector.
                  </li>
                  <li>
                    <i className="bx bx-check-double"></i>Higher the value of SM,
                    greater is the expected vulnerability of the sector
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div className="faq-list">
            <ul>
            <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i>{" "}
                <a data-toggle="collapse" className="collapsed" href="#faq-list-4">
                  FAQ - Stock Pulse <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-4"
                  className="collapse"
                  data-parent=".faq-list"
                >
                  <p><strong> What does stock pulse do? </strong>	</p>
                  <p>Stock pulse does not recommend any buy, hold,
                     or sell decisions. It simply classifies a news 
                     in terms of its likely impact on the future share price. 
                  </p>
                  <p><strong>How does stock pulse work?  </strong>	</p>
                  <p>Stock pulse screens all stock market related news about a company, a sector,
                     and even the market in general on real time basis. Using a proprietary algorithm, 
                     it generates any of three sentiments for a news- Bullish, Neutral, and Bearish.
                     It also provides information on brokers and analysts’ view on a particular stock or sector.  
                  </p>

                  <p><strong>	How does one use stock pulse? </strong>	</p>
                  <p>One can search market news about a company or a sector or even from a broker. 
                    For example, if a user wants to follow a particular broker’s recommendations, 
                    he/she can do that by selecting the broker’s name and the time period. Similarly, 
                    if one would like to see the sentiments for a stock over the past six months, 
                    the tool allows that option as well. 
                  </p>

                  <p><strong> What is the breadth and depth of news that feature in stock pulse?  </strong>	</p>
                  <p>Stock pulse uses news from multiple sources, excluding social media. 
                    Since there is a possibility of a repeat news, one can use novelty filter to screen out 
                    repeat news and thus look for sentiments for breaking news only. Stock pulse indicator
                     provides information on real time basis with minimum latency. This will help any investor 
                     to remain always on top of any price-sensitive news and can make informed decisions.
                      Stock pulse covers news on all listed companies in India. 
                  </p>
                  
                  <p><strong> Why is stock pulse not a recommending tool? </strong>	</p>
                  <p>Textplor provides textual information with sentiment scores or labels that help any investors, 
                    bankers, rating agencies, analysts, and regulators make informed decisions. 
                    Stock pulse, therefore, provides appropriate sentiments behind a news and allows users 
                    to make decisions. Stock pulse does not take responsibility for any loss suffered 
                    by a user in acting on the sentiment labels generated by the tool.
                  </p>
                </div>  
              </li>

              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i>{" "}
                <a data-toggle="collapse" className="collapsed" href="#faq-list-2">
                  FAQ - News Impact  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-2"
                  className="collapse"
                  data-parent=".faq-list"
                >
                  <p><strong> What is News Impact Score? </strong>	</p>
                  <p>News Impact Score (NIS) captures any positive and negative information about a company.
                     Such information could be in terms of business growth (positive), higher profitability (positive), 
                     financial distress (negative), deceleration of growth (negative), losses (negative), 
                     factory lockdown (negative). NIS aggregates such scores at the article level- higher the score, 
                     more severe is the negative impact. NIS can be correlated with several indicators such as, a firm’s profitability,
                    and credit rating.
                  </p>
                  <p><strong>How reliable are the news sources? </strong>	</p>
                  <p>NIS uses newspaper articles of reliable media houses and does not use social media text. 
                  </p>

                  <p><strong> What is the frequency of NIS?</strong>	</p>
                  <p>NIS can be generated at three frequency level- daily, weekly, and monthly.
                  </p>

                  <p><strong>	What is the period for which NIS data is available?  </strong>	</p>
                  <p>NIS for every company is available since 2010. 
                  </p>
                  
                  <p><strong>	Does NIS tool generate scores for all companies registered in India?</strong>	</p>
                  <p> Textplor presently provides NIS of all listed companies
                  </p>
                </div>  
              </li>

              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i>{" "}
                <a data-toggle="collapse" className="collapsed" href="#faq-list-3">
                  FAQ - Sector Meter<i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-3"
                  className="collapse"
                  data-parent=".faq-list"
                >
                  <p><strong> What is Sector meter?  </strong>	</p>
                  <p>Sector Meter (SM) captures the sentiment of a sector using online news from reliable media houses.
                    It uses natural language processing (NLP) techniques and artificial intelligence (AI) to extract
                    sentiments out of news text. Higher the value of SM, greater the expected vulnerability of the sector. 
                  </p>
                  <p><strong>How can SM be profitably used? </strong>	</p>
                  <p>One can combine NIS with SM to check vulnerability of a company, given the stress in a given sector.
                    For example, if the SM score of real estate sector is high, it is quite normal to expect high NIS for
                    real estate firms. However, if a real estate firm shows lower (negative) value of NIS, it indicates 
                    the firm’s strong financial position.  
                  </p>

                  <p><strong> How long is the time period for which SM information is available? </strong>	</p>
                  <p>SM is available at daily, weekly and monthly frequencies since 2010. 
                  </p>

                  <p><strong> How can one analyse SM? </strong>	</p>
                  <p>One can look at a time series (say, monthly) of SM scores for a sector and understand
                     how the scores have changed over time. Lenders can use SM for stress 
                     testing its exposure to a sector. Any long-term institutional investor
                      may rebalance its exposure to a sector on the basis of changes in SM score over time. 
                  </p>
                  
                  <p><strong> How many sectors are covered in SM?</strong>	</p>
                  <p>SM tool covers thirty-five sectors, including banking, agriculture, IT &ITES, capital goods.
                  </p>
                </div>  
              </li>

              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i>{" "}
                <a data-toggle="collapse" className="collapsed" href="#faq-list-5">
                  FAQ - Corporate Announcement <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-5"
                  className="collapse"
                  data-parent=".faq-list"
                >
                  <p><strong>	What does it capture?  </strong>	</p>
                  <p>It captures all the announcement filings with the stock exchanges as and when these are filed with the exchange. 
                    So, this tool presents in one place all the corporate actions and other filings by listed companies in India.
                  </p>
                  <p><strong> How useful is this tool?  </strong>	</p>
                  <p>Not only does it list all the announcements in one place,
                    the tool also classifies each announcement into relevant topics and importance.
                  </p>

                  <p><strong>How does the tool work?</strong>	</p>
                  <p>One can search market news about a company or a sector or even from a broker. 
                  The tool processes text of each corporate filing using NLP and AI to identify topics and
                   then uses a proprietary machine learning algorithm to label importance (High, Moderate, and Low) to each announcement.  
                  </p>

                  <p><strong>	Who are the possible users? </strong>	</p>
                  <p>Corporate announcements tool could be of great use to mutual fund managers and other long-term institutional investors
                     who can take action on the basis of price sensitive corporate announcements almost on real time.
                  </p>
                  
                  <p><strong>	How does it benefit any user? </strong>	</p>
                  <p>The tool saves lot of time for the user as one can only concentrate on highly 
                    important filings and read the entire text for those items. 
                    This obviates the need to read each and every announcement. 
                  </p>
                </div>  
              </li>

              <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a data-toggle="collapse" className="collapsed" href="#faq-list-5">
                    FAQ - Audit Matter <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-parent=".faq-list"
                  >
                    <p><strong>	What does it capture?  </strong>	</p>
                    <p>It captures different fees, values, auditor changes from the annual 
                      Reports of companies. Gives alert on Change of auditor and partner.
                      It also shows alert sentences from auditors report
                    </p>
                    <p><strong> How useful is this tool?  </strong>	</p>
                    <p>Not only this shows the data from one report it simultaneously show the
                      changes and alert over different year of audit report
                    </p>

                    <p><strong>How does the tool work?</strong>	</p>
                    <p>The tool capture the financial informations from annual reports with help of
                      NLP and stores 10 years of data for over 5000 companies to give a clear view of companies financial.
                      Also, the tool uses state of the art deep learning techniques to spot the alerts in a audit report
                    </p>

                    <p><strong>	Who are the possible users? </strong>	</p>
                    <p>auditor, accounting firms, lenders and investors are potential  users
                    </p>
                    
                    <p><strong>	How does it benefit any user? </strong>	</p>
                    <p>The tool saves lot of time for the user as one can only concentrate on highly 
                      important filings and read the entire text for ARs. 
                      This obviates the need to read each and every AR. 
                    </p>
                  </div>  
                </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <section id="about-video" className="about-video">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Prospective buyers</h2>
          </div>
          <div className="row" style={{display:"flex"}}>
            <div
              className="col-lg-6 pt-3 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h3>WHO?</h3>
              <ul>
                <li>
                  <i className="bx bx-check-double"></i>Lenders
                </li>
                <li>
                  <i className="bx bx-check-double"></i>Nonbanking financial
                  companies
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Mutual fund companies.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Insurance companies.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Analyst (rating agency,
                  equity analyst)
                </li>
                <li>
                  <i className="bx bx-check-double"></i>Regulatory authorities
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 pt-3 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
              style={{paddingLeft:110}}
            >
              <h3>WHY?</h3>
              <ul>
                <li>
                  <i className="bx bx-check-double"></i>Shows independent news
                  from trusted sources from 2010.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>Ability to filter relevant
                  and unique news.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Understand news
                  sentiments
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Flexibility in analysis.
                  Allow users to define parameters.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Analysts can spend more
                  time on analysis rather than searching news
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Vixplor Analytics Private Limited</p>
          </div>

          {/* <div>
            <iframe
              style={{ border: 0, width: "100%", height: "270px" }}
              src="https://www.google.com/maps/place/Vixplor+Analytics+Private+Limited/@22.5697023,88.4339727,17z/data=!4m12!1m6!3m5!1s0x3a0275abee2bb7a3:0x3dc193eb3c24f60b!2sVixplor+Analytics+Private+Limited!8m2!3d22.5697023!4d88.4361614!3m4!1s0x3a0275abee2bb7a3:0x3dc193eb3c24f60b!8m2!3d22.5697023!4d88.4361614"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div> */}

          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="icofont-google-map"></i>
                  <h4>Location:</h4>
                  <p>
                    Webel Bhaban, Block: EP & GP, Sector V, Salt Lake,
                    Kolkata: 700091{" "}
                  </p>
                </div>

                <div className="email">
                  <i className="icofont-envelope"></i>
                  <h4>Email:</h4>
                  <p>contact@vixplor.com </p>
                </div>

                <div className="phone">
                  <i className="icofont-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 98315 22750</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col s12 m6 l6 footer-contact">
              <h3>Vixplor Analytics Pvt. Ltd.</h3>
              <p>
                Webel Bhaban, Block: EP & GP <br />
                Sector V, Salt Lake
                <br />
                Kolkata: 700091 <br />
                <br />
                <strong>Phone:</strong> +91 98315 22750
                <br />
                <strong>Email:</strong> contact@vixplor.com
                <br />
              </p>
            </div>
            <div
              className="col s12 m6 l6 footer-links"
              style={{ paddingTop: 9, paddingRight: 350 }}
            >
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a onClick={onSubmit}>SignIn</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a onClick={onRegister}>Register</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="mr-md-auto text-center text-md-left">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <a href="http://www.vixplor.com/">
                Vixplor Analytics Pvt. Ltd.
              </a>
            </strong>
            . All Rights Reserved
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="http://www.vixplor.com/" className="home">
            <i className="bx bxs-home"></i>
          </a>
          <a href="https://www.facebook.com/Vixplor/" className="facebook">
            <i className="bx bxl-facebook"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/vixplor/?originalSubdomain=in"
            className="linkedin"
          >
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
      </div>
    </footer>

    <a href="#" className="back-to-top">
      <i className="ri-arrow-up-line"></i>
    </a>
  </div>
  );
}
