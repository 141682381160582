import React from "react";

export const StatTable = (props) => {
  return (
    <div>
    <table className="highlight responsive-table">
        <thead style={{display:'block'}}>
          <tr>
            {
                props.headers.map(h=>
                    <th style={{width:h.width?h.width:200}}>
                        {h.name}
                    </th>
                )
            }
            {props.action&&
            <th style={{width:200}}>
                Action
            </th>}
          </tr>
        </thead>

        <tbody style={{display:'block', overflow:'auto', height:props.height?props.height:300}}>
          {
              props.data&&props.data.map(d =>
                <tr>
                    {
                        props.headers.map(h=>
                          <td style={{width:h.width?h.width:200}}>{ h.f?h.f(d):""}</td> 
                        )
                    }
                    {props.action&&
                    <td style={{width:200}}>
                      <button data-target="crawlerModal" 
                      className="btn-floating btn-small waves-effect waves-light modal-trigger"
                      style={{backgroundColor:'#124265'}}
                      >
                        <i className="material-icons">chevron_right</i>
                      </button>
                    </td>}
                </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};
