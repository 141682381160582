import config from "../../config/config"
import createFormData from "../../help/CreateFormData";

var backendUrl = config.backendUrl

export const getConcallSecCompanies = (comId, token) => {
    return dispatch => {
    //   dispatch({type: 'LOADING_BROKER'});
      var url = backendUrl + "/company/"+comId+"/secondaries";
      fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "CONCALL_SECONDARY_FETCH_SUCCESS",
              payload: data
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "CONCALL_SECONDARY_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "CONCALL_SECONDARY_FETCH_ERROR"
        });
      });
    };
  };

export const updateConcallCompany = (com, secs, token) => {
    return dispatch => {
      dispatch({type: 'CONCALL_SECONDARY_UPDATE_LOADING'});
      var url = backendUrl + "/company/"+com+"/secondaries";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({secondaries:secs})
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            // data['body'] = body
            // //console.log("action", data)
            dispatch({
              type: "CONCALL_SECONDARY_UPDATE_SUCCESS",
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "CONCALL_SECONDARY_UPDATE_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "CONCALL_SECONDARY_UPDATE_ERROR"
        });
      });
    };
  };

  export const getConcallScores = (comId,secs,  token) => {
    return dispatch => {
      dispatch({type: 'CONCALL_SCORE_FETCH_LOADING'});
      var url = backendUrl + "/company/"+comId+"/concall";
      fetch(url,{
        method: "post",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({secondaries:secs})
      })
      .then(response =>
        response.json().then(data => {
          if (response.status === 200) {
            // //console.log("action", data)
            dispatch({
              type: "CONCALL_SCORE_FETCH_SUCCESS",
              sec: data.scoreSecondary,
              pri: data.scorePrimary,
            });
          } 
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: "CONCALL_SCORE_FETCH_ERROR"
            });
          }
        })
      )
      .catch(err =>{
        dispatch({
          type: "CONCALL_SCORE_FETCH_ERROR"
        });
      });
    };
  };

  export const getConcallScoreFile = (file, token)=> {
    return (dispatch) => {
      dispatch({type: 'CONCALL_SCORE_FETCH_LOADING'});
      fetch(backendUrl + "/getConcallReportScore", {
        method: 'post',
        body:createFormData(file,{}),
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'CONCALL_SCORE_FETCH_SUCCESS',
              sec: null,
              pri: [data.scorePrimary],
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'CONCALL_SCORE_FETCH_ERROR',
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'CONCALL_SCORE_FETCH_ERROR',
      });
    })
  };
  };

  