const initState = {
  stockLoading: "",
  stockSuccess: "",
  NewsSTockSuccess: "",
  stockError: "",
  stock: [],
  brokerSuccess: "",
  brokerError: "",
  broker: [],
  pieChartSuccess: "",
  pieChartError: "",
  chartData: [],
  initData: null,
  loadingSecCompanies: false,
  secCompaniesSuccess: false,
  secCompaniesError: false,
  secCompanies: [],

  stockTweetLoading: false,
  stockTweetSuccess: false,
  NewsSTockSuccess: false,
  stockTweetError: false,
  stockTweet: [],
};

const stockPulseReducer = (state = initState, action) => {
  switch (action.type) {
    case "BROKER_FETCH_SUCCESS":
      return {
        ...state,
        brokerSuccess: true,
        brokerError: "",
        broker: action.payload,
      };

    case "BROKER_FETCH_ERROR":
      return {
        ...state,
        brokerSuccess: "",
        brokerError: true,
        broker: [],
      };

    case "LOADING_STOCK":
      return {
        ...state,
        stockLoading: true,
        stockSuccess: false,
        stockError: false,
      };
    case "STOCK_FETCH_SUCCESS":
      return {
        ...state,
        stockLoading: false,
        stockSuccess: true,
        NewsSTockSuccess: true,
        stockError: false,
        stock: action.payload,
        initData: action.payload.body,
      };

    case "STOCK_FETCH_ERROR":
      return {
        ...state,
        stockLoading: false,
        stockSuccess: false,
        stockError: true,
        stock: [],
      };
      
    case "STOCK_TWEET_FETCH_LOADING":
      return {
        ...state,
        stockTweetLoading: true,
        stockTweetSuccess: false,
        stockTweetError: false,
      };
    case "STOCK_TWEET_FETCH_SUCCESS":
      return {
        ...state,
        stockTweetLoading: false,
        stockTweetSuccess: true,
        NewsSTockSuccess: true,
        stockTweetError: false,
        stockTweet: action.payload,
        initData: action.payload.body,
      };

    case "STOCK_TWEET_FETCH_ERROR":
      return {
        ...state,
        stockTweetLoading: false,
        stockTweetSuccess: false,
        stockTweetError: true,
        stockTweet: [],
      };
    case "LOADING_SEC_COMPANIES":
      return {
        ...state,
        loadingSecCompanies: true,
        secCompaniesSuccess: false,
        secCompaniesError: false,
        secCompanies: [],
      };
    case "SEC_COMPANIES_FETCH_SUCCESS":
      return {
        ...state,
        loadingSecCompanies: false,
        secCompaniesSuccess: true,
        secCompaniesError: false,
        secCompanies: action.payload.data,
      };

    case "SEC_COMPANIES_FETCH_ERROR":
      return {
        ...state,
        loadingSecCompanies: false,
        secCompaniesSuccess: false,
        secCompaniesError: true,
        secCompanies: [],
      };
    case "PIE_CHART_SUCCESS":
      return {
        ...state,
        pieChartSuccess: true,
        pieChartError: false,
        chartData: action.payload,
      };
    case "PIE_CHART_ERROR":
      return {
        ...state,
        pieChartSuccess: false,
        pieChartError: true,
      };
    case "HOME_CLICK":
      return initState;
    default:
      return state;
  }
};

export default stockPulseReducer;
