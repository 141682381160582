import React, { Component } from "react";
import * as d3 from "d3";
import moment from "moment"

class LineBarChart extends Component {
  componentDidUpdate() {
    //console.log(this.props);
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
    // //console.log(this.props.dataset)
  }

  drawChart() {
    const {dataset, can_width, can_height, onClick, selected, name, bgcolor,lineColor} = this.props
    
    // Calculate Margins and canvas dimensions
    var margin = {top: 40, right: 40, bottom: 90, left: 60},
        width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    //Parsers and Formaters
    var parseTime = d3.timeParse("%d/%m/%Y");
    // var formatTime = d3.timeFormat("%a/%b/%Y");
    
    // Scales
    var x = d3.scaleTime()
        .range([0, width]);
    
    var y = d3.scaleLinear()
        .range([height, 0]);

    var y2 = d3.scaleLinear()
        .range([height, 0]);
    
    // Line
    var line = d3.line()
        .x(function(d) { return x(parseTime(d.date)); })
        .y(function(d) { return y(d.nis); })
    
    //Remove already redered graph otherwise repeat graph will be created
    d3.select("#svg-element")
      .select("svg")
      .remove();
    
    var selectedData = selected?selected: dataset[0]

    var chart = d3.select("#svg-element").append("svg")
        .style("background-color", bgcolor?bgcolor: '#F5F6CE')
        .attr("width", can_width+80)
        .attr("height", can_height)
        .attr("fill", 'none')
        .attr("line-stroke", 'blue')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
     
     //Arguments for axes : Ranges for X, y  
     x.domain(d3.extent(dataset, function(d) { return parseTime(d.date); }));
     y.domain(d3.extent(dataset, function(d) { return d.nis; }));
     y2.domain([0,d3.max(dataset.map(d=>{return d.count})) ])

    
    // Axes
      chart.append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(10))
          .selectAll("text")	
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-65)")
            .style("font-size", "12px")
            .style("fill", "#000");
    
      chart.append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisLeft(y));

      chart.append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisRight(y2))
          .attr("transform", "translate("+ (can_width-margin.right)  + ", 0)")

    // Labels
    chart.append("text")
    .attr("text-anchor", "middle")
    .style("font-size", "14px")
    .style("stroke", lineColor?lineColor:'#01579B')
    .attr("transform", "translate("+ (margin.left - 94 ) +","+(height/2)+")rotate(-90)")  
    .text(name);

    chart.append("text")
    .attr("text-anchor", "middle")
    .style("font-size", "14px")
    .style("stroke", "steelblue")
    .attr("transform", "translate("+ can_width +","+(height/2)+")rotate(-90)")  
    .text("News Count");

    chart.append("text")
        .style("font-size", "14px")
        .attr("text-anchor", "middle") 
        .style("stroke", "#000")
        .attr("transform", "translate("+ (width/2) +","+(height-(margin.bottom -170))+")")
        .text("Date");


  // bars

    chart.append("g")
    .attr("fill", "steelblue")
    .attr("fill-opacity", 1)
      .selectAll("rect")
      .data(dataset)
      .join("rect")
      .attr("x", d => x(parseTime(d.date)))
      .attr("width", Math.ceil((width/ dataset.length)/2))
      .attr("y", d => y2(d.count))
      .attr("height", d => y2(0) - y2(d.count))
      .style("pointer-events", "all")
      .on('click', mouseclick)
      .append('title')
        .text(d=> "Date: " + d.date + "\nScore: " + d.nis.toFixed(2) + "\nCount: "+ d.count);

    // Data Lines:

    chart.append("path")
        .datum(dataset)
        .attr("class", "line")
        .attr('stroke', lineColor?lineColor:'#01579B')
        .attr('stroke-width', 4)
        .attr("d", line)
        .enter()
        ;


    function mouseclick(e) {
      selectedData = e
      onClick(e.date, e)
    }

  }

  render() {
    return <div id="svg-element"style={{marginLeft:0}}></div>;
  }
}
export default LineBarChart;