import React from "react";

export default function AuditMyDashboard({ auditData, secAuditData }) {
  return (
    <div>
         <div className="col s12">
            <table className="tableFixed">
                <thead>
                    <tr>
                    <th>Company Name</th>
                    <th>Leverage</th>
                    <th>Profitability</th>
                    <th>Efficiency</th>
                    <th>Intangibility</th>
                    </tr>
                </thead>
                <tbody style={{height:150}}>
                    <tr>
                    <td style={{backgroundColor:'#e0e7ff', fontWeight:'bold'}}>{auditData.Company.name }</td>
                    <td style={{backgroundColor:'#e0e7ff', fontWeight:'bold'}}>{auditData.total_asset_s&&auditData.total_asset_s!=0?Number(auditData.borrowing_s/auditData.total_asset_s).toFixed(2):0 }</td>
                    <td style={{backgroundColor:'#e0e7ff', fontWeight:'bold'}}>{auditData.total_income_s&&auditData.total_income_s!=0?Number(auditData.profit_before_tax_s/auditData.total_income_s).toFixed(2):0 }</td>
                    <td style={{backgroundColor:'#e0e7ff', fontWeight:'bold'}}>{auditData.total_asset_s&&auditData.total_asset_s!=0?Number(auditData.total_income_s/auditData.total_asset_s).toFixed(2):0 }</td>
                    <td style={{backgroundColor:'#e0e7ff', fontWeight:'bold'}}>{auditData.total_asset_s&&auditData.goodwill_s&&auditData.intangibles_s&&auditData.total_asset_s!=0?Number((auditData.goodwill_s+auditData.intangibles_s)/auditData.total_asset_s).toFixed(2):0 }</td>
                    </tr>
                    {
                    secAuditData&&secAuditData.map(d => 
                        <tr>
                        <td>{d.Company.name }</td>
                        <td>{d.total_asset_s&&d.total_asset_s!=0? Number(d.borrowing_s/d.total_asset_s).toFixed(2) :0 }</td>
                        <td>{d.total_income_s&&d.total_income_s!=0?Number(d.profit_before_tax_s/d.total_income_s).toFixed(2):0 }</td>
                        <td>{d.total_asset_s&&d.total_asset_s!=0?Number(d.total_income_s/d.total_asset_s).toFixed(2):0 }</td>
                        <td>{d.total_asset_s&&d.goodwill_s&&d.intangibles_s&&d.total_asset_s!=0?Number((d.goodwill_s+d.intangibles_s)/d.total_asset_s).toFixed(2):0 }</td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>
    </div>
  );
}
