import React from 'react'
import "./style.css";

export default function PDTable({data, secData, pCom}) {
    // //console.log(data);
    return (
        <div> 
            <div className="row left-align" style={{borderBottom:'solid', fontWeight:'bolder', marginTop:10, marginBottom:5, marginLeft:0}}>
                Annual Report
            </div> 
            <table className="pdTable" style={{marginLeft:0, fontSize:14}}>
                <tbody>
                    <tr>
                        <td style={{color:"#5d1b8f"}}>
                            <b>Year</b>
                        </td>
                        {
                            data.map(col => 
                                <td key={col.year} style={{color:"#5d1b8f"}}>
                                    <b>{col.year}</b>
                                </td>
                            )
                        }
                    </tr>
                    <tr style={{border:2, borderStyle:"solid", backgroundColor:"#F5F6CE"}}>
                        <td style={{color:"#5d1b8f"}}>
                            <b>{pCom}</b>
                        </td>
                        {
                            data.map(col => 
                                <td  key={col.year}>
                                    <div className="center-align"
                                        style={{
                                                width: 100,
                                                height: 20,
                                                marginTop:5,
                                                marginBottom:0,
                                                color:"white",
                                                borderRadius:10,
                                                backgroundColor: 
                                                col.pd == null ? 
                                                    "transparent"
                                                :
                                                col.pd<=0.5?
                                                    '#0F8305'
                                                :
                                                col.pd>0.5 && col.pd<=0.70
                                                ?
                                                    'coral'
                                                :
                                                col.pd>0.70?
                                                    '#DF0101'
                                                :   
                                                    '#99004c'
                                                }} >
                                        <span><strong style={col.pd == null ? {color:"black"}: {color:"white"}}>{col.pd == null ? "-" : Number(col.pd).toFixed(2)}</strong></span>
                                    </div>
                                </td>
                            )
                        }
                    </tr>
                    {
                        secData.map(secCompany=>
                            <>
                                <tr>
                                    <td style={{color:"#5d1b8f"}}>
                                        <b>{secCompany.name}</b>
                                    </td>
                                    {
                                        secCompany.data.map(col => 
                                            <td  key={col.year}>
                                                <div className="center-align"
                                                    style={{
                                                            width: 100,
                                                            height: 20,
                                                            marginTop:5,
                                                            marginBottom:0,
                                                            color:"white",
                                                            borderRadius:10,
                                                            backgroundColor: 
                                                            col.pd == null ? 
                                                                "transparent"
                                                            :
                                                            col.pd<=0.5?
                                                                '#0F8305'
                                                            :
                                                            col.pd>0.5 && col.pd<=0.70
                                                            ?
                                                                'coral'
                                                            :
                                                            col.pd>0.70?
                                                                '#DF0101'
                                                            :   
                                                                '#99004c'
                                                            }} >
                                                    <span><strong style={col.pd == null ? {color:"black"}: {color:"white"}}>{col.pd == null ? "-" : Number(col.pd).toFixed(2)}</strong></span>
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                            </>
                            )
                    }
                </tbody>
                {/* <tfoot>
                   
                </tfoot> */}
                
            </table>
            <div className='row left-align' style={{display:"flex",borderBottom:'0.05pt', borderBottomStyle:'solid', marginLeft:365, borderColor:'#eee', marginTop:20}}>         
                {/* <td style={{padding:5, width:'100%'}}> */}
                <div className="col s4 l4 m4 left-align" style={{marginLeft:0, fontSize:12}}>
                    <span>{"PD<= 0.5"}</span><div className="col s12 l12 m12" style={{width: 15,height: 15,marginLeft:0,marginRight:5, backgroundColor: '#0F8305'}}></div>
                </div>
                <div className="col s4 l4 m4 left-align"style={{marginLeft:0, fontSize:12}}>
                    <span>{"0.5 < PD <= 0.7"}</span><div className="col s12 l12 m12" style={{width: 15,height: 15,marginLeft:0,marginRight:5,backgroundColor: 'coral'}}></div>
                </div>
                {/* </td>
                <td style={{padding:5}}> */}
                <div className="col s4 l4 m4 left-align"style={{marginLeft:0, fontSize:12}}>
                    <span>{"PD> 0.7"}</span><div className="col s12 l12 m12" style={{width: 15,height: 15,marginLeft:0,marginRight:5,backgroundColor: '#DF0101'}}></div>
                </div>
                {/* </td> */}
            </div>
        </div>
        
    )
}
