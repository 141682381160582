import React from "react";

export const EditCompanyModal = (props) => {
  console.log("here", props.state);
  return (
    <div>
      <div id="edit-company-modal" className="modal">
        <div className="modal-content">
          <div
            className="modal-title dashboard-modal-title valign-wrapper"
            style={{ backgroundColor: "#124265", minHeight: 10 }}
          >
            <span className="dashboard-modal-name">Edit Company</span>
          </div>
          <form id="edit-company" onSubmit={props.OnSubmit}>
              {props.state.companyEditField=="company"?
              <div className="row input-field" style={{ marginTop: 23 }}>
                <input
                type="text"
                id="companyEditValue"
                value={props.state.companyEditValue}
                onChange={props.ChangeHandler}
                />
                <label for="companyEditValue"/>
              </div>
              :props.state.companyEditField=="sector"?
              <div className="row" style={{ marginTop: 23 }}>
                <div className="col s12 l12 m12">
                  <h5>Modify  Sector</h5>
                  <select
                    className="browser-default"
                    id="companyEditValue"
                    value={props.state.companyEditValue}
                    onChange={props.ChangeHandler}
                    >
                      {
                        props.sectors.map(s=>
                          <option value={s.id}>{s.name}</option>
                        )
                      }
                    </select>
                </div>
              </div>
              :props.state.companyEditField=="keyword"?
              <div className="row input-field" style={{ marginTop: 23 }}>
                <input
                type="text"
                id="companyEditValue"
                value={props.state.companyEditValue}
                onChange={props.ChangeHandler}
                />
                <label for="companyEditValue"/>
              </div>
              :null}
          </form>
        </div>
        <div className="modal-footer">
          <button className="modal-close waves-effect waves-green btn-flat">
            Close
          </button>
          <button
            className="waves-effect waves-green btn-flat"
            form="edit-company"
            type="submit"
          >
            Submit
          </button>
          {props.state.companyEditField=="keyword"&&
            <button
            className="waves-effect waves-green btn-flat"
            type="button"
            onClick={props.deleteKeyword}
          >
            Remove
          </button>}
        </div>
      </div>
    </div>
  );
};
