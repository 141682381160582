import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { changePassword } from '../../store/action/authActions'
import { Redirect } from 'react-router-dom';

 class ChangePassword extends Component {
  state = {
    password:"",
    newPassword: "",
    confirmPassword:"",
    error:false
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    if(this.state.error && (e.target.id === 'confirmPassword' || e.target.id === 'newPassword')){
        this.setState({...this.state, error:false})
    }
  };
  componentDidUpdate=()=>{
      if(this.props.auth.changePasswordSuccess){
        alert('Password changed succefully...')
        this.props.history.push("/dashboard")
      }
  }
  handleSubmit = (e)=>{
    e.preventDefault();
    this.state.newPassword === this.state.confirmPassword ?  
    this.props.ChangePassword(this.state, this.props.auth.accessToken)
    :
    this.setState({...this.state,error:true})

  }

  OnSuccess = (e) =>{
        this.props.auth.changePassword = false
        this.props.history.push("/")
  }

  OnCancel = (e) =>{
    this.props.history.push("/dashboard")
}

    render() {
        const { auth } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    return (
        <div>
          {auth.changePassword ?(
          <div className="main_container_wrapper">
              <div className="main-container container ">
                  <div className="col s10 m0 l0 center center-align" style={{padding:30}}>
                      <p style={{color:'grey',fontFamily:"Roboto", fontSize:48,}}>Password Changed</p>
                  </div>
                  <div className="input-field col s18 m8 l8 login-inputs center" style={{padding:10}}>
                      <button
                      style={{backgroundColor:'#29e831'}}
                          className="btn waves-effect waves-light"
                          type="button"
                          onClick={this.OnSuccess}
                      >
                          Continue
                      </button>
                  </div>
              </div>
          </div>
          )  
          :
          
          <div className="main_container_wrapper">
              <div className="main-container container ">
                  <div className="row valign-wrapper">
                      <div className="col s10 m0 l0 center" style={{padding:20}}>
                          <form onSubmit={this.handleSubmit} style={{ marginTop: 40 }}>
                          <div
                          className="col s12 left-align"
                          style={{ padding: 10 }}
                          >
                              <div
                                  className="col s3 m3 l3"
                                  style={{ height: 20  }}
                              >
                                  <p>Current Password</p>
                              </div>
                              <div
                                  className="col s5 m5 l5 center-align"
                                  style={{
                                  height: 40,
                                  backgroundColor: "#fff",
                                  // outline: "none",
                                  // border: "none",
                                  border:'1',
                                  radius: '5'
                                  }}
                              >
                                  <input className=" validate center-align"
                                  id="password"
                                  onChange={this.handleChange}
                                  type="password"  
                                  required
                                  />
                              </div>
                          </div>
                          <div
                          className="col s12 left-align"
                          style={{ padding: 10 }}
                          >
                              <div
                                  className="col s3 m3 l3"
                                  style={{ height: 20  }}
                              >
                                  <p>New Password</p>
                              </div>
                              <div
                                  className="col s5 m5 l5 center-align"
                                  style={{
                                  height: 40,
                                  backgroundColor: "#fff",
                                  outline: "none",
                                  border: "none",
                                  }}
                              >
                                  <input
                                  style={{
                                      outline: "none",
                                  }}
                                  id="newPassword"
                                  onChange={this.handleChange}
                                  type="password"  
                                  className="validate center-align"
                                  required
                                  />
                              </div>
                          </div>
                          <div
                          className="col s12 left-align"
                          style={{ padding: 10 }}
                          >
                              <div
                                  className="col s3 m3 l3"
                                  style={{ height: 20  }}
                              >
                                  <p>Confirm New Password</p>
                              </div>
                              <div
                                  className="col s5 m5 l5 center-align"
                                  style={{
                                  height: 40,
                                  backgroundColor: "#fff",
                                  outline: "none",
                                  border: "none",
                                  }}
                              >
                                  <input
                                  style={{
                                      outline: "none",
                                  }}
                                  id="confirmPassword"
                                  onChange={this.handleChange}
                                  type="password"  
                                  className="validate center-align"
                                  required
                                  />
                              </div>
                          </div>
                              <div className="row col s18 m8 l8 login-inputs center" style={{padding:50}}>
                              <button
                              style={{backgroundColor:'#2487ce'}}
                                  className="btn waves-effect waves-light"
                                  type="submit"
                                  disabled={auth.changePasswordLoading}
                              >
                                  Submit
                              </button>
                              <button
                              onClick={this.OnCancel} 
                              style={{backgroundColor:'red', opacity:0.7, marginLeft:20}}
                                  className="btn waves-effect waves-light"
                                  type="button"
                                  disabled={auth.changePasswordLoading}
                              >
                                  Cancel
                              </button>
                              <div className="red-text center">
                                  {this.state.error?<p>Password and confirm password not same</p> : null}
                                  {auth.changePasswordError ? <p>Wrong Password</p> : null}
                              </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
          }
        </div>
    
    )
    }
}

const mapStateToProps = (state) => {
  return {
      auth:state.auth
    };
};
const mapDispatchToProps = dispatch => {
  return {
    ChangePassword: (credentials, token) => {
      dispatch(changePassword(credentials, token));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);