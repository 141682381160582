import { json } from "d3-fetch"

export const filterSens = (da_sentences, nf_sentences,uo_sentences) => {
  var sens = []
  if(da_sentences.length > 0 && da_sentences[0].length > 0){
    console.error("da_sentences:: ",da_sentences)
    console.error("da_sentences.length[0]:: ",da_sentences[0].length)
    var x = JSON.parse(da_sentences)
    for(var i=0;i<x.length;i++){
      var ts = "HIGH: " + x[i]
      // var ts = ""
      // var index = x[i].toLowerCase().indexOf("\"page")
      // ts = x[i].slice(0, index)
      if(ts.length>5)
        sens.push(ts)
    }
  }
  if(nf_sentences.length > 0 && nf_sentences[0].length > 0){
    console.error("nf_sentences:: ",nf_sentences)
    console.error("nf_sentences.length[0]:: ",nf_sentences[0].length)
    var x = JSON.parse(nf_sentences)
    for(var i=0;i<x.length;i++){
      var ts = "SENTIMENT: " +x[i]
      // var ts = ""
      // var index = x[i].toLowerCase().indexOf("\"page")
      // ts = x[i].slice(0, index)
      if(ts.length>5)
        sens.push(ts)
    }
  }
  if(uo_sentences.length > 0 && uo_sentences[0].length > 0){
    console.error("uo_sentences:: ",uo_sentences)
    console.error("uo_sentences.length[0]:: ",uo_sentences[0].length)
    var x = JSON.parse(uo_sentences)
    for(var i=0;i<x.length;i++){
      var ts = "MEDIUM: " + x[i]
      // var ts = ""
      // var index = x[i].toLowerCase().indexOf("\"page")
      // ts = x[i].slice(0, index)
      if(ts.length>5)
        sens.push(ts)
    }
  }
  return sens
}
