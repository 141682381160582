import React, { Component } from "react";
import * as d3 from "d3";

class MultiLineChart extends Component {
  componentDidUpdate() {
    // //console.log(this.props);
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
    // //console.log(this.props.dataset)
  }

  drawChart() {
    const {dataset1, dataset2, can_width, can_height, onClick, selected, Company, Sector, margins} = this.props
    
    var dataset = dataset1.concat(dataset2);

    // Calculate Margins and canvas dimensions
    var margin = {
        top: margins?margins.top:40, 
        right: margins?margins.right:40, 
        bottom: margins?margins.bottom:90, 
        left: margins?margins.left:60
      },
        width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    //Parsers and Formaters
    var parseTime = d3.timeParse("%d/%m/%Y");
    // var formatTime = d3.timeFormat("%a/%b/%Y");
    
    // Scales
    var x = d3.scaleTime()
        .range([0, width]);
    
    var y = d3.scaleLinear()
        .range([height, 0]);
    
    // Line
    var line = d3.line()
        .x(function(d) { return x(parseTime(d.date)); })
        .y(function(d) { return y(d.nis); })
    
    //Remove already redered graph otherwise repeat graph will be created
    d3.select("#svg-element")
      .select("svg")
      .remove();
    
    var selectedData = selected?selected: dataset[0]

    var chart = d3.select("#svg-element").append("svg")
        .style("background-color", '#ffffff')
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("fill", 'none')
        .attr("line-stroke", 'blue')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
     
     //Arguments for axes : Ranges for X, y  
     x.domain(d3.extent(dataset, function(d) { return parseTime(d.date); }));
     y.domain(d3.extent(dataset, function(d) { return d.nis; }));
    
    // Axes
      chart.append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(10))
          .selectAll("text")	
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-65)")
            // .attr("font-weight", "100")
            .style("font-size", "12px")
            .style("fill", "gray")
            
          .call(d3.axisBottom(x));
    
      chart.append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisLeft(y))
          .style('color', 'black');

   
    // Labels
    chart.append("text")
    .attr("text-anchor", "middle")
    .style("font-size", "14px")
    .style("stroke", "gray")
    .attr("transform", "translate("+ (margin.left - 94 ) +","+(height/2)+")rotate(-90)")  
    .text("Score");

    chart.append("text")
        .style("font-size", "14px")
        .attr("text-anchor", "middle") 
        .style("stroke", "gray")
        .attr("transform", "translate("+ (width/2) +","+(height-(margin.bottom -170))+")")
        .text("Date");

  // Data Lines:

    chart.append("path")
        .datum(dataset1)
        .attr("class", "line")
        .attr('stroke', '#109ea3')
        .attr('stroke-width', 4)
        .attr("d", line)
        .enter()
        ;
    chart.append("path")
        .datum(dataset2)
        .attr("class", "line")
        .attr('stroke', '#3f8c23')
        .attr('stroke-width', 4)
        .attr("d", line)
        .enter()
        ;
    
  // chart.append('g')
  //   .attr('class', 'grid')
  //   .style("color", "grey")
  //   .call(d3.axisLeft()
  //   .scale(y)
  //   .tickSize(-width, 0, 0)
  //   .tickFormat(''))

  // Create a rect on top of the svg area: this rectangle recovers mouse position
  chart
    .append('rect')
    .style("fill", "none")
    .style("pointer-events", "all")
    .attr('width', width)
    .attr('height', height);

    //leagends
  chart
    .append("circle")
    .attr("cx",10)
    .attr("cy",-20 )
    .attr("r", 6)
    .style("fill", '#3f8c23')

  chart
    .append("circle")
    .attr("cx",350)
    .attr("cy",-20 )
    .attr("r", 6)
    .style("fill", '#109ea3')

  chart
    .append("text")
    .attr("x", 30)
    .attr("y", -20 )
    .text(Sector)
    .style("font-size", "15px")
    .style('fill', 'gray')
    .style('color', 'black')
    .attr("alignment-baseline","middle")
  chart
    .append("text")
    .attr("x", 370)
    .attr("y", -20 )
    .text(Company)
    .style("font-size", "15px")
    .style('fill', 'gray')
    .style('color', 'black')
    .attr("alignment-baseline","middle")

  }

  render() {
    return <div id="svg-element"style={{marginLeft:0}}></div>;
  }
}
export default MultiLineChart;
