import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";

export default function IndustryPerf({ label, data }) {
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (data) {
      setReportData(data);
    }
  }, [data]);

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  // console.log("REPORT DATA", reportData);
  return (
    <div className="row">
      <div className="col s12">
        <DataTable data={reportData} minRowInPage={10} />
        <p className="notes-text">
          ** OPEX Ratio = Expense of Management to Net Written Premium Ratio
          <br />
          Claim Ratio = Net Incurred Claims to Net Earned Premium
          <br />
          Combined Ratio = OPEX + Claim
        </p>
      </div>
    </div>
  );
}
