import React, { Component } from "react";
// import {isBrowser,isMobile
// } from "react-device-detect";
import PRLineChart from "../layout/PRLineChart";
import { connect } from "react-redux";
// import { initResponse } from "../../store/action/uploadAction";
// import Dashboard from "./Dashboard";
import { getNewsNIS, getNISTopNews } from "../../store/action/nisActions";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import PRLineChartSmall from "../layout/PRLineChartSmall";

class ConCall extends Component {
  state = {
    data: [12, 5],
    width: 1050,
    height: 400,
    smallWidth:150,
    smallHeight:50,
    id: "root",
    name:"PRScore",
    posSens:[],
    negSens:[],
    qSens:[],
    page:1,
    chartDataOnZoom:null,
    quarter:this.props.ChartData.length - 1
  };

  componentDidMount = () => {
    // //console.log("nis", this.state)
    document.body.style.overflow = 'visible'
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, { 
      preventScrolling:false,
     });
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      onClose: () => {
        this.onDateClose();
      },
      autoClose:true,
    });
    if(this.props.ChartData.length == 1){
        var point = this.props.ChartData[0]
        this.setState({posSens:point.posSen, negSens:point.negSen, qSens:point.quatitativeSen})
    }
    // this.updateDimensions();
    // window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    document.body.style.overflow = 'visible'
    // //console.log(this.state)
  };
  componentDidUpdate = () => {};
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };

  onNISChangeHandler = (event) => {
    // //console.log(event.target.id, event.target.value)
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    // //console.log(this.props)
    this.props.GetNewsNIS(this.state, this.props.auth.accessToken);
  };

  
  OnClick = (d, selected) => {
    // //console.log("CLICKED", d, selected);
    var point = this.props.ChartData.find(p => p.date==d)
    this.setState({posSens:point.posSen, negSens:point.negSen, qSens:point.quatitativeSen})
  };

  ZoomChart=(ind, type)=>{
      if(type == 'sec')
      this.setState({chartDataOnZoom:this.props.ChartDataSec[ind]})
      if(type=='pri')
      this.setState({chartDataOnZoom:this.props.ChartData})

  }
  /**
   * Remove event listener
   */
  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateDimensions.bind(this));
  // }

  render() {
    const {
      auth,
      ChartData,
      ChartDataSec,
      TopNews,
      CompanyName,
      loading,
      error,
      newsLoading,
    } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (!ChartData || ChartData.length === 0)
      return <Redirect to="/dashboard" />;
    // //console.log("loc state", this.state);
    // //console.log("loc props", this.props);
    return (
      <div className="container" style={{width:'90%'}}>
          <div id="modal-zoom" className="modal">
            <div className="modal-content">
                {this.state.chartDataOnZoom&&
                <PRLineChart
                id="zoomData"
                can_height={400}
                can_width={800}
                dataset={this.state.chartDataOnZoom}
                onClick={()=>{}}
                selected={this.props.selected ? this.props.selected : null}
                name={this.state.name}
                bgcolor="white"
                lineColor="#5d1b8f"/>
                }
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div>
        <div className="row" style={{ marginBottom: 0, height:"100%"}}>
            {/* <div className="col s1">
                <div className="row"style={{position:'fixed', top:'50%', width:'inherit', }}>
                    <button className='btn btn-floating' 
                    style={{backgroundColor:'#5d1b8f' }}
                    disabled={this.state.page==1}
                    onClick={()=>{this.setState({page:1})}}
                    >
                        <i className="material-icons">
                            arrow_backward
                        </i>
                    </button>
                </div>
            </div> */}
            {this.state.page==1?
            <div className="col s11">
                <div className="row">
                    {ChartData
                    &&ChartData.length>1&&
                    <div className="row center-align" style={{marginBottom:130}}>
                        <p style={{ color: "#5d1b8f" }}>
                        <strong>Positivity Rate</strong>
                        </p>
                        <div
                        className="row"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        >
                            <PRLineChart
                            can_height={this.state.height}
                            can_width={this.state.width}
                            dataset={ChartData}
                            onClick={this.OnClick}
                            selected={this.props.selected ? this.props.selected : null}
                            name={this.state.name}
                            bgcolor="white"
                            lineColor="#5d1b8f"
                            />
                        </div>  
                    </div>
                    }
                    <div className="row" style={{marginTop:20}}>
                    {
                        this.state.posSens.length>0||this.state.negSens.length>0?
                        <>
                        <div
                        className="col s6 m6 l6"
                        >
                            <p style={{ color: "#23431b", backgroundColor:"#dfb5ff" }}>
                                <strong>Positive Sentences</strong>
                            </p>
                            {
                                this.state.posSens&&this.state.posSens.map(sen => 
                                    <p>{sen}</p>
                                )
                            }
                        </div>
                        <div
                        className="col s6 m6 l6"
                        backgroundColor="#dfb5ff"
                        >
                            <p style={{ color: "#23431b", backgroundColor:"#dfb5ff" }}>
                                <strong>Negative Sentences</strong>
                            </p>
                            {
                                this.state.negSens&&this.state.negSens.map(sen => 
                                    <p>{sen}</p>
                                )
                            }
                        </div>
                        </>
                        :null
                    }
                    </div>
                    {this.state.qSens&&this.state.qSens.length>0&&
                    <div className="row" style={{marginTop:40}}>
                        <div className="row cen center-align">
                            <p style={{color:'#5d1b8f'}}>
                            <strong>Quantitive Highlights</strong>
                            </p>
                        </div>
                        <div className='row' style={{backgroundColor:"#dfb5ff"}}>
                            <div className="col s4">
                                <span><b>Title</b></span>
                            </div>
                            <div className="col s4">
                                <span><b>Quantity</b></span>
                            </div>
                            <div className="col s4">
                                <span><b>Sentiment</b></span>
                            </div>
                        </div>
                        {
                        this.state.qSens&&this.state.qSens.map(sen => 
                            <div className='row'>
                                <div className="col s4">
                                    {sen.term}
                                </div>
                                <div className="col s4">
                                    {sen.numberPart}
                                </div>
                                <div className="col s4">
                                    {sen.sentiment}
                                </div>
                            </div>
                        )
                        }
                    </div>
                    }
                </div>
            </div>
            :
            <div className='col s11'>
                <div className="row">
                    <p className="center center-align" style={{fontSize:24, marginTop:20, color:"#5d1b8f"}}>
                        Peer Group Analysis
                    </p>
                    <div className="row" style={{marginTop:30}}>
                    <table>
                        <tr>
                            <th className='concall-tab-heading'>
                                Company
                            </th>
                            <th className='concall-tab-heading'>
                                <div className="col s6">
                                    Date
                                </div>
                                <div className="col s6 input-field" style={{margin:0, padding:0}}>
                                    <select style={{margin:0, padding:0}} id='quarter' className='browser-default' onChange={this.onNISChangeHandler}>
                                        {
                                            ChartData.map((point, ind) => 
                                                <option value={ind} selected={this.state.quarter == ind}>
                                                {moment(point.date, 'DD/MM/YYYY').quarter()!=1?
                                                    'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()-1) 
                                                    + ',' + moment(point.date, 'DD/MM/YYYY').year()
                                                :
                                                    'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()+3) 
                                                    +","+ Number( moment(point.date, 'DD/MM/YYYY').year()-1)
                                                }
                                                </option>
                                            )
                                        }
                                    </select>
                                </div>
                            </th>
                            <th className='concall-tab-heading'>
                                PR Score
                            </th>
                            <th className='concall-tab-heading'>
                                Highlights
                            </th>
                            <th className='concall-tab-heading'>
                                PR Trend
                            </th>
                        </tr>
                        {
                            ChartData.map((point, ind) => 
                                ind==this.state.quarter&&
                                <tr>
                                    <td className='concall-tab-body'>
                                    {this.props.companies.find(d => point.company_id==d.id).name}
                                    </td>
                                    <td className='concall-tab-body'>
                                        {moment(point.date, 'DD/MM/YYYY').quarter()!=1?
                                            'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()-1) 
                                            + ',' + moment(point.date, 'DD/MM/YYYY').year()
                                        :
                                            'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()+3) 
                                            +","+ Number( moment(point.date, 'DD/MM/YYYY').year()-1)
                                        }
                                    </td>
                                    <td className='concall-tab-body'>
                                        {point.prScore?Number(point.prScore).toFixed(2):0}
                                    </td>
                                    <td className='concall-tab-body'>
                                        {point.quatitativeSen.map((sen, ind) =>
                                        ind<5&&
                                        <p>
                                            <span><i className="material-icons tiny">done_all</i></span>
                                            <span>{sen.term + " " +sen.numberPart + " " + sen.sentiment}</span>
                                        </p>
                                        )}
                                    </td>
                                    <td className='concall-tab-body modal-trigger' href="#modal-zoom" onClick={()=>this.ZoomChart(ind, 'pri')}>
                                        <PRLineChartSmall
                                        can_height={this.state.smallHeight}
                                        can_width={this.state.smallWidth}
                                        dataset={ChartData}
                                        name={this.state.name}
                                        bgcolor="white"
                                        lineColor="#5d1b8f"
                                        id={ind}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                        {
                            ChartDataSec.map((comp, ind1) => 
                            comp.map((point, ind) => 
                            ind== this.state.quarter&&
                                <tr>
                                    <td className='concall-tab-body'>
                                        {this.props.companies.find(d => point.company_id==d.id).name}
                                    </td>
                                    <td className='concall-tab-body'>
                                        {moment(point.date, 'DD/MM/YYYY').quarter()!=1?
                                            'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()-1) 
                                            + ',' + moment(point.date, 'DD/MM/YYYY').year()
                                        :
                                            'Q'+ Number(moment(point.date, 'DD/MM/YYYY').quarter()+3) 
                                            +","+ Number( moment(point.date, 'DD/MM/YYYY').year()-1)
                                        }
                                    </td>
                                    <td className='concall-tab-body'>
                                        {point.prScore?Number(point.prScore).toFixed(2):0}
                                    </td>
                                    <td className='concall-tab-body'>
                                        {point.quatitativeSen.map((sen, ind) =>
                                        ind<5&&
                                        <p>
                                            <span><i className="material-icons tiny">done_all</i></span>
                                            <span>{sen.term + " " +sen.numberPart + " " + sen.sentiment}</span>
                                        </p>
                                        )}
                                    </td>
                                    <td className='concall-tab-body modal-trigger' href="#modal-zoom" onClick={()=>this.ZoomChart(ind1, 'sec')}>
                                        <PRLineChartSmall
                                        can_height={this.state.smallHeight}
                                        can_width={this.state.smallWidth}
                                        dataset={comp}
                                        name={this.state.name}
                                        bgcolor="white"
                                        lineColor="#5d1b8f"
                                        id={"sec"+ind1}
                                        />
                                    </td>
                                </tr>
                            )
                            )
                        }
                    </table>
                    </div>
                </div>
                
            </div>
            }
            <div className="col s1">
                <div className="row"style={{position:'fixed', top:'50%', width:'inherit', }}>
                    <button className='btn btn-floating' 
                    style={{backgroundColor:'#5d1b8f', marginLeft:40 }}
                    disabled={!ChartDataSec||(ChartDataSec&&ChartDataSec.length==0)}
                    onClick={()=>{this.setState({page:this.state.page==1? 2:1})}}
                    >
                        {this.state.page==1?
                        <i className="material-icons">
                            arrow_forward
                        </i>
                        :
                        <i className="material-icons" style={{paddingLeft:5}}>
                            arrow_backward
                        </i>
                        }
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ChartData: state.concall.concall ? state.concall.concall : [],
    ChartDataSec: state.concall.concallSec ? state.concall.concallSec : [],
    TopNews:state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,
    auth: state.auth,
    profile: state.auth,
    companies: state.NIS.companyList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetNewsNIS: (data, token) => {
      dispatch(getNewsNIS(data, token));
    },
    GetNISTopNews: (data, selected, token) => {
      dispatch(getNISTopNews(data, selected, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConCall);