import React, { useState } from "react";
import { useEffect } from "react";
import BarChartGenericVertical from "../../layout/BarChartGenericVertical";
import {
  getTopN,
  getUniqueList,
  monthToDates,
  orderMonths,
} from "../../../utils/nlHelper";
import LineChartGeneric from "../../layout/LineChartGeneric";

export default function TrendDashboard({ label, data }) {
  const [transformedData, setTransFormedData] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [selMeasures, setSelMeasures] = useState({});

  useEffect(() => {
    transformData();
  }, [data]);

  const onCompanyChange = (e) => {
    let selMeas = selMeasures;
    selMeas[e?.target?.id] = e?.target?.checked;

    let selectedCompany = Object.values(selMeas).filter(
      (val) => val === true
    ).length;

    if (selectedCompany > 10) {
      alert("Maximum 10 companies can be selected");
      return;
    }

    // console.log("EVENT", e.target.id, e.target.checked, selMeas);
    setSelMeasures({ ...selMeas });

    let newComs = [];

    Object.entries(selMeas).map(([com, checked]) => {
      if (checked) {
        newComs.push(com);
      }
    });

    transformData(newComs);
  };

  const transformData = (newMeas) => {
    let companies = getUniqueList(
      data.map((d) => {
        return d["Company Name"];
      })
    );
    let months = getUniqueList(
      data.map((d) => {
        return d["Month"];
      })
    );
    months = orderMonths(months);
    // console.log("Months", months);
    let newData = [];
    for (let j = 0; j < months.length; j++) {
      let ob = { Month: months[j] };
      for (let i = 0; i < companies.length; i++) {
        let companyShareInMonth = data.find(
          (d) => d["Company Name"] === companies[i] && d["Month"] === months[j]
        );
        if (companyShareInMonth) {
          ob[companies[i]] = companyShareInMonth["Market %"];
        }
      }
      newData.push(ob);
    }

    let [trData, meas] = getTopN(newData, 5, newMeas);
    newData = monthToDates(trData);

    setTransFormedData(newData);
    setMeasures(
      companies?.map((com) => {
        return { text: com };
      })
    );

    if (!newMeas) {
      let selMeas = {};
      // console.log("COMPANIES", companies);
      companies?.map((com) => {
        selMeas[com] = meas?.includes(com);
      });

      setSelMeasures({ ...selMeas });
    }
  };

  // console.log("transformedData", transformedData, measures, selMeasures);
  return (
    <>
      <div className="row">
        <div className="col s2">
          <table className="tableFixed">
            <thead>
              <tr>
                <th>Companies</th>
              </tr>
            </thead>
            <tbody style={{ height: 460, position: "relative" }}>
              {measures?.map((company) => (
                <tr>
                  <td>
                    <label style={{ color: "black" }}>
                      <input
                        type="checkbox"
                        id={company.text}
                        checked={selMeasures[company.text]}
                        onChange={onCompanyChange}
                      />
                      <span>{company?.text}</span>
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col s10">
          <LineChartGeneric
            id="trend"
            data={transformedData}
            dimensions={[{ text: "Month" }]}
            measures={measures?.filter((meas) => selMeasures[meas.text])}
            can_width={1000}
            can_height={550}
            tooltip={false}
            labelInBar={true}
          />
        </div>
      </div>
    </>
  );
}
