import React, { Component } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import {
  getAnnouncement,
  getPieChart,
} from "../../store/action/announceAction";
// import Announcements from "../layout/Announcements";
import PieChart from "../layout/PieChart";
import _ from "lodash";
import Select from "react-select";

const announcementTopics = [
  { value: "AGM/EGM", label: "AGM/EGM" },
  { value: "Business Discontinuity", label: "Business Discontinuity" },
  { value: "Meeting Notice", label: "Meeting Notice" },
  { value: "Corp. Action", label: "Corp. Action" },
  { value: "Auditors", label: "Auditors" },
  { value: "AGM Proceedings", label: "AGM Proceedings" },
  { value: "Acquisition", label: "Acquisition" },
  { value: "Ratings", label: "Ratings" },
  { value: "Media", label: "Media" },
  { value: "Debentures", label: "Debentures" },
  { value: "Corp Action", label: "Corp Action" },
  { value: "Voting results", label: "Voting results" },
  { value: "Investor presentation", label: "Investor presentation" },
  { value: "Company Update", label: "Company Update" },
  { value: "Analyst call/Meet", label: "Analyst call/Meet" },
  { value: "Compliance", label: "Compliance" },
  { value: "Change in Management", label: "Change in Management" },
  { value: "Fraud", label: "Fraud" },
  { value: "Result", label: "Result" },
  { value: "Dividend", label: "Dividend" },
  { value: "Financial Distress", label: "Financial Distress" },
  { value: "Legal", label: "Legal" },
  {
    value: "Trading window/book closure",
    label: "Trading window/book closure",
  },
  { value: "Annual report", label: "Annual report" },
  { value: "Miscellaneous", label: "Miscellaneous" },
  {
    value: "Alteration of Memorandum/Articles",
    label: "Alteration of Memorandum/Articles",
  },
  { value: "Financial Results", label: "Financial Results" },
  { value: "Shareholding", label: "Shareholding" },
  { value: "Insider Trading / SAST", label: "Insider Trading / SAST" },
  { value: "Covid-19", label: "Covid-19" },
  { value: "Board Meeting", label: "Board Meeting" },
  { value: "RPTs", label: "RPTs" },
  { value: "Scheme of Arrangement", label: "Scheme of Arrangement" },
  { value: "Promoters", label: "Promoters" },
];
class AnnounceDashboard extends Component {
  state = {
    data: [],
    // tts: [
    //   "AGM/EGM",
    //   "Business Discontinuity",
    //   "Meeting Notice",
    //   "Corp. Action",
    //   "Auditors",
    //   "AGM Proceedings",
    //   "Acquisition",
    //   "Ratings",
    //   "Media",
    //   "Debentures",
    //   " Corp Action",
    //   "Voting results",
    //   "Investor presentation",
    //   "Company Update",
    //   "Analyst call/Meet",
    //   "Compliance",
    //   "Change in Management",
    //   "Fraud",
    //   "Result",
    //   "Dividend",
    //   "Financial Distress",
    //   "Legal",
    //   "Trading window/book closure",
    //   "Annual report",
    //   "miscellaneous",
    //   "Alteration of Memorandum/Articles",
    //   "Financial Results",
    //   "Shareholding ",
    //   "Insider Trading / SAST",
    //   "Covid-19",
    //   "Board Meeting",
    //   "RPTs",
    //   "Scheme of Arrangement",
    //   "Promoters",
    // ],
    topic: {},
    topicList: {},
    topicCount: {},
    count: {},
    passParam: {},
    selectedOptions: null,
    checker: false,
    updateCheck: true,
    startDate: this.props.initData ? this.props.initData.startDate : null,
    endDate: this.props.initData ? this.props.initData.endDate : null,
  };

  componentDidMount = () => {
    document.body.style.overflow = "visible";
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      onClose: () => {
        this.onDateClose();
      },
    });
    this.setState({ data: this.props.announcement });
    this.findTopic();
    this.findParam();
  };

  componentDidUpdate() {
    if (this.props.announcementSuccess && this.state.updateCheck) {
      this.findTopic();
      this.setState({ updateCheck: false, checked: false });
    }
  }

  findTopic = (e) => {
    // //console.log("find func",this.props.announcement)
    var data = this.props.announcement;
    var topic = [];
    if (data != null) {
      for (var i = 0; i < data.length; i++) {
        topic.push(data[i].topic);
        this.setState(
          { topic: topic },
          () =>
            // this.topicList(this.state.topic),
            this.topicCount(this.state.topic)
          // this.count(this.state.topic)
        );
      }
    }
  };

  // topicList = (arr) => {
  //   const count = arr.reduce(
  //     (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
  //     {}
  //   );
  //   const topicList = _.map(count, (val, key) => {
  //     return { ...val, topic: key };
  //   });
  //   this.setState({ topicList: topicList });
  // };

  topicCount = (arr) => {
    const count = arr.reduce(
      (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
      {}
    );
    const counts = _.map(count, (val, key) => {
      return { ...val, topic: key, count: val };
    });
    this.setState({ topicCount: counts, checker: true });
    this.props.GetPieChart(counts);
  };

  // count = (arr) => {
  //   const count = arr.reduce(
  //     (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
  //     {}
  //   );
  //   const counts = _.map(count, (val, key) => {
  //     return { ...val, count: val };
  //   });
  //   this.setState({ count: counts,checker: true });
  // };

  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    // //console.log(this.state)
  };

  // handleBack = () => {
  //   this.props.initResponse();
  //   this.props.history.push("/");
  // };

  // onChangeHandler = (event) => {
  //   //console.log(event.target.id, event.target.value);
  //   this.setState({
  //     [event.target.id]: event.target.value,
  //   });
  // };

  HandleChange = (selectedOptions) => {
    if (selectedOptions)
      //console.log(selectedOptions);
      this.setState({
        selectedOptions: selectedOptions,
      });
  };

  // onSelectChange = (e)=> {
  //   // alert("ftft")
  //       var selected = [];
  //       for (var option of document.getElementById('filter').options) {
  //         if (option.selected) {
  //           selected.push(option.value);

  //         }
  //       }
  //     //console.log("selected",selected);
  //     this.setState({selectedTopic: selected})
  // }
  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  findParam = (e) => {
    var company = this.props.NIS.companyList;
    var id = this.props.initData ? this.props.initData.companyId : null;
    if (company) {
      var result = company.find((data) => data.id === id);
      this.setState({ passParam: result });
    }
  };

  onAnnounceSubmit = (e) => {
    e.preventDefault();
    if (this.state.selectedOptions) {
      var selectedOption = this.state.selectedOptions;
      var topic = [];
      for (var i = 0; i < selectedOption.length; i++) {
        topic.push(selectedOption[i].value);
      }
      // this.setState({topic:topic})
    }
    var data = {
      companyId: this.props.initData.companyId,
      // startDate: this.formatDate(this.state.startDate),
      startDate: this.state.startDate,
      // endDate: this.formatDate(this.state.endDate),
      endDate: this.state.endDate,
      topic: topic ? topic : null,
    };
    // //console.log("Successfully submited", data);
    this.setState({ updateCheck: true });
    this.props.GetAnnouncement(data, this.props.auth.accessToken);
  };

  customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: "black",
      width: "100%",
      maxHeight: 90,
      overflowY: "auto",
    }),
  };

  render() {
    if (!this.props.auth.uid) return <Redirect to="/SignIn" />;
    if (this.props.initData == null) return <Redirect to="/dashboard" />;
    // //console.log(this.state.topicCount);
    // //console.log(this.state.checker,this.props.announcementSuccess)
    // //console.log(this.state.count);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: 350, backgroundColor: "#bfbf0d" }}>
          {/* <h5 style={{paddingLeft:30, paddingTop:7, color:"antiquewhite"}}><strong>Company:{this.state.passParam.name}</strong></h5> */}
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0,
            }}
          >
            <div className="col s6" style={{ height: 283 }}>
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  marginLeft: 10,
                  // paddingTop: 10,
                  width: "95%",
                  height: "96%",
                  marginRight: "70%",
                  marginTop: 10,
                  // backgroundColor: "#bfbf0d"
                  backgroundColor: "rgb(255,255,255,0.5)",
                  border: "0px solid",
                  borderRadius: 15,
                }}
              >
                <div
                  className="col s4"
                  style={{
                    width: "89%",
                    marginLeft: "5%",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <>
                    <span>
                      <strong>Company:</strong>
                    </span>
                    <span style={{ marginLeft: 20 }}>
                      {this.state.passParam.name}
                    </span>{" "}
                  </>
                </div>
                <div
                  className="col s4"
                  style={{ width: "89%", marginLeft: "5%" }}
                >
                  <label
                    style={{
                      color: "#000000",
                      textShadow: "0.4px 0px 0px black",
                      fontSize: 15,
                    }}
                  >
                    Start Date
                  </label>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="startDate"
                    value={this.state.startDate}
                    style={{
                      backgroundColor: "transparent",
                      // borderBottom: "none",
                      borderBottomColor: "black",
                      outline: "none",
                      height: 25,
                    }}
                  />
                </div>
                <div
                  className="col s4"
                  style={{ width: "89%", marginLeft: "5%" }}
                >
                  <label
                    style={{
                      color: "#000000",
                      textShadow: "0.4px 0px 0px black",
                      fontSize: 15,
                    }}
                  >
                    End Date
                  </label>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="endDate"
                    value={this.state.endDate}
                    style={{
                      backgroundColor: "transparent",
                      // borderBottom: "none",
                      borderBottomColor: "black",
                      outline: "none",
                      height: 25,
                    }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="input-field col s3 announce-select"
                    style={{ width: "60%", marginLeft: "5%" }}
                  >
                    <Select
                      styles={this.customStyles}
                      value={this.state.selectedOptions}
                      onChange={this.HandleChange}
                      options={announcementTopics}
                      isMulti={true}
                      placeholder="Select topics..."
                    />
                    {/*  <label
                style={{
                  color: "#000000",
                  textShadow: "0.4px 0px 0px black",
                  fontSize: 15,
                }}
                >
                Topics
                </label> */}
                  </div>
                  <div style={{ paddingRight: 50, paddingTop: 20 }}>
                    <button
                      className="btn right"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                      onClick={this.onAnnounceSubmit}
                    >
                      Submit
                      <i className="material-icons right">send</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s6" style={{ height: 283 }}>
              {!this.props.announcement ||
              this.props.announcement.length === 0 ? null : this.state
                  .checker && this.props.pieChartSuccess ? (
                <PieChart
                  // id="pieChart"
                  key={this.props.chartData}
                  data={this.props.chartData}
                  // dimensions={this.state.topicList && this.state.topicList}
                  // measures={this.state.count && this.state.count}
                  height="200"
                  width="200"
                />
              ) : null}
            </div>
          </div>

          <div className="row top-news-header">
            <div className="col s2">
              <b>Date Time</b>
            </div>
            <div className="col s2">
              <b>Topic</b>
            </div>
            <div className="col s6" style={{ textAlign: "center" }}>
              <b>Headline</b>
            </div>
            <div className="col s2">
              <b>Score</b>
            </div>
          </div>
          {/* <Announcements announcements={this.state.data?this.state.data:[]}/> */}
          <div
            className="top-news"
            // style={{
            //   height: "460px",
            //   overflowY: "auto",
            // }}
          >
            <ul
              className="collection"
              style={{
                backgroundColor: "rgb(255,255,255,0.5)",
                paddingTop: 15,
                // width: "80%",
                boxShadow: "2px 20px 20px",
              }}
            >
              {!this.props.announcement ||
              this.props.announcement.length === 0 ? (
                <div style={{ marginBottom: 15, textAlign: "center" }}>
                  <b>No data found, please change selection criteria</b>
                </div>
              ) : (
                <div>
                  {this.props.announcement &&
                    this.props.announcement.map((news) => (
                      <div
                        className="row top-news-item"
                        style={{ marginLeft: 10 }}
                      >
                        <div className="col s2 center center-align">
                          <p>{news.date && news.date}</p>
                        </div>
                        <div className="col s2 left left-align">
                          <p
                            style={{
                              color: "#000000",
                              textShadow: "0.4px 0px 0px black",
                            }}
                          >
                            {news.topic && news.topic}
                          </p>
                        </div>
                        <div className="col s6 left-align">
                          <a
                            href={news.fileUrl && news.fileUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            // className="collection-item"
                            style={{
                              color: "#000000",
                              backgroundColor: "transparent",
                              textShadow: "0.4px 0px 0px black",
                            }}
                          >
                            {news.headline && news.headline}
                          </a>
                        </div>
                        {/* <div className="col s1" style={{ color: "#23431b" }}>
                      {news.score >= 0 ? (
                        <p>
                          <i
                            className="material-icons"
                            style={{
                              color: "#000000",
                              textShadow: "0.4px 0px 0px black",
                            }}
                          >
                            add_circle
                          </i>
                        </p>
                      ) : (
                        <p style={{ color: "red" }}>
                          <i className="material-icons">do_not_disturb_on</i>
                        </p>
                      )}
                    </div> */}
                        <div className="col s2" style={{ color: "#23431b" }}>
                          <p>
                            {news.score > 0 ? (
                              <strong style={{ color: "green" }}>High</strong>
                            ) : news.score < 0 ? (
                              <strong style={{ color: "red" }}>Low</strong>
                            ) : news.score === 0 ? (
                              <strong style={{ color: "blue" }}>
                                Moderate
                              </strong>
                            ) : (
                              " "
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.announcement);
  return {
    auth: state.auth,
    announcement:
      state.announcement && state.announcement.data.data
        ? state.announcement.data.data
        : [],
    initData:
      state.announcement && state.announcement.initData
        ? state.announcement.initData
        : null,
    announcementSuccess: state.announcement.announcementSuccess,
    NIS: state.NIS,
    pieChartSuccess: state.announcement.pieChartSuccess,
    pieChartError: state.announcement.pieChartError,
    chartData: state.announcement.chartData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetAnnouncement: (body, token) => {
      dispatch(getAnnouncement(body, token));
    },
    GetPieChart: (data) => {
      dispatch(getPieChart(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnounceDashboard);
