import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchLifeData,
  lifeUpdateData,
  setLifeReducer,
} from "../../../store/action/lifeAction";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import swal from "sweetalert";

export default function EditLife() {
  const location = useLocation();
  const dispatch = useDispatch();

  const tableContainerRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get("cid");
  const lifeId = queryParams.get("lifeId");
  const year = queryParams.get("year");
  const quarter = queryParams.get("quarter");
  const [edit, setEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);

  const [tableData, setTableData] = useState(null);

  const accessToken = useSelector((state) => state.auth?.accessToken);
  const lifeData = useSelector((state) => state.life?.lifeData);
  const lifePathDetailsId = useSelector(
    (state) => state.life?.lifePathDetailsId
  );
  const lifeUpdateDataSuccess = useSelector(
    (state) => state.life?.lifeUpdateDataSuccess
  );
  const lifeUpdateDataError = useSelector(
    (state) => state.life?.lifeUpdateDataError
  );
  const lifeUpdateDataMessage = useSelector(
    (state) => state.life?.lifeUpdateDataMessage
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (lifeData) {
      let keys = Object.keys(lifeData);
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let obj = { row: { value: key, colOrder: 0 } };
        let colKeys = Object.keys(lifeData[key]);
        for (let j = 0; j < colKeys.length; j++) {
          let colKey = colKeys[j];
          obj[colKey] = lifeData[key][colKey];
        }
        obj["rowOrder"] = lifeData[key]["rowOrder"];
        result.push(obj);
      }
      setTableData(result);
    }
  }, [lifeData]);

  useEffect(() => {
    if (lifeUpdateDataError) {
      swal("Oops!", lifeUpdateDataMessage, "error");
      dispatch(setLifeReducer({ lifeUpdateDataError: false }));
    }
  }, [lifeUpdateDataError]);

  const fetchData = () => {
    dispatch(
      fetchLifeData(
        {
          year: year,
          quarter: quarter,
          cid: Number(companyId),
          life_id: Number(lifeId),
        },
        accessToken
      )
    );
  };

  const update = (e, row, col) => {
    e.preventDefault();
    const userInput = prompt(
      `Please enter a value for ${row["row"]["value"]}, ${col}:`
    );
    let rowIndex = tableData.findIndex(
      (f) => f["row"]["value"] === row["row"]["value"]
    );
    if (userInput !== null && userInput !== undefined) {
      let oldRow = { ...tableData[rowIndex] };
      oldRow[col]["value"] = userInput;
      oldRow[col]["edited"] = true;
      dispatch(
        lifeUpdateData(
          {
            rowId: row[col]["rowId"],
            colId: row[col]["colId"],
            value: userInput,
            lifePathDetailsId: lifePathDetailsId,
          },
          accessToken
        )
      );
    }
  };

  return (
    <div className="row" style={{ padding: 10 }}>
      <div className="col s12">
        {tableData?.length > 0 && (
          <TableContainer ref={tableContainerRef} sx={{ overflowX: "initial" }}>
            <Table stickyHeader>
              <TableHead sx={{ position: "sticky", top: 60 }}>
                <TableRow>
                  {Object.keys(tableData[0])
                    .sort(
                      (a, b) =>
                        tableData[0][a].colOrder > tableData[0][b].colOrder
                    )
                    .map(
                      (col, index) =>
                        col !== "rowOrder" && (
                          <TableCell
                            sx={{ borderRight: "1px solid #ddd" }}
                            key={tableData[0][col]["colId"] || 0}
                          >
                            {col}
                          </TableCell>
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1))
                  .map((row) => (
                    <TableRow key={row.rowOrder}>
                      {Object.keys(row)
                        .sort((a, b) => row[a].colOrder > row[b].colOrder)
                        .map(
                          (col, cellIndex) =>
                            col !== "rowOrder" && (
                              <TableCell
                                sx={{ borderRight: "1px solid #ddd" }}
                                key={row[col]["colId"] || 0}
                                onClick={(e) => {
                                  setSelectedRow(row);
                                  setSelectedCol(col);
                                  update(e, row, col);
                                }}
                                style={
                                  row[col]["edited"] && {
                                    backgroundColor: "#bef7ce",
                                  }
                                }
                              >
                                {row[col]["value"]}
                              </TableCell>
                            )
                        )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
