import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import DataTable from "../../layout/DataTable/DataTable";
import { orderFilters } from "../../../utils/tableHelper";
import Segments from "./laylout/Segments";

export default function SegmentsPerf({ label, data }) {
  const [reportData, setReportData] = useState([]);
  const [selLob, setSelLob] = useState(null);
  const [lobs, setLobs] = useState([]);

  useEffect(() => {
    if (data) {
      let temp_lobs = [];
      for (let i = 0; i < data?.length; i++) {
        if (!temp_lobs.includes(data[i]["Segments"])) {
          temp_lobs.push(data[i]["Segments"]);
        }
      }
      temp_lobs = orderFilters(temp_lobs);
      setLobs(temp_lobs);
      setSelLob(temp_lobs[0]);

      let temp = data?.filter((row) => row.Segments === temp_lobs[0]);
      setReportData(temp);
    }
  }, [data]);

  const handleLobChange = (lob) => {
    let temp_data = data?.filter((row) => row.Segments === lob);
    setReportData(temp_data);
    setSelLob(lob);
  };

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData);
  console.log("DATA", data);
  console.log("DATA", lobs);
  return (
    <div className="row">
      <Segments lobs={lobs} selLob={selLob} handleLobChange={handleLobChange} />
      <div className="col s12">
        <DataTable
          data={reportData}
          hiddenCols={["Segments"]}
          minRowInPage={10}
        />
      </div>
    </div>
  );
}
