import React, { Component } from "react";
import * as d3 from "d3";
import { color } from "d3";
// import moment from "moment"

class LineChartGeneric extends Component {
  componentDidUpdate() {
    console.log(this.props);
    if (
      this.props.dimensions &&
      this.props.dimensions.length > 0 &&
      this.props.measures &&
      this.props.measures.length > 0
    ) {
      this.drawChart();
    }
  }
  componentDidMount() {
    if (
      this.props.dimensions &&
      this.props.dimensions.length > 0 &&
      this.props.measures &&
      this.props.measures.length > 0
    ) {
      this.drawChart();
    }
    // console.log(this.props.data)
  }

  drawChart() {
    const {
      id,
      data,
      can_width,
      can_height,
      dimensions,
      measures,
      margins,
      legend = true,
      noY = false,
      noYTick = false,
      rotateXTick = false,
    } = this.props;
    const colors = {
      primary: "#2F3B47",
      surface: "#FFFFFF",
      surface2: "#D9E1E7",
      accent: "#62B1F3",
      shade: "#E5E5E5",
      font: "#3E4B55",
      textarea: "whitesmoke",
    };

    // console.log("IN LINE",  this.props.data);

    // Calculate Margins and canvas dimensions
    var margin = {
        top: margins?.top || 40,
        right: margins?.right || 150,
        bottom: margins?.bottom || 150,
        left: margins?.left || 60,
      },
      width = can_width - margin.left - margin.right,
      height = can_height - margin.top - margin.bottom;

    // Scales
    var x = d3.scaleBand().rangeRound([0, width]).padding(0.1);

    var y = d3.scaleLinear().range([height, 0]);

    // Line
    var line = [];
    for (var i = 0; i < measures.length; i++) {
      line.push(
        d3
          .line()
          .x(function (d) {
            return x(d[dimensions[0].text]);
          })
          .y(function (d) {
            if (!d[measures[i].text]) {
              console.log(measures[i].text, d);
            }
            return y(d[measures[i].text]);
          }),
      );
    }

    //Remove already redered graph otherwise repeat graph will be created
    d3.select("#" + id)
      .select("svg")
      .remove();

    var chart = d3
      .select("#" + id)
      .append("svg")
      .style("background-color", colors.surface)
      .attr("width", can_width)
      .attr("height", can_height)
      .attr("fill", "none")
      .attr("line-stroke", "none")
      .append("g")
      .style("height", height)
      .style("width", width)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //Arguments for axes : Ranges for X, y
    x.domain(
      data.map(function (d) {
        return d[dimensions[0].text];
      }),
    );
    y.domain([
      d3.min(data, function (d) {
        var m = 999999;
        for (var i = 0; i < measures.length; i++) {
          if (d[measures[i].text] < m) {
            m = d[measures[i].text];
          }
        }
        return m;
      }),
      d3.max(data, function (d) {
        var m = 0;
        for (var i = 0; i < measures.length; i++) {
          if (d[measures[i].text] > m) {
            m = d[measures[i].text];
          }
        }
        return m;
      }),
    ]);

    // Axes
    chart
      .append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).ticks(10))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", rotateXTick ? "0.8em" : "-.8em")
      .attr("dy", rotateXTick ? "0.8em" : ".15em")
      .attr("transform", rotateXTick ? "rotate(0)" : "rotate(-65)")
      // .attr("font-weight", "100")
      .style("font-size", "12px")
      .style("fill", "#000")
      .call(d3.axisBottom(x));

    if (!noY) {
      if (!noYTick)
        chart.append("g").attr("class", "axis axis--y").call(d3.axisLeft(y));
      else
        chart
          .append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisLeft(y).ticks(0).tickSize(0));
    }

    //  Chart Title
    // chart.append("text")
    //     .attr("x", (width / 2))
    //     .attr("y", 10 - (margin.top / 2))
    //     .attr("text-anchor", "middle")
    //     .style("font-size", "16px")
    //     .style("stroke", "#000")
    //     .text("NIS Graph");

    for (var i = 0; i < measures.length; i++) {
      // Data Lines:
      chart
        .append("path")
        .datum(data)
        .attr("class", "line")
        .attr("stroke", d3.schemeCategory10[i])
        .attr("stroke-width", 4)
        .attr("d", line[i])
        .enter();

      chart
        .selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "circle")
        .attr("cx", function (d) {
          return x(d[dimensions[0].text]);
        })
        .attr("cy", function (d) {
          return y(d[measures[i].text]);
        })
        .attr("r", 4)
        .attr("stroke", colors.primary)
        .attr("fill", colors.primary);

      if (legend !== false) {
        //leagends
        chart
          .append("circle")
          .attr("cx", width + 20)
          .attr("cy", i * 30)
          .attr("r", 6)
          .style("fill", d3.schemeCategory10[i]);

        chart
          .append("text")
          .attr("x", width + 20 + 12)
          .attr("y", i * 30)
          .text(measures[i].text)
          .style("font-size", "15px")
          .style("fill", "black")
          .style("color", "black")
          .attr("alignment-baseline", "middle");
      }
    }

    //Grid Lines
    // svg.append('g')
    // .attr('class', 'grid')
    // .attr('transform', `translate(0, ${height})`)
    // .call(d3.axisBottom()
    //     .scale(x)
    //     .tickSize(-height, 0, 0)
    //     .tickFormat(''))

    // chart.append('g')
    //   .attr('class', 'grid')
    //   .call(d3.axisLeft()
    //   .scale(y)
    //   .tickSize(-width, 0, 0)
    //   .tickFormat(''))
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}
export default LineChartGeneric;
