import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import { setReducer, updateNLData } from "../../../store/action/nlFixAction";

export default function EditNl({ data, changeTrackData }) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(data?.value);

  const nlDataUpdateLoading = useSelector(
    (state) => state.nlFix?.nlDataUpdateLoading,
  );
  const nlDataUpdateSuccess = useSelector(
    (state) => state.nlFix?.nlDataUpdateSuccess,
  );
  const nlDataUpdateError = useSelector(
    (state) => state.nlFix?.nlDataUpdateError,
  );
  const nlDataUpdateMessage = useSelector(
    (state) => state.nlFix?.nlDataUpdateMessage,
  );
  const accessToken = useSelector((state) => state.auth?.accessToken);

  useEffect(() => {
    if (data) setValue(data.value);
  }, [data]);

  useEffect(() => {
    if (nlDataUpdateSuccess) {
      changeTrackData(data?.id, value);
      M.toast({ html: nlDataUpdateMessage || "Success" });
      dispatch(setReducer({ nlDataUpdateSuccess: false }));
      let elem = document.getElementById("edit-modal");
      var instance = M.Modal.getInstance(elem);
      if (instance.isOpen) {
        instance.close();
      }
    }
    if (nlDataUpdateError) {
      M.toast({ html: nlDataUpdateMessage || "Error" });
      dispatch(setReducer({ nlDataUpdateError: false }));
    }
  }, [nlDataUpdateSuccess, nlDataUpdateError]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onEdit = (e) => {
    e.preventDefault();
    dispatch(
      updateNLData(
        {
          year: data?.year,
          quarter: data?.quarter,
          cid: data?.cid,
          nlid: data?.nlid,
          rowId: data?.rowId,
          colId: data?.colId,
          value: value,
        },
        accessToken,
      ),
    );
  };

  console.log("state", data);
  if (nlDataUpdateLoading) {
    return (
      <div className="progress">
        <div className="indeterminate"></div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col s4">Year: {data?.year}</div>
        <div className="col s4">Quarter: {data?.quarter}</div>
        <div className="col s4">NL Id: {data?.nlid}</div>
      </div>
      <div className="row">
        <div className="col s4">Col Id: {data?.colId}</div>
        <div className="col s4">Row Id: {data?.rowId}</div>
      </div>
      <form onSubmit={onEdit}>
        <div className="row">
          <input id="value" value={value} onChange={onChange} />
        </div>
        <div className="row">
          <button className="btn right" type="submit">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}
