const initState = { 
    concallLoading: '',
    concallSuccess: '',
    NewsconcallSuccess:'',
    concallError: '',
    concall: [],
    brokerSuccess: '',
    brokerError: '',
    broker: [],
    chartData:[],
    initData: null,
    loadingSecCompanies:false,
    secCompaniesSuccess:false,
    secCompaniesError:false,
    secCompanies:[],
    concallSec:[]
  };

  const concallReducer = (state = initState, action) => {
    switch (action.type) {
      case "BROKER_FETCH_SUCCESS" : 
          return {
              ...state,
              brokerSuccess: true,
              brokerError: '',
              broker: action.payload,
          };
      
      case "BROKER_FETCH_ERROR" :
          return {
              ...state,
              brokerSuccess: '',
              brokerError: true,
              broker: []
          };

      case "CONCALL_SCORE_FETCH_LOADING" :
          return {
            ...state,
            concallLoading: true,
            concallSuccess:false,
            concallError:false
          }
      case "CONCALL_SCORE_FETCH_SUCCESS" : 
          return {
              ...state,
              concallLoading:false,
              concallSuccess: true,
              concallError: false,
              concallSec: action.sec,
              concall: action.pri,
              // initData:action.payload.body
          };
      
      case "CONCALL_SCORE_FETCH_ERROR" :
          return {
              ...state,
              concallLoading:false,
              concallSuccess: false,
              concallError: true,
              concall: []
          };
      case "CONCALL_SECONDARY_FETCH_LOADING" :
          return {
            ...state,
            loadingSecCompanies:true,
            secCompaniesSuccess:false,
            secCompaniesError:false,
            secCompanies:[]
          }
      case "CONCALL_SECONDARY_FETCH_SUCCESS" : 
        return {
          ...state,
          loadingSecCompanies:false,
          secCompaniesSuccess:true,
          secCompaniesError:false,
          secCompanies:action.payload.data
        };
    
      case "CONCALL_SECONDARY_FETCH_ERROR" :
        return {
          ...state,
          loadingSecCompanies:false,
          secCompaniesSuccess:false,
          secCompaniesError:true,
          secCompanies:[]
        };
      case "CONCALL_SECONDARY_UPDATE_LOADING" :
          return {
            ...state,
            updateSecCompanies:false,
          }
      case "CONCALL_SECONDARY_UPDATE_SUCCESS" : 
        return {
          ...state,
          updateSecCompanies:false,
        };
    
      case "CONCALL_SECONDARY_UPDATE_ERROR" :
        return {
          ...state,
          updateSecCompanies:true
        };
      case "PIE_CHART_SUCCESS" : 
        return {
            ...state,
            pieChartSuccess:true,
            pieChartError:false,
            chartData: action.payload
        };
      case 'HOME_CLICK':
          return initState;
      default:
          return state;
    }
};

export default concallReducer