import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLifeList } from "../../../store/action/lifeAction";
import { getCompanyListForLifeReport } from "../../../utils/nlHelper";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { queryBuilder } from "../../../utils/helper";

export default function LifeValidator() {
  const dispatch = useDispatch();

  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [lifeId, setLifeId] = useState(false);

  const accessToken = useSelector((state) => state.auth?.accessToken);
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const life = useSelector((state) => state.life?.life);

  useEffect(() => {
    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    if (years?.length > 0 && quarters?.length > 0) {
      setYear(years[years.length - 1].value);
      setQuarter(quarters[quarters.length - 1].value);
    }
    dispatch(fetchLifeList(accessToken));
  }, []);

  const isInsuranceCompany = (company) => {
    return getCompanyListForLifeReport(company, sectors);
  };
  return (
    <div className="row" style={{ padding: 10 }}>
      <div className="col s12">
        <div className="row">
          <div className="col s2">
            <label htmlFor="name">Company</label>
            <select
              required
              id="companyId"
              className="browser-default custom-select"
              onChange={(e) => {
                setCompanyId(e.target.value);
              }}
            >
              <option value={""}>Select</option>
              {companies?.filter(isInsuranceCompany)?.map((com, index) => (
                <option value={com.id} key={index}>
                  {com.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s2">
            <label htmlFor="name">Year</label>
            <select
              required
              id="year"
              className="browser-default custom-select"
              onChange={(e) => {
                setYear(e.target.value);
              }}
            >
              <option value={""}>Select</option>
              {years?.map((year, index) => (
                <option value={year.value} key={index}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col s2">
            <label htmlFor="name">Quarter</label>
            <select
              required
              id="quarter"
              className="browser-default custom-select"
              onChange={(e) => {
                setQuarter(e.target.value);
              }}
            >
              <option value={""}>Select</option>
              {quarters?.map((quarter, index) => (
                <option value={quarter.value} key={index}>
                  {quarter.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col s2">
            <label htmlFor="name">Life</label>
            <select
              required
              id="life"
              className="browser-default custom-select"
              onChange={(e) => {
                setLifeId(e.target.value);
              }}
            >
              <option value={""}>Select</option>
              {life
                ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((item, index) => (
                  <option value={item.id} key={item.id}>
                    {item.lable}
                  </option>
                ))}
            </select>
          </div>
          <div className="col s3">
            <Link
              to={{
                pathname: queryBuilder("/life/data/fix", {
                  company: companies?.find(
                    (com) => Number(com.id) === Number(companyId)
                  )?.name,
                  life: life?.find((f) => Number(f.id) === Number(lifeId))
                    ?.label,
                  year,
                  quarter,
                  cid: companyId,
                  lifeId: lifeId,
                }),
              }}
              target="_blank"
              rel="noopener noreferrer"
              disabled={
                companyId === "" ||
                year === "" ||
                quarter === "" ||
                lifeId === ""
              }
            >
              <Button variant="contained" style={{ marginTop: 20 }}>
                Fix Data
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
