import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BarChartGeneric from "../../layout/BarChartGeneric";

export default function MarketShare({ label, data }) {
  const [marketData, setMarketData] = useState(null);
  const [segmentData, setSegmentData] = useState(null);

  useEffect(() => {
    if (data) {
      setMarketData(data?.market);
      setSegmentData(data?.segments);
    }
  }, [data]);

  return (
    <div className="row">
      <div className="col s6">
        <p className="center-align" style={{ fontWeight: "bold" }}>
          Overall
        </p>
        <BarChartGeneric
          id="top-5-stateData"
          data={marketData}
          dimensions={[{ text: "Company Name" }]}
          measures={[{ text: "Market %" }]}
          height={500}
          width={650}
          hideLegend={false}
          // charLength={10}
          margins={{ left: 120 }}
          fontSize={14}
          boldFont={true}
          noLabelWrapY={true}
          // noYLabel={true}
          // noXLabel={true}
          labelInBar={true}
        />
      </div>
      <div className="col s6">
        {segmentData &&
          Object.keys(segmentData)?.map(
            (segment, i) =>
              i < 4 && (
                <div className="col s6">
                  <p className="center-align" style={{ fontWeight: "bold" }}>
                    {segment.toUpperCase()}
                  </p>
                  <BarChartGeneric
                    id={"top-5-" + i}
                    data={segmentData[segment]}
                    dimensions={[{ text: "Company Name" }]}
                    measures={[{ text: segment }]}
                    height={220}
                    width={300}
                    hideLegend={false}
                    // charLength={10}
                    margins={{ left: 120, right: 40 }}
                    fontSize={14}
                    boldFont={true}
                    noXLabel={true}
                    noLabelWrapY={true}
                    labelInBar={true}
                    barFontSize={"12px"}
                  />
                </div>
              )
          )}
      </div>
    </div>
  );
}
