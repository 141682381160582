import createFormData from "../../help/CreateFormData";
import config from "../../config/config"

var backendUrl = config.backendUrl


export const uploadFile = (file, token)=> {
    return (dispatch) => {
      dispatch({type: 'LOADING_UPLOAD'});
      fetch(backendUrl + "/getAnualReportScore", {
        method: 'post',
        body:createFormData(file,{
          token:'NfxoS9oGjA6MiArPtwg4aR3Cp4ygAbNA2uv6Gg4m'
        }),
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'UPLOAD_SUCCESSFUL',
              data:data
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'UPLOAD_ERROR',
              data:data
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'UPLOAD_ERROR',
      });
    })
  };
  };

  export const selectYear = (companyId, token)=> {
    return (dispatch) => {
      dispatch({
        type: 'YEAR_FETCH_LOADING',
      });
      fetch(backendUrl + "/company/"+companyId+"/getYears", {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'YEAR_FETCH_SUCCESSFUL',
              payload:data
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'YEAR_FETCH_ERROR',
              payload:data
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'YEAR_FETCH_ERROR',
      });
    })
  };
  };

  export const submitPD = (companyId,year, token)=> {
    return (dispatch) => {
      dispatch({
        type: 'SUBMIT_PD_LOADING',
      });
      fetch(backendUrl + "/company/"+companyId+"/year/"+year+"/getAnualScore", {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        response.json().then((data)=>{
            if (response.status === 200) {
            dispatch({
              type: 'SUBMIT_PD_SUCCESSFUL',
              payload:data
            });
          }
          else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR"
            });
          }
          else {
            dispatch({
              type: 'SUBMIT_PD_ERROR',
              payload:data
            });
          }
        })
      }
    )
    .catch(response => {
      dispatch({
        type: 'SUBMIT_PD_ERROR',
      });
    })
  };
  };

  export const initResponse = ()=> {
    return (dispatch) => {
      dispatch({
        type: 'INITIAL_STATE',
      });
   
  };
  };