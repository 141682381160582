import React from "react";
import { Link } from "react-router-dom";

export const CrawlerModal = () => {
  return (
    <div id="crawlerModal" className="modal">
      <div className="modal-content">
        <h4>Crawler Modal</h4>
        <table className="highlight responsive-table">
          <thead>
            <tr>
              <th>Headline</th>
              <th>Company</th>
              <th>Sector</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <Link
                  className="db"
                  to="https://economictimes.indiatimes.com/topic/newspaper"
                  style={{ color: "#2487ce" }}
                >
                  Headline 1
                </Link>
              </td>
              <td>abc</td>
              <td>xyz</td>
            </tr>
            <tr>
              <td>
                <Link
                  className="db"
                  to="https://economictimes.indiatimes.com/topic/newspaper"
                  style={{ color: "#2487ce" }}
                >
                  Headline \2
                </Link>
              </td>
              <td>abc</td>
              <td>xyz</td>
            </tr>
            <tr>
              <td>
                <Link
                  className="db"
                  to="https://economictimes.indiatimes.com/topic/newspaper"
                  style={{ color: "#2487ce" }}
                >
                  Headline 1\3
                </Link>
              </td>
              <td>abc</td>
              <td>xyz</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <a href="#!" className="modal-close waves-effect waves-green btn-flat">
          Close
        </a>
      </div>
    </div>
  );
};
