import React from "react";
import papers from '../../config/researchs'

export default function ArticleNews() {
  return (
    <div>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto">
            <a href="/">
              <h2>Textplor</h2>
            </a>
          </h1>
        </div>
      </header>
      <section className="d-flex align-items-center">
        <div className="container" data-aos="fade-up" style={{width:'90%'}}>
            <div className="row prod-detail">
              <h3 className="center center-align">
                News Impact Score and Stock Market Quality: Information Content in Business News
              </h3>
              
          </div>
          <div className="row">
            <div className="col s12">
              <div className="col s4">
                <div className="row">
                  <div className="col s4">
                    <img 
                      type="image"
                      className="circle"
                      height="70"
                      width="70"
                      src="assets/img/team/ashok.jpg"alt=""/>
                  </div>
                  <div className="col s6"> 
                    <span><strong>Ashok Banerjee</strong></span>
                    <br/>
                    <span><strong>21 Aug 2021</strong></span>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                      <span className="mybadge-info">Textplor</span>
                      <span className="mybadge-primary">News Analysis</span>
                      <span className="mybadge-warning">Finance</span>
                  </div>
                </div>
              </div>
              <div className="col s8">
                <h5>
                    The news impact score (NIS) uses newspaper reports to estimate corporate stress levels. 
                    There is a strong negative correlation between NIS and stock returns. Higher NIS is followed 
                    by greater volatility in stock returns.
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <h2>
              News Frequency and NIS: Basic Features
            </h2>
            <p>
                We look at firm-level news in this paper and hence ignore any news on economy in general.
                Since news is sourced from business dailies (e.g Economic Times, Mint, Money Control etc.),
                NIS does not capture sentiments of any general, value-neutral news. We have looked at top
                500 NS-listed companies (NSE 500 Index) and analysed the NIS data for these companies over
                11 years (2010 to 2020).We have removed companies for which stock price data were not
                1 Geert Bekaert, and Guojun Wu. Asymmetric Volatility and Risk in Finance Markets. 
                The Review of Finance available, leaving us with 399 of the Nifty 500 companies. 
                These companies are sorted on the basis of aggregate news frequency in a particular year and 
                the top decile represents top ten percent companies which are heavily reported in the media in that year.
            </p>
          </div>
          <div className="row">
            <h2>
            Data and Methodology:
            </h2>
            <p>
              We have looked at the effect of NIS on stock returns and volatility. Since, NIS are used at
              monthly frequency, stock prices and hence returns are estimated at monthly rest. Stock prices
              for firms are collected from Yahoo Finance. Stock returns are estimated as (natural)log of price
              relatives. In other words, we have defined stock returns as monthly change in prices on a
              logarithmic scale. Historical volatility (standard deviation) of stock returns is estimated using
              past 30 months returns on a rolling basis.
              <br/>
              <br/>
              News flow was much lower during 2010 through 2013. Also, a large number of companies had
              very low news volume even after 2013. We have only considered, for our analysis, those
              companies which had news for at least 80% of the months since July 2014. This filter has
              reduced our sample size to 86 companies out of about 400 companies. Thus, we have monthly
              data for the variables since July 2014 till December 2020.
        
            </p>
          </div>
          <div className="row">
            <h2>
            Results and Analysis:
            </h2>
            <p>
              We observe that returns are negatively correlated with NIS, lag of NIS, and lag of
              volatility whereas volatility is positively correlated with NIS, which is ideally correct since
              higher NIS indicates bad news and thus lower returns, but high volatility). Also, return and
              2 Panel analysis is a statistical method to analyse two-dimensional (cross-section and
              time) panel data. The data are usually collected over time and over the same individuals and
              volatility are positively correlated as higher risk demands greater rewards. There is a negative
              relationship between current and past returns suggesting negative autocorrelations in returns
              which is a well-established feature of stock returns. 
              <br/>
              <br/>
              There are two types of effects in a panel model: Fixed or Random effect. A model is a fixed
              effect model if the variables are constant across individuals, random otherwise. To check which
              effect is best suited for the given data, we have conducted the Hausman test. Results show that
              it is better to use random effect models.
            </p>
          </div>
          <div className="row">
            <a href={papers.NIS}
              target="_blank"
              rel="noopener noreferrer"
              >Read more...</a>
          </div>
        </div>
      </section>

      <a href="#" className="back-to-top">
        <i className="ri-arrow-up-line"></i>
      </a>
    </div>
  );
}
