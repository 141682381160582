import React, { Component } from "react";
import * as d3 from "d3";

export default class PieChart extends Component {
  componentDidMount() {
    //console.log(this.props)
    this.drawChart();
  }

  componentDidUpdate() {
    //console.log(this.props)
    this.drawChart();
  }

  drawChart() {
    const { data, width, height } = this.props;
    //console.log("data", data);
    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d.count);

    const radius = (Math.min(width, height) / 2) * 0.5;
    const arcLabel = d3.arc().innerRadius(radius).outerRadius(radius);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(width, height) / 2 - 1);

    const color = d3
      .scaleOrdinal()
      .domain(data && data.map((d) => d.topic))
      .range(
        d3
          .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
          .reverse()
      );

    const arcs = pie(data);
    d3.select("#svg-element").select("svg").remove();
    const svg = d3
      .select("#svg-element")
      .append("svg")
      .attr("viewBox", [-width / 2, -height / 2, width, height]);

    svg
      .append("g")
      .attr("stroke", "white")
      .selectAll("path")
      .data(arcs)
      .join("path")
      .attr("fill", (d) => color(d.data.topic))
      .attr("d", arc)
      .append("title")
      .text((d) => `${d.data.topic}: ${d.data.count.toLocaleString()}`);

    svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 8)
      .attr("fill", data.length > 1 ? "black" : "white")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(arcs)
      .join("text")
      .attr("transform", (d) => `translate(${arcLabel.centroid(d)})`)
      .call((text) =>
        text
          .append("tspan")
          .attr("y", "-0.4em")
          .attr("font-weight", "bold")
          .text((d) => d.data.topic)
      )
      .call((text) =>
        text
          .filter((d) => d.endAngle - d.startAngle > 0.25)
          .append("tspan")
          .attr("x", 0)
          .attr("y", "0.7em")
          .attr("fill-opacity", 0.7)
          .text((d) => d.data.count.toLocaleString())
      );
  }

  render() {
    // //console.log("from pie chart")
    return (
      <div
        id={this.props.id ? this.props.id : "svg-element"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginLeft: 10,
          paddingTop: 10,
          width: "95%",
          height: 275,
          marginRight: "70%",
          marginTop: 10,
          backgroundColor: "rgb(255,255,255,0.5)",
          border: "0px solid",
          borderRadius: 15,
        }}
      ></div>
    );
  }
}
