import React, { Component } from "react";
import { connect } from "react-redux";
import { getMissingNLReport, getNlList } from "../../store/action/nlActions";
import { getCompanyList } from "../../store/action/nisActions";
import {
  companyNameMap,
  getCompanyListForNLReport,
  yearQuarterRev,
} from "../../utils/nlHelper";

const fontSize = "8px";

export class NLTrack extends Component {
  state = {
    quarter: null,
  };

  componentDidMount() {
    let lastYQ = this.props.nlYearQuarterAvailable[0]?.fyq;
    this.props.GetCompanyList(this.props.auth.accessToken);
    this.props.GetNlList(null, this.props.auth.accessToken);
    this.props.GetMissingNLReport(
      { year: lastYQ?.split("-")[0], quarter: lastYQ?.split("-")[1] },
      this.props.auth.accessToken
    );

    this.setState({
      quarter: lastYQ,
    });
  }

  onCheck = (e) => {
    this.setState({ quarter: e.target.id });
    this.props.GetMissingNLReport(
      {
        year: e.target.id.split("-")[0],
        quarter: e.target.id.split("-")[1],
      },
      this.props.auth.accessToken
    );
  };

  render() {
    console.log("props", this.props);
    // console.log("State", this.state);

    if (this.props.nlListLoading || this.props.missingNLReportLoading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <div className="preloader-wrapper big active">
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ position: "sticky", top: 64, backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div>Select Quarter : </div>
            {this.props.nlYearQuarterAvailable?.map((d, i) => (
              <div>
                <label>
                  <input
                    id={d.fyq}
                    checked={this.state.quarter === d.fyq}
                    name="group1"
                    type="radio"
                    onChange={this.onCheck}
                  />
                  <span>{d.label}</span>
                </label>
              </div>
            ))}
          </div>
          <table className="centered">
            <thead>
              <tr>
                <th
                  style={{
                    borderRight: "2px solid white",
                    borderBottom: "2px solid white",
                    wordWrap: "normal",
                    width: "130px",
                    fontSize: fontSize,
                    color: "white",
                    backgroundColor: "#828282",
                  }}
                >
                  Company
                </th>
                {this.props.nlList
                  ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                  .filter(f => !['nl_45b', 'nl_45c'].includes(f.name))
                  .map((d, i) => (
                    <th
                      key={i}
                      style={{
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        fontSize: fontSize,
                        color: "white",
                        backgroundColor: "#828282",
                      }}
                    >
                      {d.lable.replace("NL ", "")}
                    </th>
                  ))}
              </tr>
            </thead>
          </table>
        </div>
        {this.props.nlList && (
          <div>
            <table className="centered">
              <tbody>
                {this.props.missingNLReport &&
                  this.props.companyList
                    ?.filter((company) =>
                      getCompanyListForNLReport(company, this.props.sectors) &&
                      company.name !== "Bharti AXA"
                    )
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((d, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            wordWrap: "normal",
                            borderRight: "2px solid white",
                            borderBottom: "2px solid white",
                            width: "130px",
                            fontSize: "10px",
                            fontWeight: "900",
                          }}
                        >
                          {d.name}
                        </td>
                        {this.props.nlList
                          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                          .filter(f => !['nl_45b', 'nl_45c'].includes(f.name))
                          .map((dnl, nli) => (
                            <td
                              key={nli}
                              title={`Company : ${d.name} \nNL : ${dnl.lable}`}
                              style={{
                                borderRight: "2px solid white",
                                borderBottom: "2px solid white",
                                borderRadius: "3px",
                                backgroundColor:
                                  this.props.missingNLReport[d.id] &&
                                  this.props.missingNLReport[d.id][dnl.id]
                                    ? "#eddb72"
                                    : "#88cc68",
                              }}
                            ></td>
                          ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // //console.log(state);
  return {
    auth: state.auth,
    profile: state.auth,
    user: state.auth.user,
    sectors: state.SM.sectorList,

    companyList: state.NIS.companyList,

    nlListLoading: state.nl.nlListLoading,
    nlListSuccess: state.nl.nlListSuccess,
    nlListError: state.nl.nlListError,
    nlList: state.nl.nlList,

    missingNLReportLoading: state.nl.missingNLReportLoading,
    missingNLReportSuccess: state.nl.missingNLReportSuccess,
    missingNLReportError: state.nl.missingNLReportError,
    missingNLReport: state.nl.missingNLReport,

    nlYearQuarterAvailable: yearQuarterRev(state.nl.nlYearQuarterAvailable),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanyList: (token) => {
      dispatch(getCompanyList(token));
    },
    GetNlList: (query, token) => {
      dispatch(getNlList(query, token));
    },
    GetMissingNLReport: (query, token) => {
      dispatch(getMissingNLReport(query, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NLTrack);
