import React from "react";

export const LoaderTwo = (props) => {
  return (
    <div className="bar">
      <div className="circle-new"></div>
      <p className="loader2">Loading</p>
    </div>
  );
};
