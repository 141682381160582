import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyListForNLReport } from "../../../utils/nlHelper";
import { Backdrop, Button, CircularProgress, Icon } from "@mui/material";
import { setReducer, uploadNlPdf } from "../../../store/action/nlFixAction";
import swal from "sweetalert";
import { getNlList } from "../../../store/action/nlActions";

export default function NLPdfUploader() {
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth?.accessToken);
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const uploadNLPdfLoading = useSelector(
    (state) => state.nlFix?.uploadNLPdfLoading
  );
  const uploadNLPdfSuccess = useSelector(
    (state) => state.nlFix?.uploadNLPdfSuccess
  );
  const uploadNLPdfError = useSelector(
    (state) => state.nlFix?.uploadNLPdfError
  );
  const uploadNLPdfMessage = useSelector(
    (state) => state.nlFix?.uploadNLPdfMessage
  );
  const nlList = useSelector((state) => state.nl.nlList);

  const [companyId, setCompanyId] = useState("");
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [particularNL, setParticularNL] = useState(false);
  const [nlId, setNlId] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    if (years?.length > 0 && quarters?.length > 0) {
      setYear(years[years.length - 1].value);
      setQuarter(quarters[quarters.length - 1].value);
    }
    dispatch(getNlList({}, accessToken));
  }, []);

  useEffect(() => {
    if (uploadNLPdfSuccess) {
      swal("Success", uploadNLPdfMessage, "success");
      dispatch(setReducer({ uploadNLPdfSuccess: false }));
    }
    if (uploadNLPdfError) {
      swal("Oops!", uploadNLPdfMessage, "error");
      dispatch(setReducer({ uploadNLPdfError: false }));
    }
  }, [uploadNLPdfSuccess, uploadNLPdfError]);

  const isInsuranceCompany = (company) => {
    return getCompanyListForNLReport(company, sectors);
  };

  const upload = (e) => {
    e.preventDefault();
    dispatch(
      uploadNlPdf(
        companyId,
        year,
        quarter,
        isPdf,
        forceUpdate,
        particularNL,
        nlId,
        file,
        accessToken
      )
    );
  };

  return (
    <div className="row" style={{ padding: 10 }}>
      {uploadNLPdfLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="col s12" style={{ textAlign: "center" }}>
        <fieldset style={{ borderRadius: "7px" }}>
          <legend>Upload NL</legend>
          <form>
            <div className="row">
              <div className="col s4">
                <label htmlFor="name">Company</label>
                <select
                  required
                  id="companyId"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setCompanyId(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {companies?.filter(isInsuranceCompany)?.map((com, index) => (
                    <option value={com.id} key={index}>
                      {com.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s4">
                <label htmlFor="name">Year</label>
                <select
                  required
                  id="year"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {years?.map((year, index) => (
                    <option value={year.value} key={index}>
                      {year.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col s4">
                <label htmlFor="name">Quarter</label>
                <select
                  required
                  id="quarter"
                  className="browser-default custom-select"
                  onChange={(e) => {
                    setQuarter(e.target.value);
                  }}
                >
                  <option value={""}>Select</option>
                  {quarters?.map((quarter, index) => (
                    <option value={quarter.value} key={index}>
                      {quarter.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={isPdf}
                      onChange={() => {
                        setIsPdf(!isPdf);
                      }}
                    />
                    <span>PDF</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={forceUpdate}
                      onChange={() => {
                        setForceUpdate(!forceUpdate);
                      }}
                    />
                    <span>Force Update</span>
                  </label>
                </p>
                <p>
                  <label>
                    <input
                      type="checkbox"
                      checked={particularNL}
                      onChange={() => {
                        setParticularNL(!particularNL);
                        setIsPdf(true);
                      }}
                    />
                    <span>Paricular NL</span>
                  </label>
                </p>
              </div>
            </div>
            {particularNL && (
              <div className="row">
                <div className="col s3" style={{ textAlign: "left" }}>
                  <select
                    required
                    id="nl"
                    className="browser-default custom-select"
                    onChange={(e) => {
                      setNlId(e.target.value);
                    }}
                  >
                    <option value={""}>Select</option>
                    {nlList?.map((item, index) => (
                      <option value={item.id} key={item.id}>
                        {item.lable}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <input
                  required
                  id="file"
                  type="file"
                  accept={isPdf ? ".pdf" : ".zip"}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12" style={{ textAlign: "left" }}>
                <Button variant="contained" type="action" onClick={upload}>
                  Upload
                  <Icon>upload</Icon>
                </Button>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </div>
  );
}
