import React  from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const CompanyComponent = ({HandleChange, state, HandleChangeSec, companies, index}) => {
    return (
    <>
        <div className="input-field col s6">
            <select
                className="browser-default"
                onChange={(e) => HandleChange(e, index)}
                // value={state.configCompanies[index-1]?state.configCompanies[index-1].primary:''}
                id={'primaryCompany-'+index}
            >
                <option value="" disabled defaultValue selected>Choose primary company</option>
                {
                    companies.map((com)=>
                        <option key={com.id} value={com.id}>{com.name}</option>
                    )
                }
            </select>
            {/* <label>select primary companies</label> */}
        </div>
        <div className="input-field col s6">
            <Multiselect
            onRemove={(data) => HandleChangeSec(data, index)}
            onSelect={(data) => HandleChangeSec(data, index)}
            options={companies}
            displayValue="name"
            id={'secCompanies-'+index}
            placeholder="Select secondary companies"
            />
        </div>
    </>
  );  

}

export default CompanyComponent;  
