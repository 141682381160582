import React from "react";
import GeoChart from "./laylout/GeoChart";
import { useState } from "react";
import { useEffect } from "react";
import { getArrayOfArray } from "../../../utils/nlHelper";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import StackBar from "./laylout/StackBar";
import StackBarHorizontal from "./laylout/StackBarHorizontal";
import {
  NL_TYPE_GIC,
  NL_TYPE_LIFE,
  graphColors,
  specialColorMap,
  specialColorMapLifeGeo,
} from "../../../config/constants";
import Segments from "./laylout/Segments";

export default function GeoCompDashboardLife({ label, data, type }) {
  const [reportData, setReportData] = useState(null);
  const [reportDataSecondary, setReportDataSecondary] = useState(null);
  const [secondaryCompanies, setSecCompanies] = useState(null);
  const [selCompany, setSelCompany] = useState(null);
  const [myCompany, setMyCompany] = useState(null);
  const [initial, setInitial] = useState(false);
  const [segData, setSegData] = useState([]);
  const [segDataSec, setSegDataSec] = useState([]);
  const [overallCol, setOverallCol] = useState("Premium (Total)");
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  useEffect(() => {
    if (data && data.segments) {
      setAllData();
    }
  }, [data, overallCol]);

  const setAllData = () => {
    let insSector = sectors?.find(
      (sec) => sec?.name?.toLowerCase() === "insurance",
    );
    let myCom = userCompany?.find(
      (com) =>
        com?.detail?.sector_id === insSector?.id &&
        com?.detail?.insuranceType === NL_TYPE_LIFE,
    );
    setMyCompany(myCom);
    let overallSegmentData = data.segments[overallCol];
    let myCompanyData = overallSegmentData?.filter(
      (data) => data?.company === myCom?.detail?.name,
    );
    // console.log("My company", myCom);
    // console.log("My company data", data.segments);

    let sec = myCom?.secondaries ? myCom?.secondaries[0] : null;
    setSecCompanies(myCom?.secondaries);
    setSelCompany(sec);
    let mySecCompanyData = overallSegmentData?.filter(
      (data) => data?.company === sec?.name,
    );

    // console.log("mySecCompanyData", mySecCompanyData);

    mySecCompanyData = getArrayOfArray(mySecCompanyData, ["state", "revenue"]);
    // console.log("My company data", myCompanyData, mySecCompanyData);
    myCompanyData = getArrayOfArray(myCompanyData, ["state", "revenue"]);
    // console.log("My sec com", mySecCompanyData, myCompanyData);

    setReportData(myCompanyData);
    setReportDataSecondary(mySecCompanyData);
    if (!initial) setInitial(true);
    // setStackData(myCom, sec);
  };

  // const setStackData = (myCom, secCom) => {
  //   let segs = Object.keys(data?.segments)?.filter((seg) => seg != overallCol);
  //   let finalData = [];
  //   for (let i = 0; i < segs.length; i++) {
  //     let dSeg = data.segments[segs[i]];
  //     let segsum = 0.0;
  //     let selData = dSeg.filter((d) => d["company"] === myCom?.detail?.name);
  //     for (let j = 0; j < selData?.length; j++) {
  //       segsum += selData[j].revenue;
  //     }
  //     finalData.push({
  //       company: myCom?.detail?.name,
  //       segment: segs[i],
  //       revenue: segsum,
  //     });
  //   }
  //   setSegData(finalData);
  //   finalData = [];
  //   for (let i = 0; i < segs.length; i++) {
  //     let dSeg = data.segments[segs[i]];
  //     let segsum = 0.0;
  //     let selData = dSeg.filter((d) => d["company"] === secCom?.name);
  //     for (let j = 0; j < selData?.length; j++) {
  //       segsum += selData[j].revenue;
  //     }
  //     finalData.push({
  //       company: secCom?.name,
  //       segment: segs[i],
  //       revenue: segsum,
  //     });
  //   }
  //   setSegDataSec(finalData);
  // };

  const onCompanyChange = (e) => {
    let comId = e.target.value;
    let com = secondaryCompanies?.find((com) => com.id === Number(comId));
    console.log("COCM", com, comId);
    let mySecCompanyData = data.segments[overallCol]?.filter(
      (data) => data?.company === com.name,
    );
    mySecCompanyData = getArrayOfArray(mySecCompanyData, ["state", "revenue"]);
    setReportDataSecondary(mySecCompanyData);
    setSelCompany(com);
  };

  const onStateSelect = (reg, t) => {
    if (reg[0] === "Orissa") {
      reg[0] = "Odisha";
    }
    let segs = Object.keys(data?.segments)?.filter((seg) => seg != overallCol);
    let finalData = [];
    for (let i = 0; i < segs.length; i++) {
      let dSeg = data.segments[segs[i]];
      let selData = dSeg.find(
        (d) =>
          d["state"] === reg[0] && d["company"] === myCompany?.detail?.name,
      );
      finalData.push({
        segment: segs[i],
        company: myCompany?.detail?.name,
        revenue: selData?.revenue,
      });
    }

    setSegData(finalData);
  };
  const onStateSelectSec = (reg, t) => {
    console.log("selCompany", selCompany);
    if (reg[0] === "Orissa") {
      reg[0] = "Odisha";
    }
    let segs = Object.keys(data?.segments)?.filter((seg) => seg != overallCol);
    let finalData = [];
    for (let i = 0; i < segs.length; i++) {
      let dSeg = data.segments[segs[i]];
      let selData = dSeg.find(
        (d) => d["state"] === reg[0] && d["company"] === selCompany?.name,
      );
      finalData.push({
        segment: segs[i],
        company: selCompany?.name,
        revenue: selData?.revenue,
      });
    }

    setSegDataSec(finalData);
  };

  const onStateChange = (reg, t) => {
    onStateSelect(reg, t);
    onStateSelectSec(reg, t);
  };

  console.log("REPORT DATA: ", data.segments);

  return (
    <>
      <div className="row" style={{ marginBottom: 0 }}>
        {initial && !myCompany && !selCompany && (
          <Alert severity="error">
            Configuration for primary & secondary company not added
          </Alert>
        )}
        <div className="ccol s12">
          <Segments
            lobs={Object.keys(specialColorMapLifeGeo)}
            selLob={overallCol}
            handleLobChange={(lob) => {
              setOverallCol(lob);
            }}
          />
        </div>
        <div className="col s6">
          <div className="col s12">
            <strong>{myCompany?.detail?.name}</strong>
          </div>
          <GeoChart
            height={400}
            width="100%"
            data={reportData || []}
            ChartSelect={onStateChange}
          />
          {/* <StackBarHorizontal
            id="my-com"
            data={segData}
            dimensions={[{ text: "company" }]}
            measures={[{ text: "revenue" }]}
            group={"segment"}
            can_height={160}
            can_width={580}
            hideLegend={false}
            legendInRow={6}
            noXTick={true}
            colorMap={
              type === NL_TYPE_LIFE ? specialColorMapLifeGeo : specialColorMap
            }
          /> */}
        </div>
        <div className="col s6">
          <div className="col s6">Change Peer</div>
          <div className="col s6">
            <select
              style={{
                padding: 0,
                height: "1.5rem",
                borderBottom: "1px solid black",
                // borderRadius: 10,
                fontSize: 12,
              }}
              id="selectedSegment"
              className="browser-default"
              onChange={onCompanyChange}
              value={selCompany?.id}
            >
              <option value="">Set Peer</option>
              {secondaryCompanies?.map((company) => (
                <option value={company.id}>{company.name}</option>
              ))}
            </select>
          </div>
          <GeoChart
            height={400}
            width="100%"
            data={reportDataSecondary || []}
            ChartSelect={onStateChange}
          />
          {/* <StackBarHorizontal
            id="my-sec-com"
            data={segDataSec}
            dimensions={[{ text: "company" }]}
            measures={[{ text: "revenue" }]}
            group={"segment"}
            can_height={160}
            can_width={580}
            hideLegend={true}
            noXTick={true}
            colorMap={
              type === NL_TYPE_LIFE ? specialColorMapLifeGeo : specialColorMap
            }
            // margins={{ left: 1 }}
          /> */}
        </div>
      </div>
      {/* <div style={{ display: "flex", flexDirection: "row" }}>
        {graphColors?.map((color) => (
          <div
            style={{
              height: 100,
              width: 100,
              backgroundColor: color,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {color}
          </div>
        ))}
      </div> */}
    </>
  );
}
