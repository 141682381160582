import config from "../../config/config";

var backendUrl = config.backendUrl;

export const getSectorList = (token) => {
  return (dispatch) => {
    var url = backendUrl + "/sectors";
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          dispatch({
            type: "AUTHENTICATION_ERROR",
          });
        }
        response.json().then((data) => {
          // //console.log("LIST FETCH")
          if (response.status === 200) {
            dispatch({
              type: "SECTOR_LIST_FETCH_SUCCESS",
              payload: data.data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "SECTOR_LIST_FETCH_ERROR",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: "SECTOR_LIST_FETCH_ERROR",
        });
      });
  };
};
export const getNewsSM = (body, token) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_SM" });
    var url = backendUrl + "/getSectorScores";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            data["body"] = body;
            // //console.log("action", data)
            dispatch({
              type: "NEWS_SM_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "NEWS_SM_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "NEWS_SM_FETCH_ERROR",
        });
      });
  };
};

export const getSMTopNews = (body, token, selected) => {
  return (dispatch) => {
    dispatch({ type: "LOADING_SM_TOP_NEWS", payload: selected });
    var url = backendUrl + "/getSectorTopnews";
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            data["body"] = body;
            data["selected"] = selected;
            // //console.log("action", data)
            dispatch({
              type: "SM_TOP_NEWS_FETCH_SUCCESS",
              payload: data,
            });
          } else if (response.status === 403 || response.status === 401) {
            dispatch({
              type: "AUTHENTICATION_ERROR",
            });
          } else {
            dispatch({
              type: "SM_TOP_NEWS_FETCH_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        dispatch({
          type: "SM_TOP_NEWS_FETCH_ERROR",
        });
      });
  };
};
