const initState = {
  success: false, // make true when auth succdessful
  loading: false,
  error: "",
  companyData: null,
  sectorData: null,
  reportData: null,
  secReportData: null,
  news: null,
  companyName: null,
  sectorName: null,
  secChartData: null,
  secCompanies: null,
  reportLoading: false,
  reportSuccess: false,
  reportError: false,
};

const CombineReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_COMBINE_DATA":
      return {
        ...state,
        loading: true,
        success: false,
        error: "",
        companyData: null,
        sectorData: null,
        reportData: null,
        news: null,
        secReportData: null,
        companyName: null,
      };

    case "COMBINE_DATA_FETCH_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
        companyData: action.payload.company.data,
        sectorData: action.payload.sector.data,
        reportData: action.payload.report,
        news: action.payload.news,
        secReportData: action.payload.secArData,
        companyName: action.payload.company.companyName,
        sectorName: action.payload.sector.sectorName,
        secChartData: action.payload.secChartData,
        secCompanies: action.payload.secCompanies,
      };
    case "FETCH_USER_REPORTS_LOADING":
      return {
        ...state,
        reportLoading: true,
        reportSuccess: false,
        reportError: false,
      };
    case "FETCH_USER_REPORTS_SUCCESS":
      return {
        ...state,
        reportLoading: false,
        reportSuccess: true,
        reportError: false,
      };
    case "FETCH_USER_REPORTS_ERROR":
      return {
        ...state,
        reportLoading: false,
        reportSuccess: false,
        reportError: true,
      };
    case "COMBINE_DATA_FETCH_ERROR":
      return {
        ...state,
        loading: false,
        success: false,
        error: "fetch failed",
        companyData: null,
        sectorData: null,
        reportData: null,
        news: null,
        secReportData: null,
        companyName: null,
      };
    case "INITIAL_STATE":
      return initState;
    case "HOME_CLICK":
      return initState;
    default:
      return state;
  }
};

export default CombineReducer;
