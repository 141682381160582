import React  from 'react';

const SentenceBankRow = ({s, OnVerify}) => {
  return (
    <tr>
        <td width="15%">
        <button className="btn btn-small btn-floating green" onClick={()=>{OnVerify("1")}}>
            <i className="material-icons">add</i>
        </button>
        <button style={{marginLeft:10}}  className="btn btn-small btn-floating red" onClick={()=>{OnVerify(s.id, "-1")}}>
            <i className="material-icons">close</i>
        </button>
        <button style={{marginLeft:10}} className="btn btn-small btn-floating gray" onClick={()=>{OnVerify(s.id, "0")}}>
            <i className="material-icons">info</i>
        </button>
        </td>
        <td width="75%">
        <p>
            Sentiment:
            <strong style={{color:s.sentiment==-1?"red":s.sentiment==1?"green":"gray"}}>{
            s.sentiment==-1?" Negative":s.sentiment==1?" Positive":" Neutral"
            }</strong>
        </p>
        <p>{s.sentence}</p>
        </td>
        <td width="10%">
        <button className="btn btn-small btn-floating admin-button" onClick={()=>{OnVerify(s.id, s.sentiment)}}>
            <i className="material-icons">check</i>
        </button>
        </td>
    </tr> 
  );  

}

export default SentenceBankRow;  
