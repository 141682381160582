import React from "react";

export const NLTable = (props) => {
  return (
    <table className="nl-table tableFixed">
      <thead>
        <tr>
          <th>Particular</th>
          <th>Overall</th>
        </tr>
      </thead>
      <tbody style={{ height: "75vh" }}>
        {props.data.map((d) => (
          <tr>
            <td>
              <p title={d.particular} className="nl-table-text">
                {d.particulars}
              </p>
            </td>
            <td>
              <p className="nl-table-number">{d.score}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
