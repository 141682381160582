import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BarStackCombination from "./commonDashboards/BarStackCombination";
import { useSelector } from "react-redux";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";

export default function SegmentClaims({ label, data, type }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [reportData, setReportData] = useState([]);
  const [reportDataTwo, setReportDataTwo] = useState([]);
  const [reportDataClaimsGdp, setReportDataClaimsGdp] = useState([]);

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com =
        !type || type === NL_TYPE_GIC
          ? userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType !== NL_TYPE_LIFE,
            )
          : userCompany?.find(
              (com) =>
                com?.detail?.sector_id === insSector?.id &&
                com?.detail?.insuranceType === NL_TYPE_LIFE,
            );
      let secs = com?.secondaries;
      let coms = [...secs];
      coms.push(com?.detail);
      console.log("HERERERERERE", data, coms);
      setReportData(makeFlatData(data, coms));
      if (type === NL_TYPE_LIFE) {
        setReportDataTwo(getBarChartData(data, coms, "Benefits Paid (Net)"));
        setReportDataClaimsGdp(getBarChartData(data, coms, "claims_gdp"));
      } else {
        setReportDataTwo(getBarChartData(data, coms, "Claims Paid (Direct)"));
      }
    }
  }, [data]);

  const getBarChartData = (data, coms, field) => {
    let newData = [];
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          [field]: d[field],
        });
      }
    });
    newData = newData?.sort((a, b) => (a[field] < b[field] ? 1 : -1));

    console.log("NEW DATA", newData);
    return newData;
  };

  const makeFlatData = (arrayData, coms) => {
    let newData = [];
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      let comFound = coms.find((com) => com.name === d.Company);
      console.log("COM FOUND", comFound);
      for (let j = 0; j < segKeys.length; j++) {
        if (comFound)
          newData.push({
            company: d.Company,
            segment: segKeys[j].replace("(%)", "").trim(),
            data: d[segKeys[j]],
          });
      }
    }
    console.log("NEW FLAT DATA", newData);
    return newData;
  };

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData, reportDataTwo);
  const BAR_CHART_HEIGHT = type === NL_TYPE_LIFE ? 250 : 530;
  const BAR_CHART_WIDTH = type === NL_TYPE_LIFE ? 400 : 400;
  const STACK_CHART_HEIGHT = type === NL_TYPE_LIFE ? 800 : 800;
  const STACK_CHART_WIDTH = type === NL_TYPE_LIFE ? 500 : 450;
  return (
    <BarStackCombination
      barOneDetails={{
        id: "claims_paid",
        height: BAR_CHART_HEIGHT,
        width: BAR_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [
          {
            text:
              type === NL_TYPE_LIFE
                ? "Benefits Paid (Net)"
                : "Claims Paid (Direct)",
          },
        ],
        label:
          type === NL_TYPE_LIFE ? "Benefits Paid (Net)" : "Claims Paid(Direct)",
        data: reportDataTwo,
      }}
      barTwoDetails={
        type == NL_TYPE_LIFE
          ? {
              id: "claims_gdp",
              height: BAR_CHART_HEIGHT,
              width: BAR_CHART_WIDTH,
              dimensions: [{ text: "company" }],
              measures: [{ text: "claims_gdp" }],
              label: "Claims to GDP",
              data: reportDataClaimsGdp,
            }
          : null
      }
      stackBarDetails={{
        id: "seg-rev",
        width: STACK_CHART_HEIGHT,
        height: STACK_CHART_WIDTH,
        dimensions: [{ text: "company" }],
        measures: [{ text: "data" }],
        group: "segment",
        label:
          type === NL_TYPE_LIFE
            ? "Contribution of products on claims"
            : "Contribution of segments",
        data: reportData,
        gic: false,
      }}
      life={type === NL_TYPE_LIFE}
    />
  );
}
