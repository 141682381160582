import React from "react";
import { LoaderOne } from "./loader1";

export const StatItem = ({label, figure, type, loading}) => {
  return (
    <div className="card card-inline" style={{width:300, height:80, padding:10, margin:10, borderRadius:'13px'}}>
      {
        loading?
        <LoaderOne height="20px" width="20px"/>
        :
        <div className="card-content white-text" style={{padding:0}}>
          <p style={{fontSize:20, fontWeight:'bold', color:'black'}}>{label}</p>
          <p
          style={{
            fontSize:28,
            color:type=="positive"?"green":type=="negative"?"red":"blue"
          }}
          >
            {figure}
          </p>
        </div>
      }
    </div>
  );
};
