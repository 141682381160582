import { Box, Button, Modal } from "@mui/material";
import { map } from "lodash";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: 570,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function DeleteNLConfirmationModal({
  companies,
  companyId,
  year,
  quarter,
  nls,
  show,
  onClose,
  confirmDelete,
}) {
  const [open, setOpen] = useState(false);
  const [nlString, setNlString] = useState("");
  const [company, setCompany] = useState(null);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (nls) {
      let text = "";
      for (let i = 0; i < nls.length; i++) {
        text += `${nls[i]["label"]} `;
      }
      setNlString(text);
    }
  }, [nls]);

  useEffect(() => {
    if (nls) {
      let text = "";
      for (let i = 0; i < nls.length; i++) {
        text += `${nls[i]["label"]} `;
      }
      setNlString(text);
    }
  }, [nls]);

  useEffect(() => {
    if (companyId && companies) {
      let loccompany = companies.find((f) => f.id == companyId);
      setCompany(loccompany);
    }
  }, [companyId, companies]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="row">
          <div className="col s2">Company : </div>
          <div className="col s4">{company?.name}</div>
        </div>
        <div className="row">
          <div className="col s2">Year : </div>
          <div className="col s4">{year}</div>
        </div>
        <div className="row">
          <div className="col s2">Quarter : </div>
          <div className="col s4">{quarter}</div>
        </div>
        <div className="row">
          <div className="col s2">NL : </div>
          <div className="col s10">{nlString}</div>
        </div>
        <div className="row">
          <div className="col s6">
            <Button
              className="left"
              variant="contained"
              color="error"
              onClick={confirmDelete}
            >
              Confirm Delete
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
