import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyListForNLReport } from "../../../utils/nlHelper";
import { getNlList } from "../../../store/action/nlActions";
import Select from "react-select";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import DeleteNLConfirmationModal from "./DeleteNLConfirmationModal";
import { deleteNLData, setReducer } from "../../../store/action/nlFixAction";
import swal from "sweetalert";

export default function DeleteNL() {
  const dispatch = useDispatch();

  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [nls, setNls] = useState([]);
  const [selectedNls, setSelectedNls] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState();

  const accessToken = useSelector((state) => state.auth?.accessToken);
  const sectors = useSelector((state) => state.SM.sectorList);
  const companies = useSelector((state) => state.NIS.companyList);
  const nlYearQuarter = useSelector((state) => state.nl.nlYearQuarterAvailable);
  const nlList = useSelector((state) => state.nl.nlList);

  const deleteNLDataLoading = useSelector(
    (state) => state?.nlFix?.deleteNLDataLoading
  );
  const deleteNLDataSuccess = useSelector(
    (state) => state?.nlFix?.deleteNLDataSuccess
  );
  const deleteNLDataError = useSelector(
    (state) => state?.nlFix?.deleteNLDataError
  );
  const deleteNLDataMsg = useSelector((state) => state?.nlFix?.deleteNLDataMsg);

  useEffect(() => {
    let years = [];
    let quarters = [];
    let fys = [];
    let qs = [];
    for (let i = 0; i < nlYearQuarter?.length; i++) {
      if (!fys.includes(nlYearQuarter[i].fyq.split("-")[0])) {
        years.push({
          value: nlYearQuarter[i].fyq.split("-")[0],
          label: nlYearQuarter[i].label.split(",")[0],
        });
      }
      if (!qs.includes(nlYearQuarter[i].fyq.split("-")[1])) {
        quarters.push({
          value: nlYearQuarter[i].fyq.split("-")[1],
          label: nlYearQuarter[i].label.split(",")[1],
        });
      }
      fys.push(nlYearQuarter[i].fyq.split("-")[0]);
      qs.push(nlYearQuarter[i].fyq.split("-")[1]);
    }
    setYears(years);
    setQuarters(quarters);
    if (years?.length > 0 && quarters?.length > 0) {
      setYear(years[years.length - 1].value);
      setQuarter(quarters[quarters.length - 1].value);
    }
    dispatch(getNlList({}, accessToken));
  }, []);

  useEffect(() => {
    let locNlList = [];
    if (nlList?.length > 0) {
      locNlList.push({ value: "all", label: "All" });
      for (let i = 0; i < nlList.length; i++) {
        locNlList.push({ value: nlList[i]["id"], label: nlList[i]["lable"] });
      }
      setNls(locNlList);
    }
  }, [nlList]);

  useEffect(() => {
    if (deleteNLDataSuccess) {
      swal("Success", deleteNLDataMsg, "success");
      dispatch(setReducer({ deleteNLDataSuccess: false }));
    }
    if (deleteNLDataError) {
      swal("Oops!", deleteNLDataMsg, "error");
      dispatch(setReducer({ deleteNLDataError: false }));
    }
  }, [deleteNLDataSuccess, deleteNLDataError]);

  const isInsuranceCompany = (company) => {
    return getCompanyListForNLReport(company, sectors);
  };

  const onChangeNl = (value) => {
    let isAll = value.find((f) => f.value === "all");
    if (isAll) {
      setSelectedNls(
        nls
          .filter((f) => f.value !== "all")
          .map((d) => {
            return d;
          })
      );
    } else {
      setSelectedNls(value);
    }
  };

  const confirmDelete = (e) => {
    e.preventDefault();
    dispatch(
      deleteNLData(
        {
          year: year,
          quarter: quarter,
          companyId: companyId,
          nls: selectedNls?.map((d) => {
            return d.value;
          }),
        },
        accessToken
      )
    );
    setOpenConfirmationModal(false);
  };

  return (
    <div className="row" style={{ padding: 10 }}>
      {deleteNLDataLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <DeleteNLConfirmationModal
        companies={companies}
        companyId={companyId}
        year={year}
        quarter={quarter}
        nls={selectedNls}
        show={openConfirmationModal}
        onClose={(e) => {
          setOpenConfirmationModal(false);
        }}
        confirmDelete={confirmDelete}
      />
      <div className="col s12" style={{ textAlign: "center" }}>
        <fieldset style={{ borderRadius: "7px" }}>
          <legend>Delete NL</legend>
          <div className="row">
            <div className="col s12">
              <label>Select NL</label>
              <Select
                onChange={onChangeNl}
                value={selectedNls}
                options={nls}
                placeholder="Select NL..."
                isMulti={true}
                closeMenuOnSelect={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col s4">
              <label htmlFor="name">Company</label>
              <select
                required
                id="companyId"
                className="browser-default custom-select"
                value={companyId}
                onChange={(e) => {
                  setCompanyId(e.target.value);
                }}
              >
                <option value={""}>Select</option>
                {companies?.filter(isInsuranceCompany)?.map((com, index) => (
                  <option value={com.id} key={index}>
                    {com.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col s4">
              <label htmlFor="name">Year</label>
              <select
                required
                id="year"
                value={year}
                className="browser-default custom-select"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <option value={""}>Select</option>
                {years?.map((year, index) => (
                  <option value={year.value} key={index}>
                    {year.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col s4">
              <label htmlFor="name">Quarter</label>
              <select
                required
                id="quarter"
                value={quarter}
                className="browser-default custom-select"
                onChange={(e) => {
                  setQuarter(e.target.value);
                }}
              >
                <option value={""}>Select</option>
                {quarters?.map((quarter, index) => (
                  <option value={quarter.value} key={index}>
                    {quarter.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col s4">
              <Button
                variant="contained"
                className="left"
                disabled={
                  selectedNls.length === 0 || !companyId || !year || !quarter
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenConfirmationModal(true);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
}
