import React from "react";

export default function News({
  TopNews,
  marginTop,
  height,
  textColor,
  component,
}) {
  return (
    <div>
      <div>
      {component == "my_dashboard" ? (
      <ul style={{ backgroundColor: textColor?textColor: "rgb(93, 27, 143)", marginTop:marginTop?marginTop: "10%" }}>
          <div className="row" style={{ paddingTop: 9 }}>
            <div className="col s2  center-align">
              <p style={{ color:textColor=="white"?"black": "white", fontWeight: 500 }}>Date</p>
            </div>
            <div className="col s8 center-align">
              <p style={{ color: textColor=="white"?"black":"white", fontWeight: 500 }}>Headline</p>
            </div>
            <div className="col s1" style={{ color: "#23431b" }}>
              <p style={{ color: textColor=="white"?"black":"white", fontWeight: 500 }}>Sentiment</p>
            </div>
            <div className="col s1">
              <p style={{ color: textColor=="white"?"black":"white", fontWeight: 500 }}>Score</p>
            </div>
          </div>
      </ul>) : null}
      <div
        className="top-news-ud"
        style={
          component == "my_dashboard"
            ? {
                height: height,
                overflowY: "auto",
                marginTop: -20,
                marginBottom: 10,
              }
            : {
                height: height,
                overflowY: "auto",
                marginTop: marginTop,
                marginBottom: 10,
              }
        }
      >
        <ul className="collection" style={{ backgroundColor: "white" }}>
          {TopNews &&
            TopNews.map((news) => (
              <div className="col s12 top-news-item">
                <div className="col s2 center center-align" st>
                  <p style={{ color: "black" }}>{news.date}</p>
                </div>
                <div className="col s8 left-align">
                  <a
                    href={news.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="collection-item"
                    style={{ color:textColor=="white"?"black": textColor ? textColor : "#5d1b8f" }}
                  >
                    {news.headline}
                  </a>
                </div>
                <div className="col s1" style={{ color: "#23431b" }}>
                  {news.score >= 0 ? (
                    <p>
                      <i className="material-icons">add_circle</i>
                    </p>
                  ) : (
                    <p style={{ color: "red" }}>
                      <i className="material-icons">do_not_disturb_on</i>
                    </p>
                  )}
                </div>
                <div className="col s1">
                  <p>{news.score ? news.score.toFixed(2) : "1"}</p>
                </div>
              </div>
            ))}
        </ul>
      </div>
      </div>
    </div>
  );
}
