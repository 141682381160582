import React from 'react'
import "./style.css";

export default function PolicyChange({id, headings, company, percentage, onSwitch}) {
    // //console.log(data);
    return (
        <div className="col s4" onClick={()=>{onSwitch(id)}} style={{cursor:'pointer'}}>
            <div className="card">
            <div className="card-content">
                <span className="card-title">{company}</span>
                <p><strong>Changed Sections</strong></p>
                <div className="row">
                    <div className="col s8" style={{maxHeight:100, overflowY:'hidden'}}>
                        {
                        headings.map(h=>
                            <span>
                                <span>{h}</span>
                                <span>, </span>
                            </span>
                        )}
                    </div>
                    <div className="col s4">
                        <h2>{percentage}</h2>
                    </div>
                </div>
            </div>
            </div>                    
        </div>
    )
}
