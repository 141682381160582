import React from "react";
import Select from "react-select";

export const DownloadReportModal = ({
  HandleChange,
  company,
  loading,
  error,
}) => {
  return (
    <div>
      <div id="download-report-modal" className="modal small-modal">
        <div className="modal-content">
          <div
            className="modal-title dashboard-modal-title valign-wrapper"
            style={{
              backgroundColor: "#124265",
              minHeight: 10,
              marginBottom: 20,
            }}
          >
            <span className="dashboard-modal-name">
              Download Company Report
            </span>
          </div>
          <div className="row">
            <Select
              onChange={HandleChange}
              options={company}
              placeholder="Select Company..."
            />
          </div>
          <div className="row center center align">
            {loading && (
              <div className="preloader-wrapper active">
                <div className="spinner-layer spinner-blue-only">
                  <div className="circle-clipper left">
                    <div className="circle"></div>
                  </div>
                  <div className="gap-patch">
                    <div className="circle"></div>
                  </div>
                  <div className="circle-clipper right">
                    <div className="circle"></div>
                  </div>
                </div>
              </div>
            )}
            <div className=" row red-text">
              {error ? <p>Report not available</p> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
