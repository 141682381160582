import React, { Component } from "react";
import * as d3 from "d3";
import moment from "moment"

class LineChart extends Component {
  componentDidUpdate() {
    //console.log(this.props);
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
    // //console.log(this.props.dataset)
  }

  drawChart() {
    const {dataset, can_width, can_height, onClick, selected, name, bgcolor,lineColor} = this.props
    
    // Calculate Margins and canvas dimensions
    var margin = {top: 40, right: 40, bottom: 90, left: 60},
        width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    //Parsers and Formaters
    var parseTime = d3.timeParse("%d/%m/%Y");
    // var formatTime = d3.timeFormat("%a/%b/%Y");
    
    // Scales
    var x = d3.scaleTime()
        .range([0, width]);
    
    var y = d3.scaleLinear()
        .range([height, 0]);
    
    // Line
    var line = d3.line()
        .x(function(d) { return x(parseTime(d.date)); })
        .y(function(d) { return y(d.nis); })
    
    //Remove already redered graph otherwise repeat graph will be created
    d3.select("#svg-element")
      .select("svg")
      .remove();
    
    var selectedData = selected?selected: dataset[0]

    var chart = d3.select("#svg-element").append("svg")
        .style("background-color", bgcolor?bgcolor: '#F5F6CE')
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("fill", 'none')
        .attr("line-stroke", 'blue')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
     
     //Arguments for axes : Ranges for X, y  
     x.domain(d3.extent(dataset, function(d) { return parseTime(d.date); }));
     y.domain(d3.extent(dataset, function(d) { return d.nis; }));
    
    // Axes
      chart.append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x).ticks(10))
          .selectAll("text")	
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-65)")
            // .attr("font-weight", "100")
            .style("font-size", "12px")
            .style("fill", "#000")
          .call(d3.axisBottom(x));
    
      chart.append("g")
          .attr("class", "axis axis--y")
          .call(d3.axisLeft(y));

    // Create the circle that travels along the curve of chart
    var focus = chart
    .append('g')
    .append('circle')
      .style("fill", "#01579B")
      .attr("stroke", "black")
      .attr('r', 6)
      .attr("cx", x(parseTime(selectedData.date)))
      .attr("cy", y(selectedData.nis))
      .style("opacity", 0)
    // Labels
    chart.append("text")
    .attr("text-anchor", "middle")
    .style("font-size", "14px")
    .style("stroke", "#000")
    .attr("transform", "translate("+ (margin.left - 94 ) +","+(height/2)+")rotate(-90)")  
    .text(name);

    chart.append("text")
        .style("font-size", "14px")
        .attr("text-anchor", "middle") 
        .style("stroke", "#000")
        .attr("transform", "translate("+ (width/2) +","+(height-(margin.bottom -170))+")")
        .text("Date");

    //  Chart Title
    // chart.append("text")
    //     .attr("x", (width / 2))             
    //     .attr("y", 10 - (margin.top / 2))
    //     .attr("text-anchor", "middle")  
    //     .style("font-size", "16px") 
    //     .style("stroke", "#000")
    //     .text("NIS Graph");

  // Data Lines:

    chart.append("path")
        .datum(dataset)
        .attr("class", "line")
        .attr('stroke', lineColor?lineColor:'#01579B')
        .attr('stroke-width', 4)
        .attr("d", line)
        .enter()
        ;

  //Grid Lines
  // svg.append('g')
  // .attr('class', 'grid')
  // .attr('transform', `translate(0, ${height})`)
  // .call(d3.axisBottom()
  //     .scale(x)
  //     .tickSize(-height, 0, 0)
  //     .tickFormat(''))
    
  // chart.append('g')
  //   .attr('class', 'grid')
  //   .call(d3.axisLeft()
  //   .scale(y)
  //   .tickSize(-width, 0, 0)
  //   .tickFormat(''))

  // Create the text that travels along the curve of chart
  var focusText = chart
    .append('g')
    .append('text')
      .style("opacity", 0)
      .attr("text-anchor", "left")
      .style("font-size", "14px")
      .style("fill", "#000")
      .attr("alignment-baseline", "middle")
      .html("Date:" + selectedData.date + "\nScore:" + selectedData.nis.toFixed(2)+"</p>")
      .attr("x", x(parseTime(selectedData.date))+15)
      .attr("y", y(selectedData.nis)-15)
      .style("opacity", 0)

      // Create a rect on top of the svg area: this rectangle recovers mouse position
    chart
    .append('rect')
    .style("fill", "none")
    .style("pointer-events", "all")
    .attr('width', width)
    .attr('height', height)
    .on('mouseover', mouseover)
    .on('mousemove', mousemove)
    .on('mouseout', mouseout)
    .on('click', mouseclick);

    // What happens when the mouse move -> show the annotations at the right positions.
  function mouseover() {
    focus.style("opacity", 1)
    focusText.style("opacity",1)
  }

  function mousemove() {
    // recover coordinate we need
    var x0 = x.invert(d3.mouse(this)[0]);
    // var i = d3.bisect(dataset, x0, 1);
    // //console.log("X0:",x0);
    for (var j=0;j<dataset.length;j++){
      if (moment(parseTime( dataset[j].date)).date() == moment(x0).date()
      &&moment(parseTime(dataset[j].date)).year() == moment(x0).year()
      && moment(parseTime(dataset[j].date)).month() == moment(x0).month()){
        // //console.log("called",dataset[j],moment(x0).toDate());
        selectedData = dataset[j]
        break
      }
    }
    focus
      .attr("cx", x(parseTime(selectedData.date)))
      .attr("cy", y(selectedData.nis))
    focusText
      .html("Date:" + selectedData.date + "\nScore:" + selectedData.nis.toFixed(2)+"</p>")
      .attr("x", x(parseTime(selectedData.date))+15)
      .attr("y", y(selectedData.nis)-15)
    }
  function mouseout() {
    focus.style("opacity", 0.5)
    focusText.style("opacity", 1)
  }
  function mouseclick() {
    // //console.log("Date", selectedData)
    onClick(selectedData.date, selectedData)
  }

  }

  render() {
    return <div id="svg-element"style={{marginLeft:0}}></div>;
  }
}
export default LineChart;