const initState = { 
    uploadSuccess: false, // make true when auth succdessful
    uploadMessage: '', //change when auth failed
    uploadLoading: false,
    uploadError:'',
    uploadResponse:null,
    yearFetchSuccess: false,
    yearFetchError: false,
    yearData: [],
    submitPDSuccess: false,
    submitPDError:false,
    submitPD:'',
    submitPDLoading: false,
  };
  
  const uploadReducer = (state = initState, action) => {
    switch (action.type) {
      case 'LOADING_UPLOAD':
        return {
          ...state,
          uploadLoading: true,
          uploadSuccess: false,
          uploadMessage: '',
          uploadError:'',
          uploadResponse:null,
        };
  
      case 'UPLOAD_SUCCESSFUL':
        return {
          ...state,
          uploadLoading: false,
          uploadSuccess: true,
          uploadMessage: 'Successfully uploaded',
          uploadError:'',
          uploadResponse:action.data
        };
      case 'UPLOAD_ERROR':
        return {
          ...state,
          uploadLoading: false,
          uploadSuccess: false,
          uploadMessage: '',
          uploadError:action.data.errormessage?action.data.errormessage:'Upload failed',
          uploadResponse:null
        };
      case "YEAR_FETCH_SUCCESSFUL":
        return {
          ...state,
          yearData: action.payload,
          yearFetchLoading: false,
          yearFetchSuccess: true,
          yearFetchError: false,
        };
      case "YEAR_FETCH_LOADING":
        return {
          ...state,
          yearData: [],
          yearFetchLoading: true,
          yearFetchSuccess: false,
          yearFetchError: false,
        };
      case "YEAR_FETCH_ERROR":
        return {
          ...state,
          yearData: [],
          yearFetchLoading: false,
          yearFetchSuccess: false,
          yearFetchError: true,
        };
      case "SUBMIT_PD_SUCCESSFUL" :
        if( action.payload.data['0']&&action.payload.data['0'].pos_sen&&action.payload.data['0'].neg_sen){
        action.payload.data['0'].pos_sen = action.payload.data['0'].pos_sen?action.payload.data['0'].pos_sen.sentences:[]
        action.payload.data['0'].neg_sen = action.payload.data['0'].neg_sen?action.payload.data['0'].neg_sen.sentences:[]}
        return {
          ...state,
          submitPDLoading: false,
          submitPDSuccess: true,
          submitPDError:false,
          submitPD:action.payload
        }
      case "SUBMIT_PD_ERROR" :
        return {
          ...state,
          submitPDLoading: false,
          submitPDSuccess: false,
          submitPDError:true,
          submitPD:action.payload
        }
      case "SUBMIT_PD_LOADING" :
        return {
          ...state,
          submitPDLoading: true,
          submitPDSuccess: false,
          submitPDError:false,
        }
        case 'INITIAL_STATE':
          return initState
        case 'HOME_CLICK':
          return initState
      default:
        return state;
    }
  };
  
  export default uploadReducer;
  