import React from "react";
import "./style.css";

export const NLCard = ({ title, body, height }) => {
  return (
    <div
      className="card nl-dashboard-card"
      style={height ? { height: height } : {}}
    >
      <div className="card-content">
        {title && (
          <div className="card-title">{title ? title : "Card Title"}</div>
        )}
        <div>{body}</div>
      </div>
    </div>
  );
};
