import React, { useEffect, useState } from "react";
import BarChartGeneric from "../../../layout/BarChartGeneric";
import Legends from "../laylout/Legends";
import PieChartLegend from "../../../layout/PieChartLegend";
import * as d3 from "d3";

export default function BarPieCombination({
  barDetails,
  pieDetails,
  myCompany,
  secondaries,
  lengendMaxChar,
  barTwoDetails,
}) {
  const [colorMapCustom, setColorMap] = useState({});

  useEffect(() => {
    if (pieDetails?.data[myCompany?.name])
      handleColorMap(pieDetails?.data[myCompany?.name], pieDetails?.dimensions);
  }, [pieDetails]);

  const handleColorMap = (data, dimensions) => {
    console.log("DATA", data);
    const color = d3
      .scaleOrdinal()
      .domain(data.map((d) => d[dimensions[0].text]))
      .range(
        d3
          .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), data.length)
          .reverse(),
      );
    let colorMap = {};
    data?.map((d) => {
      colorMap[d[dimensions[0].text]] = color(d[dimensions[0].text]);
    });
    console.log("COLOR MAP", colorMap);
    setColorMap(colorMap);
  };

  return (
    <>
      <div className="col s4">
        <div className="col s12" style={{ marginTop: 16 }}>
          <div className="">
            <strong>{barDetails?.label}</strong>
          </div>
          <BarChartGeneric
            id="total-one"
            data={barDetails?.data || []}
            dimensions={barDetails?.dimensions}
            measures={barDetails?.measures}
            height={barDetails?.height}
            width={barDetails?.width}
            hideLegend={false}
            margins={{ left: 90, bottom: 10 }}
            fontSize={12}
            boldFont={true}
            noLabelWrapY={true}
            // noYLabel={true}
            noXLabel={true}
            labelInBar={false}
            // barFontSize={10}
          />
        </div>
        {barTwoDetails && (
          <div className="col s12" style={{ marginTop: 16 }}>
            <div className="">
              <strong>{barTwoDetails?.label}</strong>
            </div>
            <BarChartGeneric
              id="total-two"
              data={barTwoDetails?.data || []}
              dimensions={barTwoDetails?.dimensions}
              measures={barTwoDetails?.measures}
              height={barTwoDetails?.height}
              width={barTwoDetails?.width}
              hideLegend={false}
              margins={{ left: 90, bottom: 10 }}
              fontSize={12}
              boldFont={true}
              noLabelWrapY={true}
              // noYLabel={true}
              noXLabel={true}
              labelInBar={false}
              // barFontSize={10}
            />
          </div>
        )}
      </div>
      <div className="col s8">
        <div style={{ marginTop: 16, marginLeft: 64 }}>
          <strong>{pieDetails?.title}</strong>
        </div>
        <div className="col s4">
          <div className="center" style={{ marginTop: 16 }}>
            <strong>{myCompany?.name}</strong>
          </div>
          <PieChartLegend
            id={"data-primary"}
            data={pieDetails?.data[myCompany?.name] || []}
            dimensions={pieDetails?.dimensions}
            measures={pieDetails?.measures}
            height={1000}
            width={1000}
            noLegend={true}
            // colorMap={specialColorMap}
          />
        </div>
        {secondaries?.map((sec, i) => (
          <div className="col s4" style={{ marginTop: 16 }}>
            <div className="center">
              <strong>{sec?.name}</strong>
            </div>
            <PieChartLegend
              id={"data-" + sec?.id}
              data={pieDetails?.data[sec?.name] || []}
              dimensions={pieDetails?.dimensions}
              measures={pieDetails?.measures}
              height={700}
              width={700}
              noLegend={true}
              colorMap={colorMapCustom}
            />
          </div>
        ))}
        <Legends
          marginTop={50}
          maxChar={lengendMaxChar}
          colorMapCustom={colorMapCustom}
        />
      </div>
    </>
  );
}
