const initState = {
    auditFirmsLoading: false,
    auditFirmsSuccess: false,
    auditFirmsError: false,
    auditFirmsData: [],

    auditPartnersLoading: false,
    auditPartnersSuccess: false,
    auditPartnersError: false,
    auditPartnersData: [],

    auditYearsLoading: false,
    auditYearsSuccess: false,
    auditYearsError: false,
    auditYearsData: [],

    auditDataLoading: false,
    auditDataSuccess: false,
    auditDataError: false,
    auditData: null,
    auditTableData: [],
    auditExtraData:null,
    auditScoreId:null,
    
    auditSameFirmDataLoading: false,
    auditSameFirmDataSuccess: false,
    auditSameFirmDataError: false,
    auditSameFirmData: null,

    auditSamePartnerDataLoading: false,
    auditSamePartnerDataSuccess: false,
    auditSamePartnerDataError: false,
    auditSamePartnerData: null,

    secAuditLoading: false,
    secAuditSuccess: false,
    secAuditError: false,
    secAuditData: [],

    accPolChangeLoading: false,
    accPolChangeSuccess: false,
    accPolChangeError: false,
    accPolChange: null,
  };
  
  const AuditReducer = (state = initState, action) => {
    console.log(action.type);
    switch (action.type) {
      case "AUDIT_FIRMS_FETCH_LOADING":
        return {
          ...state,
          auditFirmsLoading: true,
          auditFirmsSuccess: false,
          auditFirmsError: false,
          auditFirmsData: [],
        };
      case "AUDIT_FIRMS_FETCH_SUCCESS":
        return {
          ...state,
          auditFirmsLoading: false,
          auditFirmsSuccess: true,
          auditFirmsError: false,
          auditFirmsData: action.payload,
        };
      case "AUDIT_FIRMS_FETCH_ERROR":
        return {
          ...state,
          auditFirmsLoading: false,
          auditFirmsSuccess: false,
          auditFirmsError: true,
          auditFirmsData: [],
        };

      case "AUDIT_PARTNERS_FETCH_LOADING":
        return {
          ...state,
          auditPartnersLoading: true,
          auditPartnersSuccess: false,
          auditPartnersError: false,
          auditPartnersData: [],
        };
      case "AUDIT_PARTNERS_FETCH_SUCCESS":
        return {
          ...state,
          auditPartnersLoading: false,
          auditPartnersSuccess: true,
          auditPartnersError: false,
          auditPartnersData: action.payload,
        };
      case "AUDIT_PARTNERS_FETCH_ERROR":
        return {
          ...state,
          auditPartnersLoading: false,
          auditPartnersSuccess: false,
          auditPartnersError: true,
          auditPartnersData: [],
        };

      case "AUDIT_YEARS_FETCH_LOADING":
        return {
          ...state,
          auditYearsLoading: true,
          auditYearsSuccess: false,
          auditYearsError: false,
          auditYearsData: [],
        };
      case "AUDIT_YEARS_FETCH_SUCCESS":
        return {
          ...state,
          auditYearsLoading: false,
          auditYearsSuccess: true,
          auditYearsError: false,
          auditYearsData: action.payload,
        };
      case "AUDIT_YEARS_FETCH_ERROR":
        return {
          ...state,
          auditYearsLoading: false,
          auditYearsSuccess: false,
          auditYearsError: true,
          auditYearsData: [],
        };

      case "AUDIT_DATA_FETCH_LOADING":
        return {
          ...state,
          auditDataLoading: true,
          auditDataSuccess: false,
          auditDataError: false,
          auditData: null,
        };
      case "AUDIT_DATA_FETCH_SUCCESS":
        if (action.payload.extra && action.payload.extra.type == "company")
          return {
            ...state,
            auditDataLoading: false,
            auditDataSuccess: true,
            auditDataError: false,
            auditData: action.payload.data,
            auditExtraData: action.payload.extra,
            auditScoreId: action.payload.single
              ? action.payload.data[0].id
              : action.payload.extraId,
          };
        else
          return {
            ...state,
            auditDataLoading: false,
            auditDataSuccess: true,
            auditDataError: false,
            auditTableData: action.payload.data,
            auditExtraData: action.payload.extra,
            auditScoreId: action.payload.single
              ? action.payload.data[0].id
              : action.payload.extraId,
          };
      case "AUDIT_DATA_FETCH_ERROR":
        return {
          ...state,
          auditDataLoading: false,
          auditDataSuccess: false,
          auditDataError: true,
          auditData: null,
        };

      case "AUDIT_SAME_FIRM_FETCH_LOADING":
        return {
          ...state,
          auditSameFirmDataLoading: true,
          auditSameFirmDataSuccess: false,
          auditSameFirmDataError: false,
          auditSameFirmData: null,
        };
      case "AUDIT_SAME_FIRM_FETCH_SUCCESS":
        return {
          ...state,
          auditSameFirmDataLoading: false,
          auditSameFirmDataSuccess: true,
          auditSameFirmDataError: false,
          auditSameFirmData: action.payload,
        };
      case "AUDIT_SAME_FIRM_FETCH_ERROR":
        return {
          ...state,
          auditSameFirmDataLoading: false,
          auditSameFirmDataSuccess: false,
          auditSameFirmDataError: true,
          auditSameFirmData: null,
        };

      case "AUDIT_SAME_PARTNER_FETCH_LOADING":
        return {
          ...state,
          auditSamePartnerDataLoading: true,
          auditSamePartnerDataSuccess: false,
          auditSamePartnerDataError: false,
          auditSamePartnerData: null,
        };
      case "AUDIT_SAME_PARTNER_FETCH_SUCCESS":
        return {
          ...state,
          auditSamePartnerDataLoading: false,
          auditSamePartnerDataSuccess: true,
          auditSamePartnerDataError: false,
          auditSamePartnerData: action.payload,
        };
      case "AUDIT_SAME_PARTNER_FETCH_ERROR":
        return {
          ...state,
          auditSamePartnerDataLoading: false,
          auditSamePartnerDataSuccess: false,
          auditSamePartnerDataError: true,
          auditSamePartnerData: null,
        };

      case "SEC_AUDIT_DATA_FETCH_LOADING":
        return {
          ...state,
          secAuditLoading: true,
          secAuditSuccess: false,
          secAuditError: false,
          secAuditData: [],
        };
      case "SEC_AUDIT_DATA_FETCH_SUCCESS":
        return {
          ...state,
          secAuditLoading: false,
          secAuditSuccess: true,
          secAuditError: false,
          secAuditData: action.payload,
        };
      case "SEC_AUDIT_DATA_FETCH_ERROR":
        return {
          ...state,
          secAuditLoading: false,
          secAuditSuccess: false,
          secAuditError: true,
          secAuditData: [],
        };

      case "SWITCH_AUDIT":
        var i = state.secAuditData.findIndex((x) => x.id == action.payload);
        var x = state.auditData[0];
        var y = state.secAuditData;

        var t = state.secAuditData[i];
        y[i] = x;
        x = t;

        return {
          ...state,
          secAuditData: y,
          auditData: [x],
          auditScoreId: action.payload,
        };
      case "ACC_POLICY_CHANGE_FETCH_LOADING":
        return {
          ...state,
          accPolChangeLoading: true,
          accPolChangeSuccess: false,
          accPolChangeError: false,
          accPolChange: null,
        };
      case "ACC_POLICY_CHANGE_FETCH_SUCCESS":
        return {
          ...state,
          accPolChangeLoading: false,
          accPolChangeSuccess: true,
          accPolChangeError: false,
          accPolChange: action.payload,
        };
      case "ACC_POLICY_CHANGE_FETCH_ERROR":
        return {
          ...state,
          accPolChangeLoading: false,
          accPolChangeSuccess: false,
          accPolChangeError: true,
          accPolChange: null,
        };
      case "SET_AUDIT_REDUCER":
        return { ...state, ...action.data };
      case "INITIAL_STATE":
        return initState;
      case "HOME_CLICK":
        return initState;
      default:
        return state;
    }
  };
  
  export default AuditReducer;
  