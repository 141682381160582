const initState = { 
    announcementloading: '',
    announcementSuccess: '',
    announcementError: '',
    data: [],
    pieChartSuccess:'',
    pieChartError:'',
    chartData:[],
    initData: null,
  };

  const announcementReducer = (state = initState, action) => {
      switch (action.type) {
        case "LOADING_ANNOUNCEMENT" : 
            return {
                ...state,
                announcementloading: true,
                announcementSuccess: false,
                announcementError: false,
            };
        
        case "ANNOUNCEMENT_FETCH_SUCCESS" : 
            return {
                ...state,
                announcementloading: '',
                announcementSuccess: true,
                NewsAnnouncementSuccess: true,
                announcementError: '',
                data: action.payload,
                initData:action.payload.body
            };
        
        case "ANNOUNCEMENT_FETCH_ERROR" :
            return {
                ...state,
                announcementloading: '',
                announcementSuccess: '',
                announcementError: true,
                data: []
            };
        case "PIE_CHART_SUCCESS" : 
            return {
                ...state,
                pieChartSuccess:true,
                pieChartError:false,
                chartData: action.payload
            };
        case "PIE_CHART_ERROR" : 
        return{
            ...state,
            pieChartSuccess:false,
            pieChartError:true,
        }
        case 'INITIAL_STATE':
            return initState
            case 'HOME_CLICK':
            return initState;
        default:
            return state;
      }
  };

  export default announcementReducer