import React, { Component } from "react";
import M from "materialize-css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import DashboardModal from "../layout/DashboardModal";
import {
  uploadFile,
  selectYear,
  submitPD,
} from "../../store/action/uploadAction";
import {
  addCompany,
  getAuditCom,
  getCompanyList,
  getNews,
} from "../../store/action/nisActions";
import { getNewsNIS } from "../../store/action/nisActions";
import { getNewsSM, getSectorList } from "../../store/action/smActions";
import { getConfig, getSettingsMeta } from "../../store/action/authActions";
import { onHome, setDefaultCompany } from "../../store/action/homeAction";
import { getAnnouncement } from "../../store/action/announceAction";
import {
  getBroker,
  getSecCompanies,
  getStockPulse,
  addSecCompanies,
  getStockTweetReport,
} from "../../store/action/stockPulseAction";
import moment from "moment";
import {
  updateConcallCompany,
  getConcallSecCompanies,
  getConcallScores,
  getConcallScoreFile,
} from "../../store/action/concallAction";
import {
  getAuditFirms,
  getAuditPartners,
  getAuditYears,
  getSecondaryComAudit,
  submitAudit,
  getAccPolChange,
  auditOnAlrts,
  auditFirmContinuation,
  getAccPolChangeList,
} from "../../store/action/auditAction";
import { ToolCard } from "../layout/ToolCard";
import { AddCompanyModal } from "../layout/AddCompanyModal";
import { uploadOwnAR } from "../../store/action/adminActions";
import { DownloadReportModal } from "../layout/DownloadReport";
import { getUserReport } from "../../store/action/combineDataActions";
import {
  downloadNLReport,
  getAvailableNlYearquarter,
  getNLSegments,
  getNLStatYears,
  getNLTemplates,
  getNlData,
} from "../../store/action/nlActions";
import { getCompanyListForNLReport } from "../../utils/nlHelper";
import { allTools } from "../../config/constants";

function titleCase(str) {
  return str
    .split(" ")
    .map((word) =>
      word.toLowerCase() != "and"
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word,
    )
    .join(" ");
}

class Dashboard extends Component {
  formatDate = (date) => {
    var date = date.split(" ");
    var date1 = [date[2], date[3]].join(", ");
    return [date[1], date1].join(" ");
  };
  addMonths = (date, months) => {
    date.setMonth(date.getMonth() + months);
    return date;
  };
  state = {
    selectedFile: null,

    frequency: "monthly",
    companyId: null,
    startDate: this.formatDate(
      this.addMonths(new Date(Date.now()), -6).toString(),
    ),
    endDate: this.formatDate(new Date(Date.now()).toString()),
    relevanceThreshold: 0.8,
    noveltyThreshold: 0.4,
    nis: false,

    smFrequency: "monthly",
    sector: null,
    smStartDate: null,
    smEndDate: null,
    smRelevanceThreshold: 0.8,
    smNoveltyThreshold: 0.4,
    sm: false,

    convertStartDate: "",
    modalChoose: null,

    company: [],
    allSector: [],
    broker: [],
    selectedOptions: null,
    companyRefreshed: false,
    selectedSecOptions: null,
    secSelected: false,
    concallFile: null,

    criteria: "company",
    audit: null,
    audit_year: null,
    com_year: null,
    file: null,
    submitFlag: false,

    keywords: null,
    uploadingAr: null,
    userAr: [
      { year: "", file: null },
      { year: "", file: null },
    ],
    addCompanyFlag: false,
    newCompany: null,
    accPolSearch: "company",
    noOfAuditChange: 0,
    selectedAuditPartner: null,
    selectedAuditFirm: null,
    year: null,
    nlTemplate: null,
    allNlCompany: true,
    yearQuarterSet: false,
  };

  componentDidMount = () => {
    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, {});
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false });
    var elems = document.querySelectorAll(".datepicker");
    var instances = M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      autoClose: true,
      onClose: () => {
        this.onDateClose();
      },
    });
    var elem_ac = document.querySelectorAll(".autocomplete");
    var elems_select = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems_select, {});
    const { profile } = this.props;

    this.props.HomeReached();

    if (this.props.auth && this.props.auth.accessToken) {
      this.props.GetCompanyList(profile.accessToken);
      this.props.GetSectorList(profile.accessToken);
      this.props.GetConfig(profile.accessToken);
      this.props.GetBroker(profile.accessToken);
      this.props.GetAuditFirms(profile.accessToken);
      this.props.GetAuditPartners(profile.accessToken);
      this.props.GetAvilableNLYearQuarter(profile.accessToken);
      this.props.GetNLTemplates(profile.accessToken);
      this.props.GetNLSegments(profile.accessToken);
      this.props.GetNLStatYears(profile.accessToken);
      this.props.GetSettingsMeta({ type: "frontend" }, profile.accessToken);
      // this.selectOption();
      // M.Autocomplete.init(elem_ac, {data:this.props.NIS&&this.props.NIS.companyList?this.props.NIS.companyList:[]});
    }

    if (this.props.auth && this.props.rolesAllowed) {
    }

    if (this.props.defaultData) {
      // console.log("##########################update", this.props.defaultData);
      this.setState({ ...this.props.defaultData });
    }
    // //console.log(this.props)
  };

  componentDidUpdate = () => {
    if (
      this.props.NIS.companyList &&
      this.props.NIS.companyList.length > 0 &&
      this.props.SM.sectorList &&
      this.props.SM.sectorList.length > 0 &&
      !this.state.companyRefreshed
    ) {
      this.selectOption();
    }
    if (this.props.secSuccess && !this.state.secSelected) {
      var companyList = [];
      var seCom = null;
      for (var i = 0; i < this.props.secCompanies.length; i++) {
        seCom = this.state.company.find(
          (com) => this.props.secCompanies[i].secondary_company_id == com.value,
        );
        companyList.push(seCom);
      }
      // //console.log("secom", seCom);
      this.setState({ selectedSecOptions: companyList, secSelected: true });
    }
    if (this.props.secConcallSuccess && !this.state.secSelected) {
      var companyList = [];
      var seCom = null;
      for (var i = 0; i < this.props.secConcallCompanies.length; i++) {
        seCom = this.state.company.find(
          (com) =>
            this.props.secConcallCompanies[i].secondary_company_id == com.value,
        );
        companyList.push(seCom);
      }
      // //console.log("secom", seCom);
      this.setState({ selectedSecOptions: companyList, secSelected: true });
    }

    if (this.props.customArSuccess && this.state.uploadingAr) {
      var ars = this.state.userAr;
      ars[this.state.uploadingAr]["filePath"] = this.props.customAr;
      this.setState({ uploadingAr: null, userAr: ars });
    }
    if (this.props.customArError && this.state.uploadingAr) {
      this.setState({ uploadingAr: null });
    }
    if (this.props.addCompanySuccess && this.state.addCompanyFlag) {
      this.setState({
        userAr: [{ year: "", file: null }],
        addCompanyFlag: false,
      });
    }

    if (
      this.props.yearDataSuccess &&
      !this.state.year &&
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.length > 0
    ) {
      this.setState({
        year: this.props.yearData.years[this.props.yearData.years.length - 1],
      });
    }
    if (
      this.props.audit.auditYearsSuccess &&
      !this.state.audit_year &&
      this.props.audit.auditYearsData &&
      this.props.audit.auditYearsData.length > 0
    ) {
      this.setState({
        audit_year:
          this.props.audit.auditYearsData[
            this.props.audit.auditYearsData.length - 1
          ].year,
      });
    }
    if (this.state.nlDownloadFlag && this.props.downloadNLReportError) {
      this.setState({ nlDownloadFlag: false });
      alert(this.props.downloadNLReportMsg);
    }

    if (
      this.props.nlYearQuarterAvailable &&
      this.props.nlYearQuarterAvailable.length > 0 &&
      !this.state.yearQuarterSet
    ) {
      this.setState({
        yearQuarterSet: true,
        "nl-id":
          this.props.nlYearQuarterAvailable[
            this.props.nlYearQuarterAvailable.length - 1
          ]?.fyq,
      });
    }
  };

  selectOption = () => {
    var company = [];
    var sector = [];
    var broker = [];
    var NIS = this.props.NIS.companyList;
    var SM = this.props.SM.sectorList;
    var Broker = this.props.stockPulse.broker.data;
    // //console.log(NIS,SM,Broker)
    if (NIS) {
      for (var i = 0; i < NIS.length; i++) {
        company.push({
          value: NIS[i].id,
          label: NIS[i].name,
          sector_id: NIS[i].sector_id,
          insuranceType: NIS[i].insuranceType,
        });
        // this.setState({company:company})
      }
      // //console.log(company)
    }
    if (SM) {
      for (var i = 0; i < SM.length; i++) {
        sector.push({
          value: SM[i].id,
          label:
            SM[i].name == "it & ites" || SM[i].name == "fmcg"
              ? SM[i].name.toUpperCase()
              : titleCase(SM[i].name),
        });
        // this.setState({sector:sector})
      }
      // //console.log(sector)
    }
    if (Broker) {
      for (var i = 0; i < Broker.length; i++) {
        broker.push({
          value: Broker[i].id,
          label: Broker[i].name,
        });
        // this.setState({broker:broker})
      }
      // //console.log(broker)
    }
    this.setState({
      company: company,
      allSector: sector,
      broker: broker,
      companyRefreshed: true,
    });
  };

  HandleChange = (selectedOptions) => {
    if (selectedOptions) {
      // //console.log(selectedOptions)
      // //console.log(selectedOptions.value)
      if (this.state.modalChoose == "concall")
        this.props.GetConcallSecCompanies(
          selectedOptions.value,
          this.props.auth.accessToken,
        );
      else
        this.props.GetSecCompanies(
          selectedOptions.value,
          this.props.auth.accessToken,
        );
      this.setState({
        selectedOptions: selectedOptions,
        secSelected: false,
      });
    }
    if (this.state.modalChoose == "distress predictor")
      this.props.SelectYear(selectedOptions.value, this.props.auth.accessToken);
    if (
      this.state.modalChoose == "audit-monitor" ||
      this.state.modalChoose == "acc-policy" ||
      this.state.modalChoose == "broad-finance" ||
      this.state.modalChoose == "audit-matter"
    )
      this.props.GetAuditYears(
        selectedOptions.value,
        this.props.auth.accessToken,
      );
  };
  HandleMulChange = (selectedOptions) => {
    if (selectedOptions)
      // //console.log(selectedOptions)
      // //console.log(selectedOptions.value)
      this.setState({
        selectedSecOptions: selectedOptions,
      });
  };

  onChangeHandler = (event) => {
    if (event.target.name == "accPolSearch") {
      this.setState({ accPolSearch: event.target.value });
    } else
      this.setState({
        [event.target.id]: event.target.value,
      });
  };

  OnAuditPartnerChange = (selectedAuditPartner) => {
    this.setState({ selectedAuditPartner });
  };

  OnAuditorChange = (selectedAuditFirm) => {
    this.setState({ selectedAuditFirm });
  };
  OnAudtPartnerChange = (selectedAuditPartner) => {
    this.setState({ selectedAuditPartner });
  };

  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
      // smStartDate: document.getElementById("startDate").value,
      // smEndDate: document.getElementById("endDate").value,
    });
    // //console.log(this.state)
  };

  onFileChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  onClickHandler = (e) => {
    e.preventDefault();
    const { profile } = this.props;
    // //console.log(this.state.year);
    if (!this.state.year && !this.state.selectedFile) {
      alert("please select a file to upload or choose from existing");
    } else {
      if (!this.state.year) {
        if (this.state.selectedFile && profile.PDExpiry !== 0) {
          this.props.uploadFile(this.state.selectedFile, profile.accessToken);
        } else if (this.state.selectedFile && profile.PDExpiry === 0) {
          alert("uploaded file limit has been exceed");
        } else {
          alert("please select a file to upload");
        }
      } else {
        this.props.SetDefaultCompany({
          selectedOptions: this.state.selectedOptions,
        });
        // //console.log(this.state.selectedOptions.value, this.state.year);
        this.props.SubmitPD(
          this.state.selectedOptions.value,
          this.state.year,
          profile.accessToken,
        );
      }
    }
  };

  onNISSubmit = (e) => {
    e.preventDefault();
    if (
      !this.state.selectedOptions ||
      !this.state.startDate ||
      !this.state.endDate
    ) {
      alert("Please fill the the fields...");
    } else {
      var data = {
        company: this.state.selectedOptions.value,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        frequency: this.state.frequency,
        relevanceThreshold: this.state.relevanceThreshold,
        noveltyThreshold: this.state.noveltyThreshold,
      };
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.GetNewsNIS(data, this.props.auth.accessToken);
    }
  };

  onSMSubmit = (e) => {
    e.preventDefault();
    if (
      !this.state.selectedOptions ||
      !this.state.startDate ||
      !this.state.endDate
    ) {
      alert("Please fill the fields...");
    } else {
      var data = {
        sector: this.state.selectedOptions.value,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        frequency: this.state.smFrequency,
        relevanceThreshold: this.state.smRelevanceThreshold,
        noveltyThreshold: this.state.smNoveltyThreshold,
      };
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.GetNewsSM(data, this.props.auth.accessToken);
    }
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  onAnnounceSubmit = (e) => {
    e.preventDefault();
    if (
      !this.state.selectedOptions ||
      !this.state.startDate ||
      !this.state.endDate
    ) {
      alert("Please fill the fields...");
    } else {
      var data = {
        companyId: this.state.selectedOptions.value,
        // startDate: this.formatDate(this.state.startDate),
        startDate: this.state.startDate,
        // endDate: this.formatDate(this.state.endDate),
        endDate: this.state.endDate,
        topic: null,
      };
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.GetAnnouncement(data, this.props.auth.accessToken);
    }
  };

  onStockSubmit = (e) => {
    e.preventDefault();
    if (
      !this.state.stock_type ||
      !this.state.startDate ||
      !this.state.endDate
    ) {
      alert("Please fill the fields...");
    } else {
      var data = {
        // startDate: this.formatDate(this.state.startDate),
        startDate: this.state.startDate,
        // endDate: this.formatDate(this.state.endDate),
        endDate: this.state.endDate,
        novelty: 0.5,
        authScore: 0.5,
        type: this.state.stock_type,
        id:
          this.state.stock_type == "company"
            ? Number(this.state.selectedOptions.value)
            : this.state.stock_type == "sector"
              ? Number(this.state.selectedOptions.value)
              : this.state.stock_type == "broker"
                ? Number(this.state.selectedOptions.value)
                : null,
        brokerId: null,
        secondary_company_id:
          this.state.stock_type == "company" && this.state.selectedSecOptions
            ? this.state.selectedSecOptions.map((v) => {
                return v.value;
              })
            : [],
      };
      // //console.log("Successfully submited", data);
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.GetStockPulse(data, this.props.auth.accessToken);
      this.props.GetStockTweet(data, this.props.auth.accessToken);
      if (this.state.stock_type == "company" && this.state.selectedSecOptions) {
        var data2 = {
          primary_company_id: data.id,
          secondary_company_id: data.secondary_company_id,
        };
        this.props.AddSecCompanies(data2, this.props.auth.accessToken);
      }
    }
  };

  ConcallSubmit = (e) => {
    e.preventDefault();
    if (this.state.concallFile == null) {
      var secs = this.state.selectedSecOptions
        ? this.state.selectedSecOptions.map((v) => {
            return v.value;
          })
        : [];
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.UpdateConcallCompany(
        this.state.selectedOptions.value,
        secs,
        this.props.auth.accessToken,
      );
      this.props.GetConcallScores(
        this.state.selectedOptions.value,
        secs,
        this.props.auth.accessToken,
      );
    } else {
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.GetConcallScoreFile(
        this.state.concallFile,
        this.props.auth.accessToken,
      );
    }
  };

  checkExpiry = (d) => {
    var today = moment(Date.now()).startOf("day");
    var dueDate = moment(Date(d)).startOf("day");

    var days = moment(dueDate, "DD/MM/YYYY").diff(today, "days");
    // //console.log("HERE", days, dueDate.toDate(),d);
    if (days < 0) {
      return true;
    } else {
      return false;
    }
  };

  ModalClick = (id) => {
    if (id === "distress predictor" && this.state.selectedOptions)
      this.props.SelectYear(
        this.state.selectedOptions.value,
        this.props.auth.accessToken,
      );
    if (
      (id === "audit-monitor" ||
        id === "acc-policy" ||
        id === "broad-finance" ||
        id === "audit-matter") &&
      this.state.selectedOptions
    )
      this.props.GetAuditYears(
        this.state.selectedOptions.value,
        this.props.auth.accessToken,
      );

    let temp = null;
    let yarQua = null;
    if (id === "nl-download") {
      temp = this.props.nlTemplates.find(
        (tem) => tem.value === "all" && tem.companyType === "gic",
      );
    } else if (id === "nl-download-life") {
      temp = this.props.nlTemplates.find(
        (tem) => tem.value === "all" && tem.companyType === "life",
      );
      yarQua = this.props.nlYearQuarterAvailable.filter(
        (tem) => tem.type === "life",
      );
      console.log("Yq", yarQua, this.props.nlYearQuarterAvailable);
    }
    console.log("Yq", yarQua);
    this.setState({
      modalChoose: id,
      nlTemplate: temp?.id,
      "nl-id": yarQua && yarQua[0] ? yarQua[0]?.fyq : "",
    });
  };

  ConcallFileChanger = (e) => {
    this.setState({
      concallFile: e.target.files[0],
    });
  };

  auditChange = (e) => {
    if (e.target.id == "file") {
      this.setState({ [e.target.id]: e.target.files[0] });
    } else if (e.target.type == "radio") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  auditSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.criteria == "company" ||
      this.state.modalChoose == "acc-policy" ||
      this.state.modalChoose == "broad-finance" ||
      this.state.modalChoose == "audit-matter"
    ) {
      if (this.state.selectedOptions && this.state.audit_year) {
        this.props.SetDefaultCompany({
          selectedOptions: this.state.selectedOptions,
        });
        this.props.SubmitAudit(
          this.state.selectedOptions.value,
          this.state.audit_year,
          "company",
          this.state.selectedOptions,
          this.props.auth.accessToken,
        );
        var start_date = moment().subtract(12, "months").format("MM-DD-yyyy");
        var end_date = moment().format("MM-DD-yyyy");
        this.props.GetNews(
          start_date,
          end_date,
          this.state.selectedOptions.value,
          this.props.auth.accessToken,
        );
        this.setState({ submitFlag: true });
      } else if (this.state.file) {
        this.setState({ submitFlag: true });
      } else {
        alert("Either Select company and year or upload file");
      }
    } else if (this.state.criteria == "auditor") {
      if (this.state.selectedAuditFirm && this.state.audit_year) {
        var a = this.props.audit.auditFirmsData.find(
          (af) => af.id == this.state.selectedAuditFirm.value,
        );
        this.props.SubmitAudit(
          this.state.selectedAuditFirm.value,
          this.state.audit_year,
          "auditor",
          a,
          this.props.auth.accessToken,
        );
        this.setState({ submitFlag: true });
      } else {
        alert("Select company and year both");
      }
    } else if (this.state.criteria == "continuation") {
      if (this.state.audit_year && this.state.noOfAuditChange) {
        this.props.SubmitAudit(
          this.state.noOfAuditChange,
          this.state.audit_year,
          "continuation",
          { noOfYear: this.state.noOfAuditChange },
          this.props.auth.accessToken,
        );
        this.setState({ submitFlag: true });
      } else {
        alert("Select company and year both");
      }
    } else if (this.state.criteria == "alerts") {
      if (this.state.noOfAuditChange && this.state.audit_year) {
        this.props.SubmitAudit(
          this.state.noOfAuditChange,
          this.state.audit_year,
          "alerts",
          { noOfAlerts: this.state.noOfAuditChange },
          this.props.auth.accessToken,
        );
        this.setState({ submitFlag: true });
      } else {
        alert("Select all the fields");
      }
    } else {
      if (this.state.selectedAuditPartner && this.state.audit_year) {
        var a = this.props.audit.auditPartnersData.find(
          (af) => af.id == this.state.selectedAuditPartner.value,
        );
        this.props.SubmitAudit(
          this.state.selectedAuditPartner.value,
          this.state.audit_year,
          "partner",
          a,
          this.props.auth.accessToken,
        );
        this.setState({ submitFlag: true });
      } else {
        alert("Select company and year both");
      }
    }

    if (this.state.modalChoose == "broad-finance") {
      var secIds = this.state.selectedSecOptions.map((so) => {
        return so.value;
      });
      this.props.GetSecondaryComAudit(
        secIds,
        this.state.audit_year,
        this.props.auth.accessToken,
      );
    }
  };

  accPolSubmit = (e) => {
    e.preventDefault();
    var cId = this.state.selectedOptions.value;
    var secIds = this.state.selectedSecOptions.map((so) => {
      return so.value;
    });

    if (cId && secIds.length < 5 && this.state.accPolSearch == "company") {
      this.props.SetDefaultCompany({
        selectedOptions: this.state.selectedOptions,
      });
      this.props.SubmitAudit(
        this.state.selectedOptions.value,
        this.state.audit_year,
        "company",
        this.state.selectedOptions,
        this.props.auth.accessToken,
      );
      this.props.GetAccPolChange(
        cId,
        secIds,
        this.state.audit_year,
        this.props.auth.accessToken,
      );
    } else if (
      this.state.accPolSearch == "change" &&
      this.state.noOfAuditChange != null
    ) {
      this.props.GetAccPolList(
        this.state.audit_year,
        this.state.noOfAuditChange,
        this.props.auth.accessToken,
      );
    } else {
      alert("PLease select company, year and max 5 secondary companies");
    }
  };

  addCompany = (e) => {
    e.preventDefault();
    var data = {
      sectorId: this.state.sectorId,
      company: this.state.newCompany,
      keywords: this.state.synonyms,
      annualReports: this.state.userAr
        .filter((a) => a.year && a.filePath)
        .map((a) => {
          return { year: a.year, filePath: a.filePath };
        }),
    };
    this.setState({ addCompanyFlag: true });
    this.props.AddCompany(data, this.props.auth.accessToken);
  };

  FileChangeHandler = (e) => {
    var ars = this.state.userAr;
    if (e.target.type == "file")
      ars[e.target.id.split("-")[1]]["file"] = e.target.files;
    else ars[e.target.id.split("-")[1]]["year"] = e.target.value;

    this.setState({ userAr: ars });
  };

  ChangeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  addAr = (e) => {
    var ars = this.state.userAr;
    ars.push({ year: "", file: null });
    this.setState({ userAr: ars });
  };

  removeAr = (e) => {
    var ars = this.state.userAr;
    var id = e.target.id.split("-")[1];
    ars = ars.filter((a, i) => i != id);
    this.setState({ userAr: ars });
  };

  upload = (e) => {
    var i = e.target.id.split("-")[1];
    var file = this.state.userAr[i].file;
    if (!file) {
      alert("Select a file.");
    } else {
      this.setState({ uploadingAr: i });
      this.props.uploadFile(file[0], this.props.auth.accessToken);
    }
  };

  DownloadReport = (selectedOption) => {
    this.props.GetReport(
      selectedOption.value,
      selectedOption.label,
      this.props.auth.accessToken,
    );
  };

  onNLDownload = (e) => {
    e.preventDefault();
    if (!this.state.allNlCompany && !this.state.selectedOptions) {
      alert("Select company or all checkbox");
    } else if (!this.state["nl-id"]) {
      alert("Choose a year and quarter");
    } else {
      var tem = this.props.nlTemplates.find(
        (nl) => nl.id == this.state.nlTemplate,
      );
      var nlName = tem?.value === "all" ? "all-nl-" : "major-nl-";
      nlName +=
        this.props?.nlYearQuarterAvailable?.find(
          (f) => f.fyq === this.state["nl-id"],
        )?.label + ".xlsx";
      nlName = nlName.replace(", ", "-");

      this.props.DownloadNLReport(
        null,
        {
          company_id_list: !this.state.allNlCompany
            ? [this.state.selectedOptions?.value]
            : this.state.company
                .filter((f) =>
                  getCompanyListForNLReport(f, this.state.allSector),
                )
                .map((d, i) => {
                  return d.value;
                }),
          year: this.state["nl-id"].split("-")[0],
          quarter: this.state["nl-id"].split("-")[1],
          template: this.state.nlTemplate,
          filename: nlName,
          downloadAcive: true,
        },
        this.props.profile.accessToken,
      );
      this.setState({ nlDownloadFlag: true });
    }
  };

  onNLDownloadLife = (e) => {
    e.preventDefault();
    if (!this.state.allNlCompany && !this.state.selectedOptions) {
      alert("Select company or all checkbox");
    } else if (!this.state["nl-id"]) {
      alert("Choose a year and quarter");
    } else {
      var tem = this.props.nlTemplates.find(
        (nl) => nl.id == this.state.nlTemplate,
      );
      var nlName = tem?.value === "all" ? "all-nl-life-" : "major-nl-life-";
      nlName +=
        this.props?.nlYearQuarterAvailable?.find(
          (f) => f.fyq === this.state["nl-id"],
        )?.label + ".xlsx";
      nlName = nlName.replace(", ", "-");

      this.props.DownloadNLReportLife(
        null,
        {
          company_id_list: !this.state.allNlCompany
            ? [this.state.selectedOptions?.value]
            : this.state.company
                .filter((f) =>
                  getCompanyListForNLReport(f, this.state.allSector, "life"),
                )
                .map((d, i) => {
                  return d.value;
                }),
          year: this.state["nl-id"].split("-")[0],
          quarter: this.state["nl-id"].split("-")[1],
          template: this.state.nlTemplate,
          filename: nlName,
          downloadAcive: true,
        },
        this.props.profile.accessToken,
      );
      this.setState({ nlDownloadFlag: true });
    }
  };

  onNLDashboard = (e) => {
    e.preventDefault();
    if (!this.state.selectedOptions) {
      alert("Select atleast one company");
    } else if (!this.state["nl-id"]) {
      alert("Choose a year and quarter");
    } else {
      var nlName = this.state.nlTemplate === "magma" ? "all-nl-" : "major-nl-";
      nlName +=
        this.props?.nlYearQuarterAvailable?.find(
          (f) => f.fyq === this.state["nl-id"],
        )?.label + ".xlsx";
      nlName = nlName.replace(", ", "-");

      this.props.GetNlData(
        {
          company_id: this.state.selectedOptions?.value,
          year: this.state["nl-id"].split("-")[0],
          quarter: this.state["nl-id"].split("-")[1],
          filename: nlName,
        },
        this.props.profile.accessToken,
      );
      this.setState({ nlDownloadFlag: true });
    }
  };

  onNLAllCompany = () => {
    this.setState({ allNlCompany: !this.state.allNlCompany });
  };

  render() {
    const {
      profile,
      auth,
      uploadRespose,
      uploadLoading,
      uploadError,
      yearData,
      submitPDSuccess,
      NIS,
      SM,
      announcement,
      broker,
      stockPulse,
      concall,
      concallSuccess,
      audit,
      rolesAllowed,
      addCompanyLoading,
      addCompanySuccess,
      addCompanyError,
      addCompanyMessage,
      yearDataLoading,
      submitPDLoading,
      nlTemplates,
    } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    if (uploadRespose || submitPDSuccess) {
      return <Redirect to="/report" />;
    }
    // if (uploadLoading) {
    //   return (
    //     <div className="progress">
    //       <div className="indeterminate"></div>
    //     </div>
    //   );
    // }
    if (NIS && NIS.NISSuccess && NIS.NewsNISSuccess) {
      return <Redirect to="/nis" />;
    }
    if (SM && SM.SMSuccess && SM.NewsSMSuccess) {
      return <Redirect to="/sm" />;
    }
    if (
      announcement &&
      announcement.announcementSuccess &&
      announcement.NewsAnnouncementSuccess
    ) {
      return <Redirect to="/announce" />;
    }
    if (stockPulse && stockPulse.stockSuccess && stockPulse.NewsSTockSuccess) {
      if (this.state.stock_type == "company") {
        if (stockPulse.stockTweetSuccess && stockPulse.NewsSTockSuccess) {
          return <Redirect to="/stockTweet" />;
        }
      } else return <Redirect to="/stock" />;
    }
    if (concallSuccess) {
      return <Redirect to="/concall" />;
    }

    if (
      audit.auditDataSuccess &&
      this.state.criteria == "company" &&
      this.state.modalChoose == "audit-monitor"
    ) {
      return <Redirect to="/audit" />;
    }
    if (
      audit.auditDataSuccess &&
      (this.state.criteria == "auditor" ||
        this.state.criteria == "partner" ||
        this.state.criteria == "continuation" ||
        this.state.criteria == "alerts" ||
        this.state.accPolSearch == "change")
    ) {
      return <Redirect to="/auditor" />;
    }

    if (audit.accPolChangeSuccess && this.state.modalChoose == "acc-policy") {
      return <Redirect to="/accPolicy" />;
    }

    if (audit.auditDataSuccess && this.state.modalChoose == "audit-matter") {
      return <Redirect to="/auditMatter" />;
    }

    if (audit.auditDataSuccess && this.state.modalChoose == "broad-finance") {
      return <Redirect to="/broadFinance" />;
    }

    if (this.state.modalChoose == "statistics") {
      return <Redirect to="/userStat" />;
    }
    if (this.state.modalChoose === "nl-reports") {
      return <Redirect to="/nl/reports?type=quarterly" />;
    }
    if (this.state.modalChoose === "nl-monthly") {
      return <Redirect to="/nl/reports?type=monthly" />;
    }
    if (this.state.modalChoose === "nl-reports-life") {
      return <Redirect to="/nl/reports/life?type=quarterly" />;
    }

    if (this.props.nlDataSuccess) {
      return <Redirect to="/nlDashboard" />;
    }
    if (this.props.nlDataSuccess) {
      return <Redirect to="/nlDashboard" />;
    }

    // console.log("local state", this.state);
    console.log("Dashboard");
    console.log("local props", this.props);
    console.log("local state", this.state);
    return (
      <div
        className="row"
        style={{ padding: 10, paddingBottom: 0, marginBottom: 0 }}
      >
        <DashboardModal
          auth={this.props.auth}
          state={this.state}
          id={this.state.modalChoose}
          onChangeHandler={this.onChangeHandler}
          HandleAuditPartnerChange={this.OnAudtPartnerChange}
          HandleAuditorChange={this.OnAuditorChange}
          company={this.state.company}
          sector={this.state.allSector}
          brokers={this.state.broker}
          HandleChange={this.HandleChange}
          selectedOptions={this.state.selectedOptions}
          selectedAuditor={this.state.selectedAuditor}
          selectedAuditFirm={this.state.selectedAuditPartner}
          selectedMulOptions={this.state.selectedSecOptions}
          yearData={
            this.state.modalChoose == "audit-monitor" ||
            this.state.modalChoose == "acc-policy" ||
            this.state.modalChoose == "audit-matter" ||
            this.state.modalChoose == "broad-finance"
              ? audit.auditYearsData
              : yearData
          }
          broker={broker}
          updateRangeInput={this.updateRangeInput}
          onClickHandler={this.onClickHandler}
          onNISSubmit={this.onNISSubmit}
          onSMSubmit={this.onSMSubmit}
          onAnnounceSubmit={this.onAnnounceSubmit}
          onConcallSubmit={this.ConcallSubmit}
          onStockSubmit={this.onStockSubmit}
          onFileChangeHandler={this.onFileChangeHandler}
          uploadError={uploadError}
          uploadLoading={uploadLoading || submitPDLoading}
          stockError={stockPulse.stockError}
          stockLoading={stockPulse.stockLoading}
          annLoading={announcement.announcementloading}
          annError={announcement.announcementError}
          nisLoading={NIS.NISLoading}
          nisError={NIS.NISError}
          nis={this.props.NIS}
          smLoading={SM.SMLoading}
          smError={SM.SMError}
          concallLoading={this.props.concallLoading}
          HandleMulSelChange={this.HandleMulChange}
          concallError={this.props.concallError}
          onConcollFileChangeHandler={this.ConcallFileChanger}
          auditChange={this.auditChange}
          auditorData={this.props.audit.auditFirmsData}
          auditCriteria={this.state.criteria}
          auditPartnersData={this.props.audit.auditPartnersData}
          onAuditSubmit={this.auditSubmit}
          onAccPolSubmit={this.accPolSubmit}
          auditLoading={this.props.audit.auditDataLoading}
          auditError={this.props.audit.auditDataError}
          accPolSearch={this.state.accPolSearch}
          audit_year={this.state.audit_year}
          year={this.state.year}
          yearLoading={
            this.state.modalChoose === "audit-monitor" ||
            this.state.modalChoose === "acc-policy" ||
            this.state.modalChoose === "audit-matter" ||
            this.state.modalChoose === "broad-finance"
              ? audit.auditYearsLoading
              : yearDataLoading
          }
          year_quarter={this.props.nlYearQuarterAvailable}
          onNLDownload={this.onNLDownload}
          onNLDownloadLife={this.onNLDownloadLife}
          onNLDashboard={this.onNLDashboard}
          nlDataLoading={this.props.nlDataLoading}
          nlDataError={this.props.nlDataError}
          nlDataMsg={this.props.nlDataMsg}
          downloadNLReportLoading={this.props.downloadNLReportLoading}
          nlTemplates={nlTemplates}
          changeAllNl={this.onNLAllCompany}
        />
        <AddCompanyModal
          onChange={this.ChangeHandler}
          success={addCompanySuccess}
          error={addCompanyError}
          message={addCompanyMessage}
          state={this.state}
          addCompany={this.addCompany}
          handleFileChange={this.FileChangeHandler}
          addAR={this.addAr}
          removeAR={this.removeAr}
          customArLoading={this.props.customArLoading}
          customArSuccess={this.props.customArSuccess}
          customArError={this.props.customArError}
          customArMessage={this.props.customArMessage}
          customAr={this.props.customAr}
          fileUpload={this.upload}
        />
        <DownloadReportModal
          company={this.state.company}
          HandleChange={this.DownloadReport}
          fileSubmit={this.upload}
          loading={this.props.reportLoading}
          error={this.props.reportError}
        />
        <div className="row" style={{ margin: 0 }}>
          {allTools?.map(
            (tool) =>
              (auth.isAdmin ||
                rolesAllowed?.find((role) => role.toolName === tool.name)) && (
                <ToolCard
                  ModalClick={this.ModalClick}
                  text={tool.text}
                  toolId={tool.toolId}
                  color={tool.color}
                  image={tool.image}
                  modal={tool.modal}
                  noModal={tool.noModal}
                />
              ),
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    auth: state.auth,
    rolesAllowed: state.auth.rolesAllowed,
    profile: state.auth,
    uploadRespose: state.uploadedDataResponse.uploadResponse,
    uploadLoading: state.uploadedDataResponse.uploadLoading,
    uploadError: state.uploadedDataResponse.uploadError,
    submitPDLoading: state.uploadedDataResponse.submitPDLoading,
    submitPDSuccess: state.uploadedDataResponse.submitPDSuccess,
    submitPDError: state.uploadedDataResponse.submitPDError,
    submitPD: state.uploadedDataResponse.submitPD,
    yearData: state.uploadedDataResponse.yearData,
    yearDataLoading: state.uploadedDataResponse.yearFetchLoading,
    yearDataSuccess: state.uploadedDataResponse.yearFetchSuccess,
    NIS: state.NIS,
    SM: state.SM,
    audit: state.audit,
    announcement: state.announcement,
    stockPulse: state.stockPulse,
    broker: state.stockPulse.broker,
    secLoading: state.stockPulse.loadingSecCompanies,
    secSuccess: state.stockPulse.secCompaniesSuccess,
    secCompanies: state.stockPulse.secCompanies,
    secConcallLoading: state.concall.loadingSecCompanies,
    secConcallSuccess: state.concall.secCompaniesSuccess,
    secConcallCompanies: state.concall.secCompanies,
    concallLoading: state.concall.concallLoading,
    concallPrimary: state.concall.concall,
    concallSecondary: state.concall.concallSec,
    concallSuccess: state.concall.concallSuccess,
    concallError: state.concall.concallError,

    addCompanyLoading: state.admin.addCompanyLoading,
    addCompanySuccess: state.admin.addCompanySuccess,
    addCompanyError: state.admin.addCompanyError,
    addCompanyMessage: state.admin.addCompanyMessage,
    addedCompanyNews: state.admin.addedCompanyNews,

    customArLoading: state.admin.customArLoading,
    customArSuccess: state.admin.customArSuccess,
    customArError: state.admin.customArError,
    customArMessage: state.admin.customArMessage,
    customAr: state.admin.customAr,

    defaultData: state.auth.defaultData,

    reportLoading: state.combine.reportLoading,
    reportSuccess: state.combine.reportSuccess,
    reportError: state.combine.reportError,

    nlDataLoading: state.nl.nlDataLoading,
    nlDataSuccess: state.nl.nlDataSuccess,
    nlDataError: state.nl.nlDataError,
    nlDataMsg: state.nl.nlDataMsg,

    downloadNLReportLoading: state.nl.downloadNLReportLoading,
    downloadNLReportError: state.nl.downloadNLReportError,
    downloadNLReportMsg: state.nl.downloadNLReportMsg,

    nlYearQuarterAvailableLoading: state.nl.nlYearQuarterAvailableLoading,
    nlYearQuarterAvailableSuccess: state.nl.nlYearQuarterAvailableSuccess,
    nlYearQuarterAvailableError: state.nl.nlYearQuarterAvailableError,
    nlYearQuarterAvailable: state.nl.nlYearQuarterAvailable,
    nlYearQuarterAvailableMsg: state.nl.nlYearQuarterAvailableMsg,

    nlTemplates: state.nl.nlTemplates || [],

    // auditComData: state.NIS.auditComData.companies,
    // auditorData: state.NIS.auditComData.auditor,
    // auditPartnersData: state.NIS.auditComData.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (file, token) => {
      dispatch(uploadFile(file, token));
    },
    SelectYear: (companyId, token) => {
      dispatch(selectYear(companyId, token));
    },
    SubmitPD: (company, year, token) => {
      dispatch(submitPD(company, year, token));
    },
    GetCompanyList: (token) => {
      dispatch(getCompanyList(token));
    },
    GetSectorList: (token) => {
      dispatch(getSectorList(token));
    },
    GetNewsNIS: (data, token) => {
      dispatch(getNewsNIS(data, token));
    },
    GetNewsSM: (data, token) => {
      dispatch(getNewsSM(data, token));
    },
    GetAnnouncement: (body, token) => {
      dispatch(getAnnouncement(body, token));
    },
    GetBroker: (token) => {
      dispatch(getBroker(token));
    },
    GetStockPulse: (body, token) => {
      dispatch(getStockPulse(body, token));
    },
    GetStockTweet: (body, token) => {
      dispatch(getStockTweetReport(body, token));
    },
    GetConfig: (token) => {
      dispatch(getConfig(token));
    },
    HomeReached: () => {
      dispatch(onHome());
    },
    GetSecCompanies: (id, token) => {
      dispatch(getSecCompanies(id, token));
    },
    AddSecCompanies: (data, token) => {
      dispatch(addSecCompanies(data, token));
    },
    UpdateConcallCompany: (com, secs, token) => {
      dispatch(updateConcallCompany(com, secs, token));
    },
    GetConcallScores: (com, secs, token) => {
      dispatch(getConcallScores(com, secs, token));
    },
    GetConcallSecCompanies: (id, token) => {
      dispatch(getConcallSecCompanies(id, token));
    },
    GetConcallScoreFile: (file, token) => {
      dispatch(getConcallScoreFile(file, token));
    },
    GetAuditCompany: (token) => {
      dispatch(getAuditCom(token));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
    GetNews: (start, end, company, token) => {
      dispatch(getNews(start, end, company, token));
    },
    GetAuditFirms: (token) => {
      dispatch(getAuditFirms(token));
    },
    GetAuditPartners: (token) => {
      dispatch(getAuditPartners(token));
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    GetSecondaryComAudit: (secIds, year, token) => {
      dispatch(getSecondaryComAudit(secIds, year, token));
    },
    GetAccPolChange: (cid, secIds, year, token) => {
      dispatch(getAccPolChange(cid, secIds, year, token));
    },
    AddCompany: (data, token) => {
      dispatch(addCompany(data, token));
    },
    uploadFile: (file, token) => {
      dispatch(uploadOwnAR(file, token));
    },
    GetAccPolList: (year, noOfChange, token) => {
      dispatch(getAccPolChangeList(year, noOfChange, token));
    },
    SetDefaultCompany: (data) => {
      // console.log("&&&&&&&&&&&&here", data);
      dispatch(setDefaultCompany(data));
    },
    GetReport: (companyId, companyName, token) => {
      dispatch(getUserReport(companyId, companyName, token));
    },
    DownloadNLReport: (query, body, token) => {
      dispatch(downloadNLReport(query, body, token));
    },
    DownloadNLReportLife: (query, body, token) => {
      dispatch(downloadNLReport(query, body, token, "life"));
    },
    GetNlData: (query, token) => {
      dispatch(getNlData(query, token));
    },
    GetAvilableNLYearQuarter: (token) => {
      dispatch(getAvailableNlYearquarter(token));
    },
    GetNLTemplates: (token) => {
      dispatch(getNLTemplates(token));
    },
    GetNLSegments: (token) => {
      dispatch(getNLSegments(token));
    },
    GetNLStatYears: (token) => {
      dispatch(getNLStatYears(token));
    },
    GetSettingsMeta: (query, token) => {
      dispatch(getSettingsMeta(query, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
