import React, { Component } from "react";
import { connect } from "react-redux";
import M from "materialize-css";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import PieChart from "../layout/PieChart";
import Select from "react-select";
import {
  getStockPulse,
  getPieChart,
} from "../../store/action/stockPulseAction";

import "./style.css";
import { relativeTimeThreshold } from "moment";
import StockPriceTable from "../layout/StockPriceTable";

class stockPulseDashboard extends Component {
  state = {
    data: [],
    passParam: {},
    broker: {},
    sentimentList: [],
    sentimentCount: {},
    checker: false,
    updateCheck: true,
    SelectedBroker: [],
    selectedOptions: null,
    type: this.props.initData ? this.props.initData.type : null,
    id: this.props.initData ? this.props.initData.id : null,
    startDate: this.props.initData ? this.props.initData.startDate : null,
    endDate: this.props.initData ? this.props.initData.endDate : null,
    novelty: this.props.initData ? this.props.initData.novelty : null,
  };

  componentDidMount = () => {
    document.body.style.overflow = "visible";
    var elems = document.querySelectorAll("select");
    var instances = M.FormSelect.init(elems, {});
    var elems = document.querySelectorAll(".datepicker");
    M.Datepicker.init(elems, {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(Date.now()),
      onClose: () => {
        this.onDateClose();
      },
    });
    this.setState({ data: this.props.stockPulse, broker: this.props.broker });
    this.findParam();
    // this.findSentiment();
    this.selectedBroker();
    // this.count();
  };

  componentDidUpdate() {
    if (this.props.stockSuccess && this.state.updateCheck) {
      this.findSentiment();
      //console.log("VTVGVYGVYGVYGVGVVYGGVYGGVYGVYGxxxxxx");
      this.setState({ updateCheck: false, checker: true });
    }
  }

  findParam = (e) => {
    var company = this.props.NIS.companyList;
    var sector = this.props.SM.sectorList;
    var broker = this.props.broker.data;
    var type = this.props.initData ? this.props.initData.type : null;
    var id = this.props.initData ? this.props.initData.id : null;
    // //console.log(broker)
    if (type == "company") {
      var result = company.find((data) => data.id === id);
      this.setState({ passParam: result });
    } else if (type == "sector") {
      var result = sector.find((data) => data.id === id);
      this.setState({ passParam: result });
    } else if (type == "broker") {
      var result = broker.find((data) => data.id === id);
      this.setState({ passParam: result });
    }
  };

  findSentiment = (e) => {
    // //console.log("find sentiment", this.props.stockPulse);
    var data = this.props.stockPulse;
    var counts = {};
    if (data != null) {
      for (var i = 0; i < data.length; i++) {
        if (counts[data[i].sent]) {
          counts[data[i].sent]++;
        } else {
          counts[data[i].sent] = 1;
        }
      }
      var pieData = Object.entries(counts).map(([topic, count]) => {
        return { topic, count };
      });
      this.props.GetPieChart(pieData);
    }
  };

  selectedBroker = () => {
    var broker = [];
    var Broker = this.props.broker.data;
    //console.log("selected broker", Broker);
    if (Broker && Broker.length) {
      for (var i = 0; i < Broker.length; i++) {
        broker.push({
          value: Broker[i].id,
          label: Broker[i].name,
        });
        // this.setState({broker:broker})
      }
      //console.log(broker);
    }
    this.setState({ SelectedBroker: broker });
  };

  onDateClose = () => {
    this.setState({
      ...this.state,
      startDate: document.getElementById("startDate").value,
      endDate: document.getElementById("endDate").value,
    });
    // //console.log(this.state)
  };

  onChangeHandler = (event) => {
    //console.log(event.target.id, event.target.value);
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  HandleChange = (selectedOptions) => {
    if (selectedOptions)
      //console.log(selectedOptions);
      this.setState({
        selectedOptions: selectedOptions,
      });
  };

  onStockSubmit = (e) => {
    e.preventDefault();
    var data = {
      // startDate: this.formatDate(this.state.startDate),
      startDate: this.state.startDate,
      // endDate: this.formatDate(this.state.endDate),
      endDate: this.state.endDate,
      novelty: Number(this.state.novelty),
      type: this.props.initData.type,
      id: this.props.initData.id,
      brokerId: this.state.selectedOptions
        ? this.state.selectedOptions.map((d) => {
            return d.value;
          })
        : null,
      secondary_company_id: this.props.initData.secondary_company_id,
    };
    //console.log("Successfully submited", data);
    this.setState({ updateCheck: true });
    this.props.GetStockPulse(data, this.props.auth.accessToken);
  };

  customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: "black",
      width: "120%",
      maxHeight: 90,
      overflowY: "auto",
    }),
  };

  clickForPrimary = (id) => {
    //console.log(id, this.props.totalCompanyData);
    var total_data = this.props.totalCompanyData;
    var prime = total_data.find((el) => el == id);
    var second = total_data.filter((el) => el != id);
    //console.log("prime", prime, "second", second);
    // var data = {
    //   primary_company_id: prime,
    //   secondary_company_id: second,
    // };
    // this.props.AddSecCompanies(data, this.props.auth.accessToken);
    this.setState({ updateCheck: true });
    var data = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      novelty: Number(this.state.novelty),
      type: this.props.initData.type,
      id: prime,
      brokerId: null,
      secondary_company_id: second,
    };
    this.props.GetStockPulse(data, this.props.auth.accessToken);
  };
  render() {
    if (!this.props.auth.uid) return <Redirect to="/SignIn" />;
    if (this.props.initData == null) return <Redirect to="/dashboard" />;
    //console.log(this.state);
    //console.log(this.props);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: 378, backgroundColor: "#bf750d" }}>
          {/* <h5 style={{paddingLeft:30, paddingTop:7, color:"antiquewhite"}}><strong>{this.state.type}:{this.state.passParam.name}</strong></h5> */}
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col s6" style={{ height: 283 }}>
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  marginLeft: 10,
                  paddingTop: 10,
                  width: "95%",
                  height: 275,
                  marginRight: "70%",
                  marginTop: 10,
                  // backgroundColor: "#bfbf0d"
                  backgroundColor: "rgb(255,255,255,0.5)",
                  border: "0px solid",
                  borderRadius: "5%",
                }}
              >
                <div
                  className="col s4"
                  style={{ width: "89%", marginLeft: "5%" }}
                >
                  {this.state.type == "market" ? (
                    <>
                      <span>
                        <strong>Market</strong>
                      </span>
                    </>
                  ) : this.state.type == "company" ? (
                    <>
                      <span>
                        <strong>Company:</strong>
                      </span>
                      <span style={{ marginLeft: 20 }}>
                        {this.state.passParam.name}
                      </span>{" "}
                    </>
                  ) : this.state.type == "sector" ? (
                    <>
                      <span>
                        <strong>Sector:</strong>
                      </span>{" "}
                      <span style={{ marginLeft: 20 }}>
                        {this.state.passParam.name}
                      </span>
                    </>
                  ) : this.state.type == "broker" ? (
                    <>
                      <span>
                        <strong>Broker:</strong>
                      </span>
                      <span style={{ marginLeft: 20 }}>
                        {this.state.passParam.name}
                      </span>
                    </>
                  ) : null}
                </div>
                <div
                  className="col s4"
                  style={{ width: "89%", marginLeft: "5%" }}
                >
                  <label
                    style={{
                      color: "#000000",
                      textShadow: "0.4px 0px 0px black",
                      fontSize: 15,
                    }}
                  >
                    Start Date
                  </label>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="startDate"
                    value={this.state.startDate}
                    style={{
                      backgroundColor: "transparent",
                      // borderBottom: "none",
                      borderBottomColor: "black",
                      outline: "none",
                      height: 25,
                    }}
                  />
                </div>
                <div
                  className="col s4"
                  style={{ width: "89%", marginLeft: "5%" }}
                >
                  <label
                    style={{
                      color: "#000000",
                      textShadow: "0.4px 0px 0px black",
                      fontSize: 15,
                    }}
                  >
                    End Date
                  </label>
                  <input
                    type="text"
                    className="datepicker validate center-align"
                    id="endDate"
                    value={this.state.endDate}
                    // onChange={this.onChangeHandler}
                    style={{
                      backgroundColor: "transparent",
                      // borderBottom: "none",
                      borderBottomColor: "black",
                      outline: "none",
                      height: 25,
                    }}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="input-field"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      // width: "89%",
                      marginLeft: "6.2%",
                    }}
                  >
                    <div
                      style={{
                        color: "#000000",
                        textShadow: "0.4px 0px 0px black",
                        fontSize: 15,
                      }}
                    >
                      Novelty
                    </div>
                    <input
                      type="number"
                      step="0.1"
                      min="0.1"
                      max="1.0"
                      onChange={this.onChangeHandler}
                      style={{
                        borderBottomColor: "black",
                        height: 20,
                        marginLeft: 9,
                      }}
                      id="novelty"
                      value={this.state.novelty}
                      className="validate center-align"
                      required
                    />
                  </div>
                  <div
                    className="input-field"
                    style={{
                      width: "40%",
                      marginLeft: "-15px",
                      marginTop: "-1px",
                      paddingRight: 50,
                    }}
                  >
                    <Select
                      isMulti={true}
                      styles={this.customStyles}
                      value={this.state.selectedOptions}
                      onChange={this.HandleChange}
                      options={this.state.SelectedBroker}
                      placeholder="Select broker..."
                    />
                    {/* <label
              style={{
                color: "#000000",
                textShadow: "0.4px 0px 0px black",
                fontSize: 15,
              }}
            >
              Brokers
            </label> */}
                  </div>
                  <div style={{ paddingRight: 53, paddingTop: 2 }}>
                    <button
                      className="btn right"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                      onClick={this.onStockSubmit}
                    >
                      Submit
                      <i className="material-icons right">send</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col s6" style={{ height: 283 }}>
              {!this.props.stockPulse ||
              this.props.stockPulse.length === 0 ? null : this.state.checker &&
                this.props.pieChartSuccess ? (
                this.props.initData.type == "company" ? (
                  this.props.stockLoading ? (
                    <div
                      className="preloader-wrapper active"
                      style={{ marginLeft: "50%", marginTop: "20%" }}
                    >
                      <div className="spinner-layer spinner-black-only">
                        <div className="circle-clipper left">
                          <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                          <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                          <div className="circle"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <StockPriceTable
                      tableData={this.props.tableData}
                      clickForPrimary={this.clickForPrimary}
                    />
                  )
                ) : (
                  <PieChart
                    // id="pieChart"
                    data={this.props.chartData}
                    key={this.props.chartData}
                    // dimensions={this.state.topicList && this.state.topicList}
                    // measures={this.state.count && this.state.count}
                    height="200"
                    width="200"
                  />
                )
              ) : null}
            </div>
          </div>
          <div
            className="row stock-pulse-header" /* style={{marginLeft:35, textAlign:"-webkit-right"}} */
          >
            <div className="col s2">
              <b>Date Time</b>
            </div>
            <div className="col s8" style={{ textAlign: "center" }}>
              <b>Event</b>
            </div>
            <div className="col s2">
              <b>Sentiment</b>
            </div>
          </div>
          {/* <Announcements announcements={this.state.data?this.state.data:[]}/> */}
          <div
            className="topNews"
            // style={{
            //   height: "460px",
            //   overflowY: "auto",
            //   marginTop: 56,
            //   // marginLeft: 40,
            //   width: "80%",
            //   marginRight: 50,
            //   backgroundColor: "transparent",
            // }}
          >
            <ul
              className="collection"
              style={{
                backgroundColor: "rgb(255,255,255,0.5)",
                paddingTop: 15,
                paddingLeft: 70,
                // width:"60%",
                marginLeft: 300,
                boxShadow: "2px 20px 20px",
              }}
            >
              {!this.props.stockPulse || this.props.stockPulse.length === 0 ? (
                <div style={{ marginBottom: 15, textAlign: "center" }}>
                  <b>No data found, please change selection criteria</b>
                </div>
              ) : (
                <div>
                  {this.props.stockPulse &&
                    this.props.stockPulse.map((news) => (
                      <div className="row top-news-item">
                        <div className="col s3 center center-align">
                          <p>{news.date ? news.date : " "}</p>
                        </div>
                        {/* news.broker.map((brokers) => (
                          <div className="col s2 left left-align">
                            <p
                              style={{
                                color: "#000000",
                                textShadow: "0.4px 0px 0px black",
                              }}
                            >
                              {brokers.brokerId
                                ? brokers.brokerId
                                : " "}
                            </p>
                          </div>
                        )) */}
                        <div className="col s7 left-align">
                          <a
                            href={news.news.newsURL ? news.news.newsURL : " "}
                            rel="noopener noreferrer"
                            target="_blank"
                            // className="collection-item"
                            style={{
                              color: "#000000",
                              backgroundColor: "transparent",
                              textShadow: "0.4px 0px 0px black",
                            }}
                          >
                            {news.trigger_event ? news.trigger_event : " "}
                          </a>
                        </div>
                        {/* <div className="col s1" style={{ color: "#23431b" }}>
                        {news.score >= 0 ? (
                          <p>
                            <i
                              className="material-icons"
                              style={{
                                color: "#000000",
                                textShadow: "0.4px 0px 0px black",
                              }}
                            >
                              add_circle
                            </i>
                          </p>
                        ) : (
                          <p style={{ color: "red" }}>
                            <i className="material-icons">do_not_disturb_on</i>
                          </p>
                        )}
                      </div> */}
                        <div className="col s2" style={{ color: "#23431b" }}>
                          <p>
                            {news.sentiment > 0 ? (
                              <strong style={{ color: "green" }}>
                                {news.sent == "Very Positive"
                                  ? "Vpos"
                                  : news.sent == "Positive"
                                  ? "Pos"
                                  : news.sent == "Neutral"
                                  ? "Neut"
                                  : news.sent == "Negative"
                                  ? "Neg"
                                  : news.sent == "Very Negative"
                                  ? "Vneg"
                                  : ""}
                              </strong>
                            ) : news.sentiment < 0 ? (
                              <strong style={{ color: "red" }}>
                                {news.sent == "Very Positive"
                                  ? "Vpos"
                                  : news.sent == "Positive"
                                  ? "Pos"
                                  : news.sent == "Neutral"
                                  ? "Neut"
                                  : news.sent == "Negative"
                                  ? "Neg"
                                  : news.sent == "Very Negative"
                                  ? "Vneg"
                                  : ""}
                              </strong>
                            ) : news.sentiment === 0 ? (
                              <strong style={{ color: "blue" }}>
                                {news.sent == "Very Positive"
                                  ? "Vpos"
                                  : news.sent == "Positive"
                                  ? "Pos"
                                  : news.sent == "Neutral"
                                  ? "Neut"
                                  : news.sent == "Negative"
                                  ? "Neg"
                                  : news.sent == "VeryNegative"
                                  ? "Vneg"
                                  : ""}
                              </strong>
                            ) : (
                              " "
                            )}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  var arrCompany = [];
  var company = state.stockPulse.stock.table_data;
  if (
    company &&
    company.primary_company[0] &&
    company.primary_company[0].companyInfo
  ) {
    arrCompany.push(company.primary_company[0].companyInfo.id);
    for (var i = 0; i < company.secondary_company.length; i++) {
      arrCompany.push(company.secondary_company[i].companyInfo.id);
      //console.log("____in", arrCompany);
    }
  }

  //console.log("____here", arrCompany);
  return {
    auth: state.auth,
    stockPulse:
      state.stockPulse && state.stockPulse.stock.data
        ? state.stockPulse.stock.data
        : [],
    initData:
      state.stockPulse && state.stockPulse.initData
        ? state.stockPulse.initData
        : null,
    stockLoading: state.stockPulse.stockLoading,
    stockSuccess: state.stockPulse.stockSuccess,
    NIS: state.NIS,
    SM: state.SM,
    broker: state.stockPulse.broker,
    pieChartSuccess: state.stockPulse.pieChartSuccess,
    pieChartError: state.stockPulse.pieChartError,
    chartData: state.stockPulse.chartData,
    tableData: state.stockPulse.stock.table_data,
    totalCompanyData: arrCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetStockPulse: (body, token) => {
      dispatch(getStockPulse(body, token));
    },
    GetPieChart: (data) => {
      dispatch(getPieChart(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(stockPulseDashboard);
