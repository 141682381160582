import React, { Component } from "react";
import * as d3 from "d3";

class StackBarChart extends Component {
  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    var margin = {top: 20, right: 120, bottom: 35, left: 30};

    const { can_width, can_height, data, dimensions, measures, filters, id } = this.props;
    if( !data) return
    if(data.length==0) return
    var width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    d3.select("#"+id).select("svg").remove();
    console.log("GETtiNG DRAWN");
    var svg = d3.select("#"+id)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    
    
    /* Data in strings like it would be if imported from a csv */
        
    var parse = d3.timeParse("%Y");
    
    
    // Transpose the data into layers
    var m = data&&data.length>0?measures.map(function(mea) {
      var x = data.map((d) => {
        return {x: parse(d[dimensions[0].text]), y: +d[mea.text]};
      });
      // //console.log("X", x);
      return x
    }):[]
    //console.log("M", m);
    var dataset = m;
    //console.log("dataset", dataset);
    
    // Set x, y and colors
    var x = d3.scaleBand()
      .domain(dataset&&dataset.length>0?dataset[0].map(function(d) { return d.x; }):[])
      .rangeRound([0, width])
      .padding(0.02);
    
    var y = d3.scaleLinear()
      .domain([0, d3.max(dataset, function(d) {  return d3.max(d, function(d) { return d.y; });  })])
      .range([height, 0]);
    
    var colors = [ "#f2b447", "#82b0d0"];
    
    
    // Define and draw axes
    var yAxis = d3.axisLeft()
      .scale(y)
      // .orient("left")
      .ticks(5)
      .tickSize(-width, 0, 0)
      .tickFormat( function(d) { return d } );
    
    var xAxis = d3.axisBottom()
      .scale(x)
      // .orient("bottom")
      .tickFormat(d3.timeFormat("%Y"));
    
    svg.append("g")
      .attr("class", "y axis")
      .call(yAxis);
    
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);
    
    
    // Create groups for each series, rects for each segment 
    var groups = svg.selectAll("g.cost")
      .data(dataset)
      .enter().append("g")
      .attr("class", "cost")
      .style("fill", function(d, i) { return colors[i]; });
    
    var rect = groups.selectAll("rect")
      .data(function(d) { return d; })
      .enter()
      .append("rect")
      .attr("x", function(m) { return x(m.x); })
      .attr("y", function(m) { return y( m.y); })
      .attr("height", function(m) { return y(0) - y(m.y); })
      .attr("width", Math.ceil(width/(data.length+8)));
      // .on("mouseover", function() { tooltip.style("display", null); })
      // .on("mouseout", function() { tooltip.style("display", "none"); })
      // .on("mousemove", function(m) {
      //   var xPosition = d3.mouse(this)[0] - 15;
      //   var yPosition = d3.mouse(this)[1] - 25;
      //   tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
      //   tooltip.select("text").text(m.y);
      // });
    
    
    // Draw legend
    var legend = svg.selectAll(".legend")
      .data(colors)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function(d, i) { return "translate(30," + i * 19 + ")"; });
     
    legend.append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", function(d, i) {return colors.slice().reverse()[i];});
     
    legend.append("text")
      .attr("x", width + 5)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text(function(d, i) { 
        switch (i) {
          case 0: return "Parsed";
          case 1: return "Unparsed";
        }
      });
    
    
    // Prep the tooltip bits, initial display is hidden
    // var tooltip = svg.append("g")
    //   .attr("class", "tooltip")
    //   .style("display", "none");
        
    // tooltip.append("rect")
    //   .attr("width", 30)
    //   .attr("height", 20)
    //   .attr("fill", "white")
    //   .style("opacity", 0.5);
    
    // tooltip.append("text")
    //   .attr("x", 15)
    //   .attr("dy", "1.2em")
    //   .style("text-anchor", "middle")
    //   .attr("font-size", "12px")
    //   .attr("font-weight", "bold");
  }

  render() {
    return <div id={this.props.id} style={{ margin: 5 }}></div>;
  }
}
export default StackBarChart;
