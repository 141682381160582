const initState = { 
    SMSuccess: false, // make true when auth succdessful
    NewsSMSuccess: false, // make true when auth succdessful
    SMMessage: '', //change when auth failed
    SMLoading: false,
    SMError:'',
    SMResponse:null,
    sectorList:null,
    SMTopNewsLoading:false,
    SMTopNewsSuccess:false,
    initData:null,
    selected:null
  };
  
  const SMReducer = (state = initState, action) => {
    switch (action.type) {
      case 'LOADING_SM':
        return {
          ...state,
          SMLoading: true,
          SMSuccess: false,
          SMMessage: '',
          SMError:'',
          SMResponse:null
        };
  
      case 'LOADING_SM_TOP_NEWS':
        return {
          ...state,
          SMTopNewsLoading: true,
          SMTopNewsSuccess: false,
          NewsSMTopNews:null,
          selected:action.payload
        };
      case 'SECTOR_LIST_FETCH_SUCCESS':
        return {
          ...state,
          sectorList:action.payload
        };
      case 'NEWS_SM_FETCH_SUCCESS':
        return {
          ...state,
          SMLoading: false,
          SMSuccess: true,
          SMError:"",
          NewsSMSuccess:true,
          NewsSMData:action.payload.data, 
          NewsSMFrequency:action.payload.frequency, 
          // NewsSMTopNews:action.payload.news,
          NewsSMSector:action.payload.sectorName,
          initData:action.payload.body
        };
        case 'SM_TOP_NEWS_FETCH_SUCCESS':
        return {
          ...state,
          SMTopNewsLoading: false,
          SMTopNewsSuccess: true, 
          NewsSMTopNews:action.payload,
          selected:action.payload.selected,
        };
        case 'NEWS_SM_FETCH_ERROR':
        return {
          ...state,
          SMLoading: false,
          SMSuccess: false,
          SMMessage: '',
          SMError:'SM failed',
          SMResponse:null
        };
        case 'INITIAL_STATE':
          return initState
        case 'HOME_CLICK':
          return initState
      default:
        return state;
    }
  };
  
  export default SMReducer;
  