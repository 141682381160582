import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import M from "materialize-css";
import { signOut } from "../../store/action/authActions";
import { AddCompanyModal } from "./AddCompanyModal";
import { getSectorList } from "../../store/action/smActions";
import AuditDashboardModal from "../layout/AuditDashboardModal";
import { addCompany } from "../../store/action/nisActions";
import PapersDownload from "../auth/PapersDownload";
import { uploadOwnAR } from "../../store/action/adminActions";

function titleCase(str) {
  return str
    .split(" ")
    .map((word) =>
      word.toLowerCase() != "and"
        ? word[0].toUpperCase() + word.slice(1).toLowerCase()
        : word,
    )
    .join(" ");
}
class Navbar extends Component {
  state = {
    sector: [],
    sectorId: null,
    company: null,
    keywords: null,
  };
  componentDidMount = () => {
    // var elems = document.querySelectorAll(".modal");
    // var instances = M.Modal.init(elems, { dismissible: false });
    if (this.props.auth.accessToken)
      this.props.GetSectorList(this.props.auth.accessToken);
    var elems = document.querySelectorAll(".sidenav");
    var instances = M.Sidenav.init(elems, {});
  };

  logOut = () => {
    this.props.signOut(this.props.profile.accessToken);
  };

  render() {
    const {
      auth,
      profile,
      user,
      addCompanyMessage,
      addCompanySuccess,
      addCompanyError,
      addedCompanyNews,
    } = this.props;
    console.log("loc state", this.state);
    return (
      <>
        <PapersDownload />
        <div className="navbar-fixed">
          <nav style={{ backgroundColor: "#7A5AD8" }}>
            <div
              className="nav-wrapper"
              style={{
                position: "relative",
                backgroundColor: /* '#042b47' */ "black",
              }}
            >
              <a href="#" data-target="mobile-demo" className="sidenav-trigger">
                <i className="material-icons">menu</i>
              </a>
              <ul className="left">
                <li>
                  <Link
                    className="product"
                    to={profile && auth.uid ? "/dashboard" : "/"}
                  >
                    <strong>Textplor</strong>
                  </Link>
                </li>
              </ul>
              {profile && auth.uid ? (
                <div>
                  <ul className="brand-logo right hide-on-med-and-down">
                    <li>
                      <a
                        className="dropdown-trigger"
                        href="#"
                        data-target="dropdown1"
                      >
                        <strong>Login as, {profile.name}</strong>
                        <i className="material-icons">arrow_drop_down</i>
                      </a>
                    </li>
                  </ul>
                  <ul id="dropdown1" className="dropdown-content">
                    <li>
                      <Link
                        className="product"
                        to={"/changePass"}
                        style={{ color: "#2487ce" }}
                      >
                        Change Password
                      </Link>
                    </li>
                    {user && user.isAdmin ? (
                      <>
                        <li>
                          <Link
                            className="db"
                            to={"/user/" + auth.uid + "/admindashboard"}
                            style={{ color: "#2487ce" }}
                          >
                            Admin Panel
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="db"
                            to={"/nl/validation"}
                            style={{ color: "#2487ce" }}
                          >
                            NL Validation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="db"
                            to={"/nl/upload/pdf"}
                            style={{ color: "#2487ce" }}
                          >
                            Upload NL
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="db"
                            to={"/nl/delete"}
                            style={{ color: "#2487ce" }}
                          >
                            Delete NL
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="db"
                            to={"/life/validation"}
                            style={{ color: "#2487ce" }}
                          >
                            Life Validation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="db"
                            to={"/life/upload/pdf"}
                            style={{ color: "#2487ce" }}
                          >
                            Upload Life PDF
                          </Link>
                        </li>
                        <li>
                          <a
                            className="db modal-trigger"
                            data-target="add-company-modal"
                            style={{ color: "#2487ce" }}
                            onClick={this.sectorList}
                          >
                            Add Company
                          </a>
                        </li>
                      </>
                    ) : null}
                    {/* <li>
                  <Link
                    className="db"
                    to={"/user/" + auth.uid + "/mydashboard"}
                    style={{ color: "#2487ce" }}
                  >
                    My Dashboard
                  </Link>
                </li> */}
                    <li>
                      <a
                        className="db"
                        style={{ color: "#2487ce" }}
                        onClick={this.logOut}
                      >
                        Log Out
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </nav>
        </div>
        {auth && auth.uid ? (
          <ul className="sidenav" id="mobile-demo">
            <li>
              <Link
                className="product"
                to={"/changePass"}
                style={{ color: "#2487ce" }}
              >
                Change Password
              </Link>
            </li>
            {user && user.isAdmin ? (
              <>
                <li>
                  <Link
                    className="db"
                    to={"/user/" + auth.uid + "/admindashboard"}
                    style={{ color: "#2487ce" }}
                  >
                    Admin Panel
                  </Link>
                </li>
                <li>
                  <a
                    className="db modal-trigger"
                    data-target="add-company-modal"
                    style={{ color: "#2487ce" }}
                    onClick={this.sectorList}
                  >
                    Add Company
                  </a>
                </li>
              </>
            ) : null}
            <li>
              <Link
                className="db"
                to={"/user/" + auth.uid + "/mydashboard"}
                style={{ color: "#2487ce" }}
              >
                My Dashboard
              </Link>
            </li>
            <li>
              <a
                className="db"
                style={{ color: "#2487ce" }}
                onClick={this.logOut}
              >
                Log Out
              </a>
            </li>
          </ul>
        ) : (
          <ul className="sidenav" id="mobile-demo">
            <li>
              <a href="/SignIn">Login</a>
            </li>
            <li>
              <a href="/register">Get Demo</a>
            </li>
            <li>
              <a className="modal-trigger" data-target="modal-papers">
                Research
              </a>
            </li>
          </ul>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.auth,
    user: state.auth.user,

    SM: state.SM,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (token) => {
      dispatch(signOut(token));
    },
    GetSectorList: (token) => {
      dispatch(getSectorList(token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
