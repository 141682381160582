import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import {
  getNLReportsList,
  getNLReportsData,
} from "../../store/action/nlActions";
import { useState } from "react";
import GeoDashboard from "./nlReports/GeoDashboard";
import AgingDashboard from "./nlReports/AgingDashboard";
import LoaderFull from "../layout/LoaderFull";
import IndustryPerf from "./nlReports/IndustryPerf";
import SegmentsPerf from "./nlReports/SegmentsPerf";
import RevenuePerf from "./nlReports/RevenuePerf";
import SegmentClaims from "./nlReports/SegmentClaims";
import SegmentCommission from "./nlReports/SegmentCommission";
import OpexComparision from "./nlReports/OpexComparision";
import MarketShare from "./nlReports/MarketShare";
import LobPerf from "./nlReports/LobPerf";
import useQuery from "./nlReports/laylout/useQuery";
import TrendDashboard from "./nlReports/TrendDashboard";
import GeoCompDashboard from "./nlReports/GeoCompDashboard";
import PeerDashboard from "./nlReports/PeerDashboard";
import PortfolioMixDashboard from "./nlReports/PortfolioMixDashboard";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../config/constants";
import RevenuePerfLife from "./nlReports/RevenuePerfLife";
import GeoDashboardLife from "./nlReports/GeoDashboardLife";
import GeoCompDashboardLife from "./nlReports/GeoCompDashboardLife";

export default function NLReportDashboard() {
  const query = useQuery();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const nlReports = useSelector((state) => state.nl.nlReports);
  const nlReportData = useSelector((state) => state.nl.nlReportData);
  const nlReportDataLoading = useSelector(
    (state) => state.nl.nlReportDataLoading,
  );

  const [report, setReport] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    console.log("LOCATION: ", location);
    setType(query.get("type"));
    if (location?.pathname?.includes("/life"))
      dispatch(getNLReportsList({ com_type: "life" }, auth.accessToken));
    else dispatch(getNLReportsList({ com_type: "gic" }, auth.accessToken));
  }, []);

  useEffect(() => {
    if (nlReports?.length > 0 && type === "monthly") {
      let tem_report = nlReports.find(
        (report) => report.name === "peer-dashboard",
      );
      onReportClick(tem_report);
    }
  }, [nlReports]);

  const onReportClick = (report) => {
    setReport(report);
    if (!report.multiApi) {
      if (location?.pathname?.includes("/life"))
        dispatch(
          getNLReportsData(report.name, { com_type: "life" }, auth.accessToken),
        );
      else
        dispatch(
          getNLReportsData(report.name, { com_type: "gic" }, auth.accessToken),
        );
    }
  };

  console.log("REPORTs", nlReports);

  if (!auth.uid) return <Redirect to="/SignIn" />;
  return (
    <div className="row" style={{ marginTop: 8 }}>
      <div className="col s12 l2 m5">
        <div
          className="row fixed-side-bar nl-report-side-bar"
          style={{ backgroundColor: "#454241" }}
        >
          {nlReports
            ?.filter((report) => report.type === type)
            ?.map((nlReport) => (
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className={
                  report?.id === nlReport.id
                    ? "report-select active-side"
                    : "report-select"
                }
                onClick={() => {
                  onReportClick(nlReport);
                }}
              >
                {nlReport.label}
                {report?.id === nlReport.id && (
                  <i
                    style={{
                      transform: "rotate(90deg)",
                      marginRight: -20,
                      fontSize: 32,
                    }}
                    className="material-icons white-text"
                  >
                    arrow_drop_down
                  </i>
                )}
              </p>
            ))}
        </div>
      </div>
      <div className="col s12 l10 m7">
        {report && (
          <strong>
            <p className="center center-align report-headline">
              {report.label}{" "}
              {nlReportData && nlReportData?.date
                ? report?.name === "market-share"
                  ? " | as of : " + nlReportData?.date
                  : " | " + nlReportData?.date
                : ""}
            </p>
          </strong>
        )}

        {nlReportDataLoading && <LoaderFull />}

        {nlReportData || report?.multiApi ? (
          report?.name === "market-share" ? (
            <MarketShare data={nlReportData?.data} />
          ) : report?.name === "industry-perf" ? (
            <IndustryPerf data={nlReportData?.data} />
          ) : report?.name === "seg-perf" ? (
            <SegmentsPerf data={nlReportData?.data} />
          ) : report?.name === "geo-dashboard" ? (
            <GeoDashboard data={nlReportData?.data} />
          ) : report?.name === "geo-comp-dashboard" ? (
            <GeoCompDashboard data={nlReportData?.data} type={NL_TYPE_GIC} />
          ) : report?.name === "rev-perf" ? (
            <RevenuePerf data={nlReportData?.data} />
          ) : report?.name === "seg-claim" ? (
            <SegmentClaims data={nlReportData?.data} />
          ) : report?.name === "seg-comm" ? (
            <SegmentCommission data={nlReportData?.data} />
          ) : report?.name === "opex-com" ? (
            <OpexComparision data={nlReportData?.data} />
          ) : report?.name === "lob-perf" ? (
            <LobPerf data={nlReportData?.data} />
          ) : report?.name === "aging-dashboard" ? (
            <AgingDashboard data={nlReportData?.data} />
          ) : report?.name === "trend-dashboard" ? (
            <TrendDashboard data={nlReportData} />
          ) : report?.name === "peer-dashboard" ? (
            <PeerDashboard />
          ) : report?.name === "portfolio-mix" ? (
            <PortfolioMixDashboard />
          ) : report?.name === "rev-perf-life" ? (
            <RevenuePerfLife data={nlReportData?.data} />
          ) : report?.name === "seg-claim-life" ? (
            <SegmentClaims data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "opex-com-life" ? (
            <OpexComparision data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "seg-comm-life" ? (
            <SegmentCommission data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "geo-dashboard-life" ? (
            <GeoDashboardLife data={nlReportData?.data} type={NL_TYPE_LIFE} />
          ) : report?.name === "geo-comp-dashboard-life" ? (
            <GeoCompDashboardLife
              data={nlReportData?.data}
              type={NL_TYPE_LIFE}
            />
          ) : null
        ) : null}
      </div>
    </div>
  );
}
