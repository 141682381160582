import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BarPieCombination from "./commonDashboards/BarPieCombination";
import { useSelector } from "react-redux";
import { NL_TYPE_GIC, NL_TYPE_LIFE } from "../../../config/constants";

export default function LobPerf({ label, data }) {
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const [myCompany, setMyCompany] = useState(null);
  const [secondaries, setSecondaries] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [type, setType] = useState("nop");
  const [reportDataTwo, setReportDataTwo] = useState([]);

  useEffect(() => {
    if (data) {
      let insSector = sectors?.find(
        (sec) => sec?.name?.toLowerCase() === "insurance",
      );
      let com = userCompany?.find(
        (com) =>
          com?.detail?.sector_id === insSector?.id &&
          com?.detail?.insuranceType !== NL_TYPE_GIC,
      );
      let secs = com?.secondaries;
      setMyCompany(com?.detail);
      setSecondaries(com?.secondaries);

      let coms = [...secs];
      coms.push(com.detail);
      setData(data, coms, type);
    }
  }, [data]);

  const setData = (dataOb, coms, changedType) => {
    console.log("Data", makeSegWise(dataOb[changedType]));
    setReportData(makeSegWise(dataOb[changedType]));
    setReportDataTwo(getBarChartData(dataOb[changedType], coms));
  };

  const makeSegWise = (arrayData) => {
    let newData = {};
    for (let i = 0; i < arrayData.length; i++) {
      let d = arrayData[i];
      let newArr = [];
      let segKeys = Object.keys(d)?.filter((key) => key.includes("%"));
      for (let j = 0; j < segKeys.length; j++) {
        newArr.push({
          segment: segKeys[j].replace("(%)", "").trim(),
          data: d[segKeys[j]],
        });
      }
      newData[d.Company] = newArr;
    }
    return newData;
  };

  const getBarChartData = (data, coms) => {
    let newData = [];
    console.log("BAR set:", coms);
    data?.map((d) => {
      let comFound = coms.find((com) => com.name === d.Company);
      if (comFound) {
        newData.push({
          company: d.Company,
          overall: d.Overall,
        });
      }
    });
    newData = newData?.sort((a, b) => (a.overall < b.overall ? 1 : -1));

    return newData;
  };
  console.log("REPORT DATA", reportData, secondaries, myCompany);

  const BAR_CHART_HEIGHT = 400;
  const BAR_CHART_WIDTH = 450;

  const handleTypeChange = (changedType) => {
    let coms = [...secondaries];
    coms.push(myCompany);
    setData(data, coms, changedType);
    setType(changedType);
  };

  // console.log("COLS", cols);
  // console.log("LOBS", lobs);
  console.log("REPORT DATA", reportData, reportDataTwo);
  console.log("DATA", data);
  return (
    <div className="row">
      <div className="col s2 offset-s10">
        <label>
          <input
            name="group1"
            type="radio"
            checked={type === "nop"}
            onChange={() => {
              handleTypeChange("nop");
            }}
          />
          <span>NOP</span>
        </label>
        <label>
          <input
            name="group1"
            type="radio"
            checked={type === "amount"}
            onChange={() => {
              handleTypeChange("amount");
            }}
          />
          <span>Premium</span>
        </label>
      </div>
      <div className="col s12">
        {/* <DataTable data={reportData} minRowInPage={10} /> */}
        <BarPieCombination
          barDetails={{
            data: reportDataTwo,
            height: BAR_CHART_HEIGHT,
            width: BAR_CHART_WIDTH,
            dimensions: [{ text: "company" }],
            measures: [{ text: "overall" }],
          }}
          pieDetails={{
            data: reportData,
            dimensions: [{ text: "segment" }],
            measures: [{ text: "data" }],
          }}
          myCompany={myCompany}
          secondaries={secondaries}
          lengendMaxChar={6}
        />
        <p className="notes-text">
          ** All segment wise figures are in percentage of total for the segment
        </p>
      </div>
    </div>
  );
}
