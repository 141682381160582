import React  from 'react';
import Select from 'react-select';

const ConfigModal = ({HandleChange, OnSubmit, state, companies, OnAddCompany,BrokerChange}) => {
  return (
      <div>
        <div id="config-modal" className="modal modal-fixed-footer">
            <div className='row modal-content'>
                <div className="row modal-title">
                <h3 style={{color:"rgb(93, 27, 143)"/* , paddingTop:20 */}}>Configuration</h3>
                </div>
                {/* <hr /> */}
                <div className="row"> 
                    <div className="col s12">
                        <form id="config" onSubmit={OnSubmit}>
                            <div className="row config-section">
                                <div className="col s12 ">
                                    <span><strong>Primary and secondary companies</strong></span>
                                    <button className="btn-floating right right-align" onClick={OnAddCompany}>
                                        <i className='material-icons'>add</i>
                                    </button>
                                </div>
                                {
                                    state.companyComponent.map((newCompany, index) =>
                                        newCompany
                                    )
                                }
                            </div>
                            
                            <div className="input-field row config-section">
                                <p><strong>Annual report Config</strong></p>
                                <div>
                                    <label>Number of year for anual report</label>
                                    <input
                                        type="number"
                                        id="years"
                                        onChange={HandleChange}
                                        value={state.years}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row config-section">
                                <p><strong>NIS Config</strong></p>
                                <div className="input-field col s12">
                                    <input
                                        type="number"
                                        id="months"
                                        onChange={HandleChange}
                                        value={state.months}
                                        required
                                    />
                                    <label>Number of month for graph</label>
                                </div>
                                <div className="input-field col s12">
                                    <input
                                        type="number"
                                        id="relevance"
                                        step="0.1"
                                        onChange={HandleChange}
                                        value={state.relevance}
                                        required
                                    />
                                    <label>Relevance</label>
                                </div>
                                <div className="input-field col s12">
                                    <input
                                        type="number"
                                        id="novelty"
                                        step="0.1"
                                        onChange={HandleChange}
                                        value={state.novelty}
                                        required
                                    />
                                    <label>Novelty Threshold</label>
                                </div>
                                <div className="input-field col s12">
                                    <select
                                        required
                                        onChange={HandleChange}
                                        value={state.frequency}
                                        id='frequency'
                                    >
                                        <option value="" disabled defaultValue>Choose your option</option>
                                        <option value="daily">daily</option>
                                        <option value="monthly">monthly</option>
                                        <option value="weekly">weekly</option>
                                    </select>
                                    <label>Frequency</label>
                                </div>
                            </div> 
                            <div className="row config-section">
                                <p><strong>Transcript Config</strong></p>
                                <label for="transcriptTopics">Topic</label>
                                <select
                                    // required
                                    onChange={HandleChange}
                                    multiple={true}
                                    value={state.transcriptTopics}
                                    id='transcriptTopics'
                                >
                                    <option value="" disabled defaultValue>Choose your option</option>
                                    <option value="all">All</option>
                                    {
                                        state.tts.map(tt => 
                                        <option value={tt}>{tt}</option>
                                        )
                                    }
                                </select>
                            </div> 
                            <div className="row config-section">
                                <p><strong>Stock Brokers</strong></p>
                                <Select
                                id='selectedBrokers'
                                options={state.brokers}
                                isMulti={true}
                                onChange={BrokerChange}
                                value={state.selectedBrokers}
                                />
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button className="modal-close waves-effect waves btn-flat">CANCEL</button>
                <button type='submit' form="config" className="waves-effect waves btn-flat">Update Config</button>
            </div>
        </div>
    </div>
  );  

}

export default ConfigModal;  
