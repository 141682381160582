import React, { Component } from "react";
import * as d3 from "d3";

class BarChartVertical extends Component {
  componentDidMount() {
    this.drawChart();
  }
  componentDidUpdate() {
    this.drawChart();
  }

  drawChart() {
    const {
      id,
      width,
      height,
      data,
      dimensions,
      measures,
      filters,
      hideLegend,
      charLength,
      margins,
      tooltip = true,
      labelInBar = false,
      barFontSize = 10,
    } = this.props;
    const legendMargin = hideLegend === true ? 0 : 100;
    const axisTickPaddingX = 70;
    const axisTickPaddingY = 30;

    var axisMargin = {
      top: margins?.top || 10,
      right: margins?.right ? legendMargin + margins?.right : legendMargin + 10,
      bottom: margins?.bottom || axisTickPaddingX,
      left: margins?.left || axisTickPaddingY,
    };
    // console.log(axisMargin);

    var wrap = function () {
      var maxLen = charLength || 50;
      var self = d3.select(this),
        textLength = self.node().getComputedTextLength(),
        text = self.text();
      while (textLength > 50 && text.length > 0) {
        text = text.slice(0, -1);
        self.text(text + "...");
        textLength = self.node().getComputedTextLength();
      }
    };

    // console.log("======data", dimensions, data);
    if (!data || data.length === 0) return;
    let innerHeight = height - axisMargin.top - axisMargin.bottom;
    let innerWidth = width - axisMargin.right - axisMargin.left;

    if (
      !dimensions ||
      dimensions.length == 0 ||
      !measures ||
      measures.length == 0
    )
      return;

    const dims = [dimensions[0].text]; //only 1 dimension assumed
    const meas = measures.map((x) => x.text);
    // console.log("======data 2", dimensions, meas);

    // Scale X and Y
    var x = d3.scaleBand().rangeRound([0, innerWidth]).paddingInner(0.1),
      y = d3.scaleLinear().rangeRound([innerHeight, 0]),
      z = d3.scaleOrdinal(d3.schemeCategory10),
      yGrp = d3.scaleBand().padding(0.05);

    // console.log("======data", x, y);

    y.domain([
      Math.min(
        0,
        d3.min(data, function (d) {
          return d3.min(meas, function (mea) {
            return d[mea];
          });
        }),
      ),
      d3.max(data, function (d) {
        return d3.max(meas, function (mea) {
          return d[mea];
        });
      }),
    ]).nice();

    x.domain(
      data.map(function (d) {
        // console.log("Y:", d[dims[0]]);
        return d[dims[0]];
      }),
    );
    yGrp.domain(meas).rangeRound([0, x.bandwidth()]);
    z.domain(meas);

    d3.select("#" + id)
      .select("svg")
      .remove();

    var g = d3
      .select("#" + id)
      .append("svg")
      .attr("width", Number(width))
      .attr("height", Number(height))
      .attr("class", "bar-chart")
      .append("g")
      .attr("width", Number(innerWidth))
      .attr("height", Number(innerHeight))
      .attr(
        "transform",
        "translate(" + axisMargin.left + "," + axisMargin.top + ")",
      );

    // create a tooltip
    var Tooltip = tooltip
      ? d3
          .select("#" + id)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("padding", "5px")
      : null;

    // Three function that change the tooltip when user hover / move / leave a cell
    var mouseover = function (d) {
      if (!tooltip) return;
      Tooltip.style("opacity", 1);
      d3.select(this).style("stroke", "black").style("opacity", 1);
    };
    var mousemove = function (d) {
      if (!tooltip) return;
      Tooltip.html("X: " + d.extra + "<br/>Y: " + d.value)
        .style("left", d3.mouse(this)[0] + 70 + "px")
        .style("top", d3.mouse(this)[1] + "px");
    };
    var mouseleave = function (d) {
      if (!tooltip) return;
      Tooltip.style("opacity", 0);
      d3.select(this).style("stroke", "none").style("opacity", 0.8);
    };

    g.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        return "translate(" + x(d[dims[0]]) + ",0 )";
      })
      .selectAll("rect")
      .data(function (d) {
        return meas.map(function (mea) {
          return { key: mea, value: d[mea], extra: d[dims[0]] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return yGrp(d.key);
      })
      .attr("y", function (d) {
        return y(Math.max(0, d.value));
      })
      .attr("width", yGrp.bandwidth())
      .attr("height", function (d) {
        return Math.abs(y(d.value) - y(0));
      })
      .attr("fill", function (d) {
        return z(d.key);
      })
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave);
    // .on('mouseover', tip.show).on('mouseout', tip.hide);

    //Draw X Axis
    g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + innerHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(90)")
      .style("text-anchor", "start");

    //Draw Y Axis
    g.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + x(0) + ",0)")
      .call(d3.axisLeft(y))
      .selectAll("text")
      .each(wrap);

    if (hideLegend !== false) {
      var legend = g
        .append("g")
        .attr("text-anchor", "start")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .selectAll("g")
        .data(meas)
        .enter()
        .append("g")
        .attr("transform", function (d, i) {
          return "translate(0," + i * 22 + ")";
        });

      legend
        .append("rect")
        .attr("x", innerWidth)
        .attr("width", 19)
        .attr("height", 19)
        .attr("fill", z);

      legend
        .append("text")
        .attr("x", innerWidth + 20)
        .attr("y", 9.5)
        .attr("dy", "0.32em")
        .text(function (d) {
          return d;
        });
    }

    if (labelInBar)
      g.append("g")
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(" + x(d[dims[0]]) + ", 0)";
        })
        .selectAll("text")
        .data(function (d) {
          return meas.map(function (mea) {
            return { key: mea, value: d[mea] };
          });
        })
        .enter()
        .append("text")
        .text(function (d) {
          return d?.value?.toFixed(2);
        })
        .attr("x", function (d) {
          return yGrp(d.key) - yGrp.bandwidth() / meas?.length / 8;
        })
        .attr("y", function (d) {
          return y(Math.max(0, d.value)) - 5;
        })
        .attr("font-family", "sans-serif")
        .attr("font-size", barFontSize)
        .attr("fill", "black")
        .attr("font-weight", "bold");
  }

  render() {
    return <div id={this.props.id}></div>;
  }
}
export default BarChartVertical;
