import React from "react";
import Select from "react-select";

export default function FilterBlock({
  onYearChange,
  years,
  selectedYear,
  onMonthChange,
  months,
  selectedMonth,
  onSegmentChange,
  segments,
  selectedSegment,
}) {
  return (
    <div>
      <div className="col s4">
        <label>Year</label>
        <select
          id="selectedYear"
          className="browser-default"
          onChange={onYearChange}
          value={selectedYear}
        >
          <option value="">Year</option>
          {years?.map((year) => (
            <option value={year.year}>{year.label}</option>
          ))}
        </select>
      </div>
      <div className="col s4">
        <label>Month</label>
        <select
          id="selectedMonth"
          className="browser-default"
          onChange={onMonthChange}
          value={selectedMonth}
        >
          <option value="">Month</option>
          {months?.map((month) => (
            <option value={month.value}>{month.label}</option>
          ))}
        </select>
      </div>
      <div className="col s4">
        <label>Segment</label>
        <select
          id="selectedSegment"
          className="browser-default"
          onChange={onSegmentChange}
          value={selectedSegment}
        >
          <option value="">Segment</option>
          {segments?.map((segment) => (
            <option value={segment.id}>{segment.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
}
