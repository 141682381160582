import React, { Component } from "react";
import * as d3 from "d3";

class SecCompanyChart extends Component {
  componentDidUpdate() {
    // //console.log(this.props);
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
    // //console.log(this.props.dataset)
  }

  max=(data)=>{
    var m = 0;
    for(var i=0;i<data.length;i++){
      if(data[i].nis>m){
        m=data[i].nis
      }
    }
    // //console.log("max", m);
    return m
  }
  min=(data)=>{
    var m = 9999999;
    for(var i=0;i<data.length;i++){
      if(data[i].nis<m){
        m=data[i].nis
      }
    }
    // //console.log("min", m);

    return m
  }
  async drawChart() {
    const {data, companies, primary, can_width, can_height} = this.props
    // //console.log(primary);
    // var dataset = dataset1.concat(dataset2);

    // Calculate Margins and canvas dimensions
    var margin = {top: 40, right: 40, bottom: 40, left: 60},
        width = can_width - margin.left - margin.right,
        height = can_height - margin.top - margin.bottom;
    
    
    d3.select("#svg-element")
    .select("svg")
    .remove();
      
// append the svg object to the body of the page
var svg = d3.select("#svg-element")
  .append("svg")
    .style("background-color", '#ffffff')
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

  // Read the data and compute summary statistics for each specie
  // var data = await d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/iris.csv")
    // //console.log("data", data);
  // Compute quartiles, median, inter quantile range min and max --> these info are then used to draw the box.
  var sumstat = d3.nest() // nest function allows to group the calculation per level of a factor
    .key(function(d) { return d.companyName;})
    .rollup(function(d) {
      // //console.log(d);
      var q1 = d3.quantile([...new Set( d.map(function(g) { return g.nis;}).sort(d3.ascending))],.25)
     var  median = d3.quantile([...new Set( d.map(function(g) { return g.nis;}).sort(d3.ascending))],.5)
      var q3 = d3.quantile([...new Set( d.map(function(g) { return g.nis;}).sort(d3.ascending))],.75)
      var interQuantileRange = q3 - q1
      var min = q1 - 1.5 * interQuantileRange
      var max = q3 + 1.5 * interQuantileRange
      return({q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max})
    })
    .entries(data)

  // Show the X scale
  var x = d3.scaleBand()
    .range([ 0, width ])
    .domain(companies)
    .paddingInner(1)
    .paddingOuter(.5)
  svg.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(x))

  // Show the Y scale
  var y = d3.scaleLinear()
    .domain([this.min(data)-((this.max(data)-this.min(data))*0.2), this.max(data)+((this.max(data)-this.min(data))*0.2)])
    .range([height, 0])
  svg.append("g").call(d3.axisLeft(y))

  svg.append("line")
  .attr("x1", 0)
  .attr("y1", y(0))
  .attr("x2", width)
  .attr("y2", y(0))
  .attr("stroke-width", 1)
  .attr("stroke", "grey");

  // Show the main vertical line
  svg
    .selectAll("vertLines")
    .data(sumstat)
    .enter()
    .append("line")
      .attr("x1", function(d){return(x(d.key))})
      .attr("x2", function(d){return(x(d.key))})
      .attr("y1", function(d){return(y(d.value.min))})
      .attr("y2", function(d){return(y(d.value.max))})
      .attr("stroke", "black")
      .style("width", 40)

  // rectangle for the main box
  var boxWidth = 100
  svg
    .selectAll("boxes")
    .data(sumstat)
    .enter()
    .append("rect")
        .attr("x", function(d){return(x(d.key)-boxWidth/2)})
        .attr("y", function(d){return(y(d.value.q3))})
        .attr("height", function(d){return(y(d.value.q1)-y(d.value.q3))})
        .attr("width", boxWidth )
        .attr("stroke", "black")
        .style("fill", function(d){ return d.key == primary?"#01579B":"orange"})

  // Show the median
  svg
    .selectAll("medianLines")
    .data(sumstat)
    .enter()
    .append("line")
      .attr("x1", function(d){return(x(d.key)-boxWidth/2) })
      .attr("x2", function(d){return(x(d.key)+boxWidth/2) })
      .attr("y1", function(d){return(y(d.value.median))})
      .attr("y2", function(d){return(y(d.value.median))})
      .attr("stroke", "black")
      .style("width", 80)
  }
  

  render() {
    return <div id="svg-element"style={{marginLeft:0}}></div>;
  }
}
export default SecCompanyChart;
