import React, { Component } from "react";
import { connect } from "react-redux";
import { initResponse } from "../../store/action/uploadAction";
import { Redirect } from "react-router-dom";
import moment from "moment";
import M from "materialize-css";
import { getAuditYears, getSameAuditFirm, getSameAuditPartner, submitAudit } from "../../store/action/auditAction";
import './style.css'
import { Timeline } from "../layout/Timeline";
import { filterSens } from "../../help/FilterSens";

class AuditMatter extends Component {
  state = {
    width: 700,
    height: 500,
    clickType:null,
    name:"",
    year:null,
    alerts:[],
    clickTimeline: false,
    newYear: null,
  };

  componentDidMount() {
    var elems = document.querySelectorAll('.modal');
    var instances = M.Modal.init(elems, {});
    document.body.style.overflow = "visible";
    if (
      this.props.initData
      &&this.props.initData.year
    ) {
      this.setState({
        year: this.props.initData.year,
      });
    }

    if(this.props.auditData){
      this.props.GetAuditYears(this.props.auditData.Company.id, this.props.auth.accessToken);
      this.props.GetSameAuditFirm( this.props.auditData.year, this.props.auditData.AuditFirm.id, this.props.auth.accessToken)
      this.props.GetSameAuditPartner(this.props.auditData.year, this.props.auditData.AuditPartner.id, this.props.auth.accessToken)
    }
    if(this.props.auditData){
      var sens = filterSens(this.props.auditData.da_sentences, this.props.auditData.nf_sentences,this.props.auditData.uo_sentences)
      console.log("ALERT FILTER")
      this.setState({alerts:sens})
    }
  }

  componentDidUpdate=()=>{
    if(this.state.clickTimeline&&this.props.auditDataSuccess&&this.props.auditData){
      this.props.GetSameAuditFirm( this.state.newYear, this.props.auditData.AuditFirm.id, this.props.auth.accessToken)
      this.props.GetSameAuditPartner(this.state.newYear, this.props.auditData.AuditPartner.id, this.props.auth.accessToken)
      var sens = filterSens(this.props.auditData.da_sentences, this.props.auditData.nf_sentences,this.props.auditData.uo_sentences)
      console.log("ALERT FILTER")
      this.setState({clickTimeline: false, alerts:sens})
    }
  }

  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };
  onYearChange = (year) => {
    const { auth } = this.props;
    this.setState({ year });
    if (
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.includes(Number(year))
    ) {
      this.props.SubmitPD(
        this.state.companyData,
        year,
        auth.accessToken
      );
    } else {
      alert("Data Not found...");
      // //console.log(this.props.yearData.years, year);
    }
  };

  OnTimeLineClick=(year)=>{
    this.props.SubmitAudit(this.props.auditData.Company.id, year, 'company', {}, this.props.auth.accessToken)
    this.setState({clickTimeline:true, newYear: year})
  }


  render() {
    const { auth, auditData, auditYear, auditSameFirm, auditSamePartner, auditFirms, auditPartners } = this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    console.log("props", this.props);
    const { TopNews } = this.props
      return (
        <div className="container valign-wrapper" style={{width:'90%'}}>
          <div className="modal ar-show-modal" id="ar-show-modal">
            <div className="modal-content">
              <h4><span>Annual Report. </span>{auditData&&auditData.rpt&&<span>Page:{auditData.rpt}</span>}</h4>
              {auditData&&auditData.ar_link&&
              <iframe src={auditData.ar_link} width="100%" height="600px"></iframe>}
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close waves-effect waves-green btn-flat">Agree</a>
            </div>
          </div>
          {auditData?
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  // backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  <h3 className="card-title" style={{ marginTop: 0 }}>
                    <div className="col s12 m10 l10" style={{ marginBottom: 0 }}>
                      <strong style={{ color: "#2d49a6" }}>
                        Audit Report:
                        {auditData
                          ? " " + auditData.Company.name
                          : ""}{" "}
                      </strong>
                    </div>
                    <div className="col s12 m2 l2" style={{ fontSize:12, fontWeight:1000 }}>
                    * All figures in million rupees
                    </div>
                    <div className="col s12 m12 l12" style={{ marginBottom: 0 }}>
                    <table className="pdTableY" style={{marginLeft:0, fontSize:14}}>
                      <thead>
                      {
                        this.props.yearData&&
                        this.props.yearData.years&&
                        this.props.yearData.years.map(year => 
                              <th key={year} style={Number(year)==Number(this.state.year)
                              ?{backgroundColor:"#2d49a6", color:"white"}
                              :{color:"#2d49a6"}}>
                                  <b>{year}</b>
                              </th>
                          )
                      }
                      </thead>
                      <tbody>
                          <tr style={{border:1, borderStyle:"solid", borderColor:"#2d49a6"}}>
                            {
                              this.props.yearData&&
                              this.props.yearData.years&&
                              this.props.yearData.years.map(year => 
                                <td key={year} style={Number(year)==Number(this.state.year)?
                                  {backgroundColor:"#2d49a6", color:"white"}                                
                                  :{color:"#2d49a6"}} id={year}>
                                    <i style={{
                                      cursor:'pointer',
                                    }} onClick={()=>{this.onYearChange(year)}} className="material-icons">chevron_right</i>
                                </td>
                              )
                            }
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </h3>
                  <div className="row">
                    <div className="col s12">
                      <div className="col s3" style={{fontWeight:'bold'}}>Audit Firm</div>
                      <div className="col s3" style={{fontWeight:'bold'}}>Partner</div>
                      <div className="col s2" style={{fontWeight:'bold'}}>Place</div>
                      <div className="col s2" style={{fontWeight:'bold'}}>Date</div>
                      <div className="col s2" style={{fontWeight:'bold'}}>No. of Year</div>
                    </div>
                    <div className="col s12">
                      <div className="col s3">{auditData.AuditFirm.name}</div>
                      <div className="col s3">{auditData.AuditPartner.name}</div>
                      <div className="col s2">{auditData.audit_location}</div>
                      <div className="col s2">{
                        auditData.audit_date
                      }</div>
                      <div className="col s2">{auditYear.filter(ay => ay.audit_firm_id == auditData.audit_firm_id).length}</div>
                    </div>
                  </div>
                  <div className="row"style={{ marginBottom: 0 }}>
                    <div className="col s12 m8 l8" style={{ marginBottom: 0 }}>
                      <div className="col s12 m6 l6" style={{ marginBottom: 0 }}>
                        <h5>Companies with Same partner</h5>
                        <table className="tableFixed">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Complexity Score</th>
                            </tr>
                          </thead>
                          
                          <tbody style={{height:200}}>
                            {
                              auditSamePartner&&auditSamePartner.map(c => 
                                <tr>
                                  <td>{c.Company.name}</td>
                                  <td>
                                    <button
                                      className="center-align"
                                      style={{
                                        width: 70,
                                        height: 30,
                                        marginTop: 5,
                                        marginBottom: 0,
                                        color: "white",
                                        borderRadius: 10,
                                        border: 0,
                                        backgroundColor:
                                          c.score == null
                                            ? "transparent"
                                            : c.score <= 0.3
                                            ? "#0F8305"
                                            : c.score > 0.3 && c.score <= 0.5
                                            ? "coral"
                                            : c.score > 0.5
                                            ? "#DF0101"
                                            : "#99004c",
                                      }}
                                    >
                                      {c.score?Number(c.score).toFixed(3):0}
                                    </button>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className="col s12 m6 l6" style={{ marginBottom: 0 }}>
                        <h5>Companies with Same Audit Firm</h5>
                        <table className="tableFixed">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Complexity Score</th>
                            </tr>
                          </thead>
                          
                          <tbody style={{height:200}}>
                           {
                             auditSameFirm&&auditSameFirm.map(c => 
                              <tr>
                                <td>{c.Company.name}</td>
                                <td>
                                  <button
                                    className="center-align"
                                    style={{
                                      width: 70,
                                      height: 30,
                                      marginTop: 5,
                                      marginBottom: 0,
                                      color: "white",
                                      borderRadius: 10,
                                      border: 0,
                                      backgroundColor:
                                        c.score == null
                                          ? "transparent"
                                          : c.score <= 0.3
                                          ? "#0F8305"
                                          : c.score > 0.3 && c.score <= 0.5
                                          ? "coral"
                                          : c.score > 0.5
                                          ? "#DF0101"
                                          : "#99004c",
                                    }}
                                  >
                                      {c.score?Number(c.score).toFixed(3):0}
                                  </button>
                                </td>
                              </tr>
                             )
                           }
                          </tbody>
                        </table>
                      </div>
                      <div className="col s12 m12 l12" style={{ marginBottom: 0, marginTop: 10 }}
                      >
                        <h5>Audit Alert</h5>
                        {auditData.da_alerts>0|| auditData.uo_alerts>0||auditData.nf_alerts>0?
                          <table className="tableFixed">
                            <thead>
                              <tr>
                                <th>Sentences</th>
                              </tr>
                            </thead>
                            
                            <tbody style={{height:120}}>
                              {
                                this.state.alerts.map(s => 
                                  <tr>
                                    <td style={{padding:5}}>{s}</td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                          :
                          <h6>No Alert</h6>
                          // <table className="tableFixed">
                          //   <thead>
                          //     <tr>
                          //       <th>Sentences</th>
                          //     </tr>
                          //   </thead>
                            
                          //   <tbody style={{height:150}}>
                          //     <tr><td>This may not be a good thing to start</td></tr>
                          //     <tr><td>This may not be a good thing to start</td></tr>
                          //     <tr><td>This may not be a good thing to start</td></tr>
                          //     <tr><td>This may not be a good thing to Start</td></tr>
                          //   </tbody>
                          // </table>
                        }
                      </div>
                    </div>
                    <div
                      className="col s12 m4 l4"
                      style={{ marginBottom: 0, marginTop: 10, height:450, overflowY:'auto' }}
                    >
                      <Timeline 
                        OnClick={this.OnTimeLineClick}
                        data={
                        auditYear?
                        auditYear.sort((a,b) => (b.year - a.year)).map(a => {
                          return {
                            auditFirm:auditFirms.find(af=>af.id == a.audit_firm_id),
                            auditPartner: auditPartners.find(af=>af.id == a.audit_partner_id), 
                            year:a.year
                          }
                        })
                        :[]
                      }/>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          this.props.auditDataLoading&&
          <div className="row center center-align">
            <div className="preloader-wrapper large active">
              <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                  <div className="circle"></div>
              </div><div className="gap-patch">
                  <div className="circle"></div>
              </div><div className="circle-clipper right">
                  <div className="circle"></div>
              </div>
              </div>
            </div>
          </div>
          }
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    auditDataLoading: state.audit.auditDataLoading,
    auditDataSuccess: state.audit.auditDataSuccess,
    auditData: state.audit.auditData? state.audit.auditData.find(d=> d.id==state.audit.auditScoreId):null,
    auth: state.auth,
    initData:state.NIS.initData,
    auditYear: state.audit.auditYearsData,
    TopNews:
      state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,
    auditSamePartner:state.audit.auditSamePartnerData? state.audit.auditSamePartnerData:[],
    auditSameFirm:state.audit.auditSameFirmData? state.audit.auditSameFirmData:[],
    auditFirms: state.audit.auditFirmsData,
    auditPartners: state.audit.auditPartnersData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initResponse: () => {
      dispatch(initResponse());
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    GetSameAuditFirm: ( year, firmId, token) => {
      dispatch(getSameAuditFirm(year, firmId, token));
    },
    GetSameAuditPartner: (year, partnerId, token) => {
      dispatch(getSameAuditPartner(year, partnerId, token));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditMatter);
