import React, { Component } from "react";
import BarChart from "../layout/BarChart";
import { connect } from "react-redux";
import { initResponse, submitPD } from "../../store/action/uploadAction";
import Dashboard from "./Dashboard";
import { Redirect } from "react-router-dom";
import moment from "moment";
import BarChartAlert from "../layout/BarChartAlert";
import AuditModal from "../layout/AuditModal";
import M from "materialize-css";
import News from "../layout/News";
import {
  getAuditYears,
  getSameAuditFirm,
  getSameAuditFirmPartner,
  getSameAuditPartner,
  setAuditReducer,
  submitAudit,
} from "../../store/action/auditAction";
import "./style.css";
import { filterSens } from "../../help/FilterSens";
import { Timeline } from "../layout/Timeline";

class AuditDashboard extends Component {
  state = {
    width: 700,
    height: 500,
    clickType: null,
    name: "",
    year: null,
    alerts: [],
    sameAudit: [],
  };

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, { preventScrolling: false });
    document.body.style.overflow = "visible";
    var elems1 = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(elems1, { position: "bottom" });
    if (this.props.initData && this.props.initData.year) {
      this.setState({
        year: this.props.initData.year,
      });
    }

    if (this.props.auditData) {
      this.props.GetAuditYears(
        this.props.auditData.Company.id,
        this.props.auth.accessToken
      );
    }
    if (this.props.auditData) {
      var sens = filterSens(
        this.props.auditData.da_sentences,
        this.props.auditData.nf_sentences,
        this.props.auditData.uo_sentences
      );
      console.log("ALERT FILTER");
      this.setState({ alerts: sens });
    }
    this.props.SetAuditReducer({ auditDataSuccess: false });
  }
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };
  onYearChange = (year) => {
    const { auth } = this.props;
    this.setState({ year });
    if (
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.includes(Number(year))
    ) {
      this.props.SubmitPD(this.state.companyData, year, auth.accessToken);
    } else {
      alert("Data Not found...");
      // //console.log(this.props.yearData.years, year);
    }
  };

  OnClickAudit = () => {
    var a = this.props.auditData.AuditFirm;
    this.props.GetSameAuditFirm(
      this.props.auditData.year,
      a.id,
      this.props.auth.accessToken
    );
    this.setState({ clickType: "firm", name: a.name });
  };
  OnClickAuditPartner = () => {
    var a = this.props.auditData.AuditPartner;
    this.props.GetSameAuditPartner(
      this.props.auditData.year,
      a.id,
      this.props.auth.accessToken
    );
    this.setState({ clickType: "partner", name: a.name });
  };

  OnTimeLineClick = (year) => {
    this.props.SubmitAudit(
      this.props.auditData.Company.id,
      year,
      "company",
      {},
      this.props.auth.accessToken
    );
  };

  render() {
    const { auth, auditData, auditYear, auditFirms, auditPartners } =
      this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    console.log("props", this.props);
    const { TopNews } = this.props;
    return (
      <div className="container valign-wrapper" style={{ width: "80%" }}>
        <AuditModal
          data={
            this.state.clickType == "firm" && this.props.auditSameFirmData
              ? this.props.auditSameFirmData
              : this.state.clickType == "partner" &&
                this.props.auditSamePartnerData
              ? this.props.auditSamePartnerData
              : []
          }
          type={this.state.clickType}
          name={this.state.name}
          loading={
            this.state.clickType == "firm" &&
            this.props.auditSameFirmDataLoading
              ? this.props.auditSameFirmDataLoading
              : this.state.clickType == "partner" &&
                this.props.auditSamePartnerDataLoading
              ? this.props.auditSamePartnerDataLoading
              : false
          }
        />
        <div className="modal ar-show-modal" id="ar-show-modal">
          <div className="modal-content">
            <h4>
              <span>Annual Report. </span>
              {auditData && auditData.rpt && <span>Page:{auditData.rpt}</span>}
            </h4>
            {auditData && auditData.ar_link && (
              <iframe
                src={auditData.ar_link}
                width="100%"
                height="600px"
              ></iframe>
            )}
          </div>
          <div className="modal-footer">
            <a
              href="#!"
              className="modal-close waves-effect waves-green btn-flat"
            >
              Agree
            </a>
          </div>
        </div>
        {auditData ? (
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  // backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  <h3 className="card-title" style={{ marginTop: 0 }}>
                    <div
                      className="col s12 m10 l10"
                      style={{ marginBottom: 0 }}
                    >
                      <strong style={{ color: "#2d49a6" }}>
                        Audit Report:
                        {auditData ? " " + auditData.Company.name : ""}{" "}
                      </strong>
                    </div>
                    <div
                      className="col s12 m2 l2"
                      style={{ fontSize: 12, fontWeight: 1000 }}
                    >
                      * All figures in million rupees
                    </div>
                    <div
                      className="col s12 m12 l12"
                      style={{ marginBottom: 0 }}
                    >
                      <table
                        className="pdTableY"
                        style={{ marginLeft: 0, fontSize: 14 }}
                      >
                        <thead>
                          {this.props.yearData &&
                            this.props.yearData.years &&
                            this.props.yearData.years.map((year) => (
                              <th
                                key={year}
                                style={
                                  Number(year) == Number(this.state.year)
                                    ? {
                                        backgroundColor: "#2d49a6",
                                        color: "white",
                                      }
                                    : { color: "#2d49a6" }
                                }
                              >
                                <b>{year}</b>
                              </th>
                            ))}
                        </thead>
                        <tbody>
                          <tr
                            style={{
                              border: 1,
                              borderStyle: "solid",
                              borderColor: "#2d49a6",
                            }}
                          >
                            {this.props.yearData &&
                              this.props.yearData.years &&
                              this.props.yearData.years.map((year) => (
                                <td
                                  key={year}
                                  style={
                                    Number(year) == Number(this.state.year)
                                      ? {
                                          backgroundColor: "#2d49a6",
                                          color: "white",
                                        }
                                      : { color: "#2d49a6" }
                                  }
                                  id={year}
                                >
                                  <i
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.onYearChange(year);
                                    }}
                                    className="material-icons"
                                  >
                                    chevron_right
                                  </i>
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </h3>
                  <div className="row">
                    <div className="col s12">
                      <div className="col s3" style={{ fontWeight: "bold" }}>
                        Audit Firm
                      </div>
                      <div className="col s3" style={{ fontWeight: "bold" }}>
                        Partner
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        Place
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        Date
                      </div>
                      <div className="col s2" style={{ fontWeight: "bold" }}>
                        No. of Year
                      </div>
                    </div>
                    <div className="col s12">
                      <div className="col s3">
                        <a
                          onClick={this.OnClickAudit}
                          className="modal-trigger"
                          href="#audit-modal"
                        >
                          {auditData.AuditFirm.name}
                          <i
                            style={{ marginLeft: 10 }}
                            className="material-icons tiny tooltipped"
                            data-position="right"
                            data-tooltip="<p>Click to see companies<br/> with same auditor</p>"
                          >
                            info
                          </i>
                        </a>
                      </div>
                      <div className="col s3">
                        <a
                          onClick={this.OnClickAuditPartner}
                          className="modal-trigger"
                          href="#audit-modal"
                        >
                          {auditData.AuditPartner.name}
                          <i
                            style={{ marginLeft: 10 }}
                            className="material-icons tiny tooltipped"
                            data-position="right"
                            data-tooltip="<p>Click to see companies <br/> with same audit partner</p>"
                          >
                            info
                          </i>
                        </a>
                      </div>
                      <div className="col s2">{auditData.audit_location}</div>
                      <div className="col s2">{auditData.audit_date}</div>
                      <div className="col s2">
                        {
                          auditYear.filter(
                            (ay) => ay.audit_firm_id == auditData.audit_firm_id
                          ).length
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12 m8 l8">
                      <div
                        className="row"
                        // style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        <div className="col s4">
                          <h5>Basic Stats</h5>
                          <hr style={{ borderTopWidth: 2 }} />
                          <div
                            className="row"
                            style={{ height: 200, overflowY: "auto" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <th>Total Assets</th>

                                  <td>
                                    {auditData.total_asset_s
                                      ? auditData.total_asset_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Borrowings</th>

                                  <td>
                                    {auditData.borrowing_s
                                      ? auditData.borrowing_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total Income</th>
                                  <td>
                                    {auditData.total_income_s
                                      ? auditData.total_income_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Profit Before Tax</th>

                                  <td>
                                    {auditData.profit_before_tax_s
                                      ? auditData.profit_before_tax_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Extraordinary Items</th>

                                  <td>
                                    {auditData.extraordinary_items_s
                                      ? auditData.extraordinary_items_s.toFixed(
                                          3
                                        )
                                      : "-"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col s4">
                          <h5>Critical Items</h5>
                          <hr style={{ borderTopWidth: 2 }} />
                          <div
                            className="row"
                            style={{ height: 200, overflowY: "auto" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <th>Allowance</th>
                                  <td>
                                    {auditData.allowance_s
                                      ? auditData.allowance_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Intangibles</th>

                                  <td>
                                    {auditData.intangibles_s
                                      ? auditData.intangibles_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Impairements</th>

                                  <td>
                                    {auditData.imparement_s
                                      ? auditData.imparement_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Goodwill</th>

                                  <td>
                                    {auditData.goodwill_s
                                      ? auditData.goodwill_s.toFixed(3)
                                      : "-"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col s4">
                          <div className="row">
                            <div className="col s8">
                              <p>
                                <h5>
                                  <strong>Audit Fees</strong>
                                </h5>
                                <p>{auditData.audit_fees}</p>
                              </p>
                              <p>
                                <h5>
                                  <strong>Complexity Score</strong>
                                </h5>
                                <p>
                                  {auditData.score
                                    ? Number(auditData.score).toFixed(3)
                                    : 0}
                                </p>
                              </p>
                            </div>
                            <div className="col s4">
                              <h5>
                                RPT
                                <a
                                  className="waves-effect waves-light modal-trigger"
                                  href="#ar-show-modal"
                                  style={{ backgroundColor: "#2d49a6" }}
                                >
                                  <img
                                    src="assets/img/audit-monitor.png"
                                    className="img-fluid"
                                    alt=""
                                    width="100"
                                    height="100"
                                    id="audit-monitor"
                                  />
                                </a>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {
                          <div
                            className="col s12 m12 l6"
                            style={{ marginBottom: 0 }}
                          >
                            <h5>Audit Alerts</h5>
                            <hr style={{ borderTopWidth: 2 }} />
                            {auditData.da_alerts > 0 ||
                            auditData.uo_alerts > 0 ||
                            auditData.nf_alerts > 0 ? (
                              <BarChartAlert
                                data={[
                                  {
                                    name: "",
                                    color: "",
                                    High: parseFloat(auditData.da_alerts),
                                    Medium: parseFloat(auditData.uo_alerts),
                                    Sentiment: parseFloat(auditData.nf_alerts),
                                  },
                                ]}
                                dimensions={[{ name: "name" }]}
                                measures={[
                                  { name: "High" },
                                  { name: "Medium" },
                                  { name: "Sentiment" },
                                ]}
                                height="180"
                                width="430"
                              />
                            ) : (
                              <h6>No alerts</h6>
                            )}
                          </div>
                        }
                        {auditData.da_alerts > 0 ||
                        auditData.uo_alerts > 0 ||
                        auditData.nf_alerts > 0 ? (
                          <div
                            className="col s12 m6 l6"
                            style={{ marginBottom: 0, marginTop: 10 }}
                          >
                            <table className="tableFixed">
                              <thead>
                                <tr>
                                  <th>Sentences</th>
                                </tr>
                              </thead>

                              <tbody style={{ height: 200 }}>
                                {this.state.alerts.map((s) => (
                                  <tr>
                                    <td style={{ padding: 5, paddingLeft: 10 }}>
                                      {s}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="col s12 m4 l4"
                      style={{
                        marginBottom: 0,
                        marginTop: 10,
                        height: 500,
                        overflowY: "auto",
                      }}
                    >
                      <Timeline
                        OnClick={this.OnTimeLineClick}
                        data={
                          auditYear
                            ? auditYear
                                .sort((a, b) => b.year - a.year)
                                .map((a) => {
                                  return {
                                    auditFirm: auditFirms.find(
                                      (af) => af.id == a.audit_firm_id
                                    ),
                                    auditPartner: auditPartners.find(
                                      (af) => af.id == a.audit_partner_id
                                    ),
                                    year: a.year,
                                  };
                                })
                            : []
                        }
                      />
                    </div>
                  </div>
                  {/* <p><strong>News Alert</strong></p>
                    <News
                      height={300}
                      marginTop={10}
                      TopNews={TopNews}
                      component="my_dashboard"
                      textColor="white"
                    /> */}
                </div>

                {/* <button type="button" className="btn btn-success btn-block" onClick={this.handleBack} style={{marginTop:10,backgroundColor:'#23431b'}} >Back to Home</button>  */}
              </div>
            </div>
          </div>
        ) : (
          this.props.auditDataLoading && (
            <div className="row center center-align">
              <div className="preloader-wrapper large active">
                <div className="spinner-layer spinner-blue-only">
                  <div className="circle-clipper left">
                    <div className="circle"></div>
                  </div>
                  <div className="gap-patch">
                    <div className="circle"></div>
                  </div>
                  <div className="circle-clipper right">
                    <div className="circle"></div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // //console.log(state);
  return {
    auditDataLoading: state.audit.auditDataLoading,
    auditData: state.audit.auditData
      ? state.audit.auditData.find((d) => d.id == state.audit.auditScoreId)
      : null,
    auth: state.auth,
    initData: state.NIS.initData,
    auditYear: state.audit.auditYearsData,
    TopNews:
      state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,
    auditFirms: state.audit.auditFirmsData,
    auditPartners: state.audit.auditPartnersData,

    auditSameFirmDataLoading: state.audit.auditSameFirmDataLoading,
    auditSameFirmDataSuccess: state.audit.auditSameFirmDataSuccess,
    auditSameFirmDataError: state.audit.auditSameFirmDataError,
    auditSameFirmData: state.audit.auditSameFirmData,

    auditSamePartnerDataLoading: state.audit.auditSamePartnerDataLoading,
    auditSamePartnerDataSuccess: state.audit.auditSamePartnerDataSuccess,
    auditSamePartnerDataError: state.audit.auditSamePartnerDataError,
    auditSamePartnerData: state.audit.auditSamePartnerData,

    auditDataSuccess: state.audit.auditDataSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initResponse: () => {
      dispatch(initResponse());
    },
    SubmitPD: (company, year, token) => {
      dispatch(submitPD(company, year, token));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    GetSameAuditFirm: (year, id, token) => {
      dispatch(getSameAuditFirm(year, id, token));
    },
    GetSameAuditPartner: (year, id, token) => {
      dispatch(getSameAuditPartner(year, id, token));
    },
    SetAuditReducer: (data) => {
      dispatch(setAuditReducer(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditDashboard);
