import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getQuery } from "../../../utils/nlHelper";
import {
  Backdrop,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  fetchRowColumns,
  setReducer,
  updateNLRowColumns,
} from "../../../store/action/nlFixAction";
import styled from "@emotion/styled";
import swal from "sweetalert";
import NLRowColEditModal from "./NLRowColEditModal";
import NLRowColAddModal from "./NLRowColAddModal";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: "nowrap",
  padding: "6px 10px",
  border: "1px solid black",
}));

const editColor = "#d9e7ff";
const addColor = "#adffb1";
const reorderColor = "#e0cf5c";

export default function NLExcelStructure() {
  const dispatch = useDispatch();
  const location = useLocation();

  const search = getQuery(location?.search);
  const { nlid, cid, company, nl } = search;

  const accessToken = useSelector((state) => state.auth?.accessToken);

  const nlRowColSuccess = useSelector((state) => state?.nlFix?.nlRowColSuccess);
  const nlRowColData = useSelector((state) => state?.nlFix?.nlRowColData);
  const nlRowColError = useSelector((state) => state?.nlFix?.nlRowColError);

  const nlRowColUpdateSuccess = useSelector(
    (state) => state?.nlFix?.nlRowColUpdateSuccess
  );
  const nlRowColUpdateError = useSelector(
    (state) => state?.nlFix?.nlRowColUpdateError
  );
  const nlRowColUpdateMessage = useSelector(
    (state) => state?.nlFix?.nlRowColUpdateMessage
  );

  const [triggerRerender, setTriggerRerender] = useState(0);

  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [editCol, setEditCol] = useState(false);
  const [colData, setColData] = useState(null);
  const [editRow, setEditRow] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [addCol, setAddCol] = useState(false);
  const [addRow, setAddRow] = useState(false);
  const [actionIndex, setActionIndex] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [addOnDB, setAddOnDB] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setColumns(null);
    setRows(null);
    if (cid) {
      dispatch(fetchRowColumns(nlid, { companyId: cid }, accessToken));
    } else {
      dispatch(fetchRowColumns(nlid, {}, accessToken));
    }
  }, []);

  useEffect(() => {
    if (nlRowColSuccess) {
      dispatch(setReducer({ nlRowColSuccess: false }));
      if (nlRowColData?.rows) setRows(nlRowColData?.rows);
      if (nlRowColData?.columns) setColumns(nlRowColData?.columns);
    }
  }, [nlRowColSuccess]);

  useEffect(() => {
    if (nlRowColError) {
      swal("Oops!", "Failed to fetch NL details", "error");
      dispatch(setReducer({ nlRowColError: false }));
    }
  }, [nlRowColError]);

  useEffect(() => {
    if (nlRowColUpdateSuccess && reload) {
      setReload(false);
      swal("Success", "Updated successfully", "success");
      if (cid) {
        dispatch(fetchRowColumns(nlid, { companyId: cid }, accessToken));
      } else {
        dispatch(fetchRowColumns(nlid, {}, accessToken));
      }
      dispatch(setReducer({ nlRowColUpdateSuccess: false }));
    }
  }, [nlRowColUpdateSuccess]);

  useEffect(() => {
    if (nlRowColUpdateError) {
      swal(
        "Oops!",
        nlRowColUpdateMessage || "Error to update rows and columns",
        "error"
      );
      dispatch(setReducer({ nlRowColUpdateError: false }));
    }
  }, [nlRowColUpdateError]);

  useEffect(() => {
    if (addOnDB) {
      setAddOnDB(false);
      update(null, false);
    }
  }, [addOnDB]);

  const onSubmitRow = (e, data) => {
    let locRows = rows;
    let index = locRows.findIndex((f) => f.id === data.id);
    let edit = data["edit"] || false;
    for (let i = 0; i < data?.alternatives?.length; i++) {
      if (
        data?.alternatives[i].add ||
        data?.alternatives[i].delete ||
        data?.alternatives[i].edit
      )
        edit = true;
    }
    if (index !== null && index != undefined) {
      locRows[index] = data;
      locRows[index]["edit"] = edit;
      setRows(locRows);
    }
    setEditRow(false);
    setAddOnDB(true);
  };

  const onSubmitCol = (e, data) => {
    let locCols = columns;
    let index = locCols.findIndex((f) => f.id === data.id);
    let edit = data["edit"] || false;
    for (let i = 0; i < data?.alternatives?.length; i++) {
      if (
        data?.alternatives[i].add ||
        data?.alternatives[i].delete ||
        data?.alternatives[i].edit
      )
        edit = true;
    }
    if (index !== null && index != undefined) {
      locCols[index] = data;
      locCols[index]["edit"] = edit;
      setColumns(locCols);
    }
    setEditCol(false);
    setAddOnDB(true);
  };

  const onAddRow = (e, data) => {
    let locRows = rows;
    let locData = { ...data, id: `custom${rows?.length}`, add: true };
    if (locData.order === undefined || locData.order === null) {
      const maxOrder = locRows.reduce(
        (max, item) => (item.order > max ? item.order : max),
        locRows[0].order
      );
      locData.order = maxOrder + 1;
    } else {
      for (let i = 0; i < locRows.length; i++) {
        if (parseInt(locRows[i].order) >= parseInt(locData.order)) {
          locRows[i].order = parseInt(locRows[i].order) + 1;
          locRows[i].reorder = true;
        }
      }
    }
    locRows.push(locData);
    setRows(locRows);
    setAddRow(false);
    setAddOnDB(true);
  };

  const onAddCol = (e, data) => {
    let locCol = columns;
    let locData = { ...data, id: `custom${rows?.length}`, add: true };
    if (locData.order === undefined || locData.order === null) {
      const maxOrder = locCol.reduce(
        (max, item) => (item.order > max ? item.order : max),
        locCol[0].order
      );
      locData.order = maxOrder + 1;
    } else {
      for (let i = 0; i < locCol.length; i++) {
        if (parseInt(locCol[i].order) >= parseInt(locData.order)) {
          locCol[i].order = parseInt(locCol[i].order) + 1;
          locCol[i].reorder = true;
        }
      }
    }
    locCol.push(locData);
    setColumns(locCol);
    setAddCol(false);
    setAddOnDB(true);
  };

  const moveNext = (e, item, type) => {
    let locData = null;
    if (type === "row") {
      locData = rows;
    } else {
      locData = columns;
    }
    const maxOrder = locData.reduce(
      (max, item) => (item.order > max ? item.order : max),
      locData[0].order
    );
    if (maxOrder === item.order) return;
    let currentOrder = item.order;
    let nextOrder = locData
      .filter((item) => item.order > currentOrder)
      .reduce((min, item) => (item.order < min ? item.order : min), Infinity);
    let currentItemIndex = locData.findIndex((f) => f.order === item.order);
    let nextItemIndex = locData.findIndex(
      (f) => parseInt(f.order) === nextOrder
    );
    locData[currentItemIndex]["order"] = nextOrder;
    locData[currentItemIndex]["reorder"] = true;
    locData[nextItemIndex]["order"] = currentOrder;
    locData[nextItemIndex]["reorder"] = true;
    if (type === "row") {
      setRows(locData);
      setEditRow(false);
    } else {
      setColumns(locData);
      setEditCol(false);
    }
    setAddOnDB(true);
    setTriggerRerender((prev) => prev + 1);
  };

  const movePrev = (e, item, type) => {
    let locData = null;
    if (type === "row") {
      locData = rows;
    } else {
      locData = columns;
    }
    const minOrder = locData.reduce(
      (min, item) => (item.order < min ? item.order : min),
      locData[0].order
    );
    if (item.order === minOrder) return;
    let currentOrder = item.order;
    let prevOrder = locData
      .filter((item) => item.order < currentOrder)
      .reduce((max, item) => (item.order > max ? item.order : max), -Infinity);
    let currentItemIndex = locData.findIndex((f) => f.order === item.order);
    let prevItemIndex = locData.findIndex(
      (f) => parseInt(f.order) === prevOrder
    );
    locData[currentItemIndex]["order"] = prevOrder;
    locData[currentItemIndex]["reorder"] = true;
    locData[prevItemIndex]["order"] = currentOrder;
    locData[prevItemIndex]["reorder"] = true;
    if (type === "row") {
      setRows(locData);
      setEditRow(false);
    } else {
      setColumns(locData);
      setEditCol(false);
    }
    setAddOnDB(true);
    setTriggerRerender((prev) => prev + 1);
  };

  const update = (e, force = true) => {
    if (force) {
      setReload(true);
    }
    let locRows = [...rows];
    for (let i = 0; i < locRows.length; i++) {
      if (!locRows[i].hasOwnProperty("add")) locRows[i]["add"] = false;
      if (!locRows[i].hasOwnProperty("edit")) locRows[i]["edit"] = false;
      if (!locRows[i].hasOwnProperty("reorder")) locRows[i]["reorder"] = false;
    }
    let locCols = [...columns];
    for (let i = 0; i < locCols.length; i++) {
      if (!locCols[i].hasOwnProperty("add")) locCols[i]["add"] = false;
      if (!locCols[i].hasOwnProperty("edit")) locCols[i]["edit"] = false;
      if (!locCols[i].hasOwnProperty("reorder")) locCols[i]["reorder"] = false;
    }
    dispatch(
      updateNLRowColumns(nlid, { rows: locRows, columns: locCols }, accessToken)
    );
    // setColumns(null);
    // setRows(null);
  };

  console.log("row", rows);
  console.log("col", columns);

  return (
    <>
      <NLRowColEditModal
        show={editCol}
        data={colData}
        onClose={(e) => {
          setEditCol(false);
        }}
        onSubmit={onSubmitCol}
        dimension="column"
        companyId={cid}
      />
      <NLRowColEditModal
        show={editRow}
        data={rowData}
        onClose={(e) => {
          setEditRow(false);
        }}
        onSubmit={onSubmitRow}
        dimension="row"
        companyId={cid}
      />
      <NLRowColAddModal
        show={addCol}
        onClose={(e) => {
          setAddCol(false);
        }}
        onSubmit={onAddCol}
        currentOrder={currentOrder}
        dimension="column"
      />
      <NLRowColAddModal
        show={addRow}
        onClose={(e) => {
          setAddRow(false);
        }}
        onSubmit={onAddRow}
        currentOrder={currentOrder}
        dimension="row"
      />
      {rows && columns ? (
        <>
          <div className="row" style={{ paddingTop: "7px" }}>
            <div className="col s6" style={{ textAlign: "right" }}>
              <span style={{ fontWeight: "500" }}>{`${
                company ? company : "All NL"
              } - ${nl}`}</span>
            </div>
            <div className="col s6">
              <Button className="right" variant="contained" onClick={update}>
                Update on database
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div style={{ display: "flex", alignItems: "center" }}>
                <TableContainer component={Paper} style={{ maxHeight: 475 }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <CustomTableCell style={{ maxWidth: "370px" }}>
                          Actions
                        </CustomTableCell>
                        <CustomTableCell style={{ maxWidth: "370px" }}>
                          Order
                        </CustomTableCell>
                        <CustomTableCell
                          style={{ maxWidth: "370px" }}
                        ></CustomTableCell>
                        {columns
                          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                          .map((item, index) => (
                            <CustomTableCell key={item.id}>
                              {item.order}
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={() => {
                                  setCurrentOrder(item.order);
                                  setAddCol(true);
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>add</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="edit"
                                onClick={(e) => {
                                  setColData(item);
                                  setEditCol(true);
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>edit</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="Move up"
                                onClick={(e) => {
                                  movePrev(e, item, "column");
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>west</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="Move down"
                                onClick={(e) => {
                                  moveNext(e, item, "column");
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>east</Icon>
                              </IconButton>
                            </CustomTableCell>
                          ))}
                      </TableRow>
                      <TableRow>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell
                          style={{ maxWidth: "370px" }}
                        ></CustomTableCell>
                        <CustomTableCell style={{ maxWidth: "370px" }}>
                          Row
                        </CustomTableCell>
                        {columns
                          ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                          .map((item, index) => (
                            <CustomTableCell
                              key={item.id}
                              style={{
                                backgroundColor: item.edit
                                  ? editColor
                                  : item.add
                                    ? addColor
                                    : item.reorder
                                      ? reorderColor
                                      : "",
                              }}
                              onClick={(e) => {
                                if (actionIndex) {
                                  setActionIndex(null);
                                } else {
                                  setActionIndex({
                                    type: "column",
                                    index: index,
                                  });
                                }
                              }}
                            >
                              {item.lable}
                            </CustomTableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .sort((a, b) => (a.order > b.order ? 1 : -1))
                        .map((row_item, row_index) => (
                          <TableRow key={row_item.id}>
                            <CustomTableCell>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="add"
                                onClick={() => {
                                  setCurrentOrder(row_item.order);
                                  setAddRow(true);
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>add</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="edit"
                                onClick={(e) => {
                                  setRowData(row_item);
                                  setEditRow(true);
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>edit</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="Move up"
                                onClick={(e) => {
                                  movePrev(e, row_item, "row");
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>north</Icon>
                              </IconButton>
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="Move down"
                                onClick={(e) => {
                                  moveNext(e, row_item, "row");
                                }}
                              >
                                <Icon style={{ fontSize: "15px" }}>south</Icon>
                              </IconButton>
                            </CustomTableCell>
                            <CustomTableCell>{row_item.order}</CustomTableCell>
                            <CustomTableCell
                              style={{
                                backgroundColor: row_item.edit
                                  ? editColor
                                  : row_item.add
                                    ? addColor
                                    : row_item.reorder
                                      ? reorderColor
                                      : "",
                              }}
                              onClick={(e) => {
                                if (actionIndex) {
                                  setActionIndex(null);
                                } else {
                                  setActionIndex({
                                    type: "row",
                                    index: row_index,
                                  });
                                }
                              }}
                            >
                              {row_item.lable}
                              {row_item.company && (
                                <>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: 900,
                                    }}
                                  >
                                    {row_item.company.name}
                                  </span>
                                </>
                              )}
                            </CustomTableCell>
                            {columns
                              ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                              .map((col_item, col_index) => (
                                <CustomTableCell
                                  style={{ maxWidth: "370px" }}
                                  key={col_item.id}
                                  onClick={() => {
                                    setActionIndex(null);
                                  }}
                                ></CustomTableCell>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}
