const initState = { 
    success: false, // make true when auth succdessful
    loading: false,
    error:'',
    transcript:null
  };
  
  const TranscriptReducer = (state = initState, action) => {
    switch (action.type) {
      case 'LOADING_TRANSCRIPT_DATA':
        return {
          ...state,
          loading: true,
          success: false,
          error:'',
          transcript:null,
        };

      case 'TRANSCRIPT_DATA_FETCH_SUCCESS':
        return {
          ...state,
          loading: false,
          success: true,
          error:"",
         transcript:action.payload
        }
    case 'TRANSCRIPT_DATA_FETCH_ERROR':
        return {
        ...state,
        loading: false,
        success: false,
        error:'fetch failed',
        transcript:null,
        };
    case 'INITIAL_STATE':
        return initState
    case 'HOME_CLICK':
        return initState
    default:
        return state;
    }
  };
  
  export default TranscriptReducer;
  