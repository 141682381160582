import React from "react";
import "./style.css"

export const Timeline = ({data, OnClick}) => {
  return (
    <div style={{marginRight:50}}>
        <div className="timeline">
        {
            data.map((d, i) =>
            d&&
            <div className="container-timeline timeline-left" onClick={()=>{OnClick(d.year)}}>
                <div className="content-timeline" 
                style={
                  i<data.length-1?
                    d?.auditFirm?.id != data[i+1]?.auditFirm?.id
                    ?{backgroundColor:'#2d49a6', color:'white'}
                    :{}
                  :{}
                } >
                    {i<data.length-1?
                        d?.auditFirm?.id != data[i+1]?.auditFirm?.id
                        ?<p>Auditor Change</p>
                        :null
                      :null}
                    <h5>{d.year}</h5>
                    <p>{d?.auditFirm?.name}</p>
                    <p>{d?.auditPartner?.name}</p>
                </div>
            </div>
            )
        }
        </div> 
    </div>
    
  );
};
