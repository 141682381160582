import React from "react";
import GeoChart from "./laylout/GeoChart";
import { useState } from "react";
import { useEffect } from "react";
import {
  getReportDataFiltered,
  getReportDataSegment,
} from "../../../utils/nlHelper";
import { NL_TYPE_GIC } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { getStatePop } from "../../../store/action/nlActions";

export default function GeoDashboard({ label, data, type = NL_TYPE_GIC }) {
  const accessToken = useSelector((state) => state?.auth?.accessToken);
  const states = useSelector((state) => state?.nl?.states);
  const [reportData, setReportData] = useState(null);
  const [segments, setSegments] = useState([]);
  const [selSegment, setSelSegment] = useState("Overall");
  const [tableData, setTableData] = useState(null);
  const [selState, setSelState] = useState(null);
  const [capitaFlag, setCapitaFlag] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatePop({}, accessToken));
  }, []);

  useEffect(() => {
    if (data && data?.aggregate && data.segments) {
      setReportData(data?.aggregate);
      const overall_col = type === NL_TYPE_GIC ? "Overall" : "Premium (Total)";

      let segs = Object.keys(data.segments).map((segment) => {
        return segment;
      });
      segs = segs?.filter((seg) => seg !== overall_col);
      setSegments([overall_col, ...segs]);
      console.log("data?.aggregate[1][1]", data?.aggregate[1][0]);
      setSelSegment(overall_col);
      setTableData(
        getReportDataFiltered(
          overall_col,
          data?.aggregate[1][0],
          data.segments,
          capitaFlag,
          states,
        ),
      );
      setSelState(data?.aggregate[1][0]);
    }
  }, [data]);

  useEffect(() => {
    if (data && data?.aggregate && data.segments && selSegment && selState) {
      let temp = getReportDataSegment(
        data.segments[selSegment],
        capitaFlag,
        states,
      );
      setReportData(temp);
      setTableData(
        getReportDataFiltered(
          selSegment,
          selState,
          data.segments,
          capitaFlag,
          states,
        ),
      );
    }
  }, [capitaFlag]);

  const OnStateSelect = (state) => {
    let temp = state[0] === "Orissa" ? "Odisha" : state[0];
    setSelState(temp);
    setTableData(
      getReportDataFiltered(
        selSegment,
        temp,
        data.segments,
        capitaFlag,
        states,
      ),
    );
  };

  const OnSetSegment = (segment) => {
    setSelSegment(segment);
    let temp = getReportDataSegment(data.segments[segment], capitaFlag, states);
    setReportData(temp);
    setTableData(
      getReportDataFiltered(
        segment,
        selState,
        data.segments,
        capitaFlag,
        states,
      ),
    );
  };

  const handleSwitch = () => {
    setCapitaFlag(!capitaFlag);
  };

  // console.log("REP DATA", reportData);
  return (
    <>
      <div className="row">
        <div className="col s2">
          <table className="tableFixed">
            <thead>
              <tr>
                <th>Segments</th>
              </tr>
            </thead>
            <tbody style={{ height: 460 }}>
              {segments?.map((segment) => (
                <tr
                  onClick={() => {
                    OnSetSegment(segment);
                  }}
                >
                  <td
                    style={
                      segment === selSegment
                        ? { backgroundColor: "#dcdce2", cursor: "pointer" }
                        : { cursor: "pointer" }
                    }
                  >
                    {segment}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col s7">
          {/* <div className="col s6 switch">
            <label>
              None
              <input type="checkbox" onChange={handleSwitch} />
              <span class="lever"></span>
              Per Capita
            </label>
          </div>
          <div className="col s6"> */}
          {selState && (
            <p className="right right-align" style={{ marginBottom: 0 }}>
              <strong>State: {selState}</strong>
            </p>
          )}
          {/* </div> */}
          <GeoChart
            key={capitaFlag ? "CapitaChart" : "RevChart"}
            height={460}
            width="100%"
            data={reportData || []}
            ChartSelect={OnStateSelect}
          />
        </div>
        <div className="col s3">
          {tableData && (
            <table className="tableFixed">
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Revenue</th>
                </tr>
              </thead>
              <tbody style={{ height: 460 }}>
                {tableData?.map((td) => (
                  <tr>
                    <td title={td?.company}>
                      {td?.company?.length > 12
                        ? td.company.slice(0, 12) + "..."
                        : td.company}
                    </td>
                    <td className="right right-align">
                      {td.revenue.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <p className="notes-text">
            ** Initially the state with highest revenue is selected and company
            shares are shown in right panel
          </p>
        </div>
      </div>
    </>
  );
}
