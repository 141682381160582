import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxHeight: 570,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function NLCommonEditModal({ show, value, onClose, onSubmit }) {
  const [localValue, setLocalValue] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onChange = (e) => {
    setLocalValue(e.target.value);
  };

  const onLocalSubmit = (e) => {
    e.preventDefault();
    onSubmit(localValue);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form>
          <div className="row">
            <div className="col s12">
              <input value={localValue} onChange={onChange} />
            </div>
            <div className="col s12">
              <Button onClick={onLocalSubmit}>Submit</Button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
