import React from 'react'
import papers from '../../config/researchs'
export default function PapersDownload() {
    return (
        <div id="modal-papers" className="modal">
            <div className="modal-content">
                <h4>Research Papers</h4>
                <div className="row">
                    <div className="col s4">
                        <a href={papers.PD}  rel="noopener noreferrer" target="_blank">
                            <div
                            style={{
                                height:150,
                                margin:10,
                                padding:10,
                                backgroundColor: "#2d49a6",
                                textAlign: "center",
                                boxShadow:
                                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            }}
                            >
                                <img
                                src="assets/img/analytics-white.png"
                                className="img-fluid"
                                alt=""
                                width="100"
                                height="100"
                                id="distress predictor"
                                />
                                <p className="icon-text">Distress Predictor</p>
                                
                            </div>
                        </a>
                    </div>
                    <div className="col s8">
                        <p>
                            It uses textual information from corporate annual
                                reports to predict distress (PD) and finds that PD has 
                                a predictive power for eventual downgrade/ upgrade in credit ratings
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col s4">
                        <a href={papers.NIS}  rel="noopener noreferrer" target="_blank">
                            <div
                            style={{
                                height:150,
                                margin:10,
                                padding:10,
                                backgroundColor: "#109ea3",
                                ttextAlign: "center",
                                boxShadow:
                                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                            }}
                            >
                                <img
                                src="assets/img/bar-chart-white.png"
                                className="img-fluid"
                                alt=""
                                width="100"
                                height="100"
                                id="news impact"
                                />
                                <p className="icon-text">News Impact </p>
                            </div>
                        </a>
                    </div>
                    <div className="col s8">
                        <p>
                        The news impact score (NIS) uses newspaper reports to estimate
                        corporate stress levels. There is a strong negative correlation
                        between NIS and stock returns. Higher NIS is followed by greater
                        volatility in stock returns.
                        </p>
                    </div>
                    
                </div>
            </div>
            <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-green btn-flat">Close</a>
            </div>
        </div>
    )
}
