import {
  Box,
  Button,
  Icon,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NLRowColCompanyEditTab from "./NLRowColCompanyEditTab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  minHeight: 570,
  maxHeight: 570,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export default function NLRowColEditModal({
  show,
  data,
  onClose,
  onSubmit,
  dimension = "row",
  companyId
}) {
  const [open, setOpen] = React.useState(false);

  const [localData, setLocalData] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const deleteAlternative = (e, item) => {
    let alternatives = localData?.alternatives;
    let index = alternatives?.findIndex((f) => f.id === item.id);
    if (index != null && index != undefined) {
      if (alternatives[index]["add"]) {
        alternatives.splice(index, 1);
      } else {
        alternatives[index]["delete"] = true;
      }
      setLocalData({ ...localData, alternatives: alternatives });
    }
  };

  const undoDeleteAlternative = (e, item) => {
    let alternatives = localData?.alternatives;
    let index = alternatives?.findIndex((f) => f.id === item.id);
    if (index != null && index != undefined) {
      alternatives[index]["delete"] = false;
      setLocalData({ ...localData, alternatives: alternatives });
    }
  };

  const addNewAlternative = (e, item) => {
    let alternatives = localData?.alternatives || [];
    alternatives.push({
      id: "custom" + alternatives.length,
      name: "",
      add: true,
      nlRowColId: localData.id,
    });
    setLocalData({ ...localData, alternatives: alternatives });
  };

  const onChangeAlternative = (e, item) => {
    let alternatives = localData?.alternatives;
    let index = alternatives.findIndex((f) => f.id === item.id);
    if (index !== null && item !== undefined) {
      alternatives[index]["name"] = e.target.value;
      alternatives[index]["edit"] = true;
      setLocalData({ ...localData, alternatives: alternatives });
    }
  };

  const onChange = (e) => {
    setLocalData({
      ...localData,
      [e.target.id]: e.target.value,
      edit: true,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          aria-label="Tabs"
        >
          <Tab label="Row/Col Details" />
          {dimension === "row" && localData?.add !== true && (
            <Tab label="Comapnies" />
          )}
        </Tabs>
        <div hidden={tabValue !== 0}>
          <form>
            <div className="row">
              <Button
                variant="contained"
                className="right"
                onClick={(e) => onSubmit(e, localData)}
              >
                Submit
              </Button>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="name">Name</label>
                <input id="name" value={localData?.name} onChange={onChange} />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="lable">Label</label>
                <input
                  id="lable"
                  value={localData?.lable}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <label htmlFor="order">Order</label>
                <input
                  id="order"
                  type="number"
                  value={localData?.order}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label htmlFor="order">Alternatives</label>
                <div className="row">
                  <Button
                    variant="text"
                    onClick={(e) => {
                      addNewAlternative(e);
                    }}
                  >
                    Add Alternative
                  </Button>
                </div>
                {localData?.alternatives?.map((item, index) => (
                  <div className="row" key={item.id}>
                    <div className="col s6">
                      <input
                        id={item.id}
                        value={item.name}
                        onChange={(e) => onChangeAlternative(e, item)}
                      />
                    </div>
                    <div className="col s2">
                      {!item.delete ? (
                        <Button
                          variant="text"
                          style={{ marginTop: "15px" }}
                          onClick={(e) => deleteAlternative(e, item)}
                        >
                          delete
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          style={{ marginTop: "15px" }}
                          onClick={(e) => undoDeleteAlternative(e, item)}
                        >
                          Undo delete
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <Button
                variant="contained"
                className="right"
                onClick={(e) => onSubmit(e, localData)}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
        <div hidden={tabValue !== 1}>
          <NLRowColCompanyEditTab
            dimension={dimension}
            data={data}
            companyId={companyId}
          />
        </div>
      </Box>
    </Modal>
  );
}
