import React, { Component } from "react";
import * as d3 from "d3";

class BarChart extends Component {
  componentDidUpdate() {
    this.drawChart();
  }
  componentDidMount() {
    this.drawChart();
  }

  // drawChart() {
  //   const {width, height} = this.props;
  //   const data = this.props.data;

  //   const rectWidth = width / data.length;
  //   const rectHeightForOne = height/Math.max(...data);

  //   //comment

  //   const svg = d3
  //     .select("#svg-element")
  //     .append("svg")
  //     .attr("width", '1000px')
  //     .attr("height", '500px')
  //   svg
  //     .selectAll("rect")
  //     .data(data)
  //     .enter()
  //     .append("rect")
  //     .attr("x", (d, i) => i * (rectWidth + 5))
  //     .attr("y", (d, i) => height - rectHeightForOne * d)
  //     .attr("width", rectWidth)
  //     .attr("height", (d, i) => d *rectHeightForOne)
  //     .attr("fill", "#795C5F");
  // }
  drawChart() {
    var axisMargin = {
      top: 10,
      right: 30,
      bottom: 0,
      left: 5
    };

    const { width, height, data, dimensions, measures, filters } = this.props;

    if (!data || !Object.keys(data).length) return;
    // height = height - axisMargin.top - axisMargin.bottom;
    // width = width - axisMargin.right - axisMargin.left;

    if (
      !dimensions ||
      dimensions.length == 0 ||
      !measures ||
      measures.length == 0
    )
      return;

    const dims = [dimensions[0].name]; //only 1 dimension assumed
    const meas = measures.map(x => x.name);
    // //console.log("MMM",meas)

    // Scale X and Y
    var x = d3.scaleLinear().rangeRound([0, width - 130]),
      y = d3
        .scaleBand()
        .rangeRound([0, height])
        .paddingInner(0.1),
      z = d3.scaleOrdinal(d3.schemeCategory10),
      yGrp = d3.scaleBand().padding(0.05);
    
    x.domain([
      Math.min(
        0,
        d3.min(data, function(d) {
          return d3.min(meas, function(mea) {
            return d[mea];
          });
        })
      ),
      d3.max(data, function(d) {
        return d3.max(meas, function(mea) {
          return d[mea];
        });
      })
    ]).nice();

    y.domain(
      data.map(function(d) {
        return d[dims[0]];
      })
    );
    yGrp.domain(meas).rangeRound([0, y.bandwidth()]);
    z.domain(meas).range(['#23431b','#DF0101']);
   
    //tooltip
    // var numFormatter = d3.format(".2f");
    // var tip = d3.tip().attr('class', 'd3-tip').offset([ -10, 0 ]).html(function(d) {
    // 	return "<strong>" + d.key + ": </strong> <span>" + numFormatter(d.value) + "</span>";
    // });

    // this.addToolTip(tip);
    d3.select("#svg-element")
      .select("svg")
      .remove();

    var g = d3
      .select("#svg-element")
      .append("svg")
      .attr("width", "500px")
      .attr("height", "220px")
      .attr("class", "bar-chart")
      .append("g")
      .attr(
        "transform",
        "translate(" + axisMargin.left + "," + axisMargin.top + ")"
      )

    g.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function(d) {
        return "translate(0, " + y(d[dims[0]]) + ")";
      })
      .selectAll("rect")
      .data(function(d) {
        return meas.map(function(mea) {
          return { key: mea, value: d[mea] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function(d) {
        return x(Math.min(0, d.value));
      })
      .attr("y", function(d) {
        return yGrp(d.key);
      })
      .attr("width", function(d) {
        return Math.abs(x(d.value) - x(0));
      })
      .attr("height", yGrp.bandwidth())
      .attr("fill", function(d) {
     if(d.key === 'fear_score'){
          return '#DF0101'
        }else if(d.key === 'sunshine_score'){
          return '#23431b'
        }
      })
    // .on('mouseover', tip.show).on('mouseout', tip.hide);

    //Draw X Axis
    g.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(90)")
      .style("text-anchor", "start");

    //Draw Y Axis
    g.append("g")
      .attr("class", "y axis")
      .attr("transform", "translate(" + x(0) + ",0)")
      .call(
        d3.axisLeft(y).tickFormat(function(l) {
          return l.substring(0, 10);
        })
      );
      

    var legend = g
      .append("g")
      .attr("text-anchor", "end")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .selectAll("g")
      .data(meas)
      .enter()
      .append("g")
      .attr("transform", function(d, i) {
        return "translate(0," + i * 20 + ")";
      });


    legend
      .append("rect")
      .attr("x", width - 19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", z);

    legend
      .append("text")
      .attr("x", width - 24)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text(function(d) {
        return d;
      });

      //console.log("z:",z)
  }

  render() {
    return <div id="svg-element" style={{ marginBottom:0, height:'90%' }}></div>;
  }
}
export default BarChart;
