import React, { useEffect, useState } from "react";
import moment from "moment";
import BarChartGeneric from "../../../layout/BarChartGeneric";

export default function BarTable({ id, data, year }) {
  const [domain, setDomain] = useState(null);

  useEffect(() => {
    setDomain(getMaxMin());
  }, [data]);

  const getMaxMin = () => {
    let growths = data?.map((row) => {
      return row.previous == 0
        ? 0
        : Number(
            (((row?.current - row?.previous) / row.previous) * 100)?.toFixed(0)
          );
    });
    return [Math.min(...(growths || [])), Math.max(...(growths || []))];
  };

  return (
    <table className="tableFixed">
      <thead>
        <tr>
          <th style={{ fontWeight: "normal" }}>Month</th>
          <th style={{ fontWeight: "normal" }}>Current GDP</th>
          <th style={{ fontWeight: "normal" }}>Previous GDP</th>
          <th style={{ fontWeight: "normal" }}>Growth</th>
        </tr>
      </thead>
      <tbody style={{ height: 200, fontSize: 14 }}>
        {data?.map((row, i) => (
          <tr>
            <td>{moment.monthsShort(row.month - 1) + ", " + year.slice(2)}</td>
            <td>{row?.current?.toFixed(0)}</td>
            <td>{row?.previous?.toFixed(0)}</td>
            <td>
              {
                <BarChartGeneric
                  id={id + "-gdp-bar" + i}
                  width={70}
                  height={30}
                  dimensions={[{ text: "gdp" }]}
                  measures={[{ text: "data" }]}
                  data={[
                    {
                      data:
                        row.previous == 0
                          ? 0
                          : (
                              ((row?.current - row?.previous) / row.previous) *
                              100
                            )?.toFixed(0),
                      type: "gdp",
                    },
                  ]}
                  noX={true}
                  noXLabel={true}
                  noYLabel={true}
                  barNegPosLabel={true}
                  labelInBar={true}
                  hideLegend={true}
                  barFontSize={12}
                  margins={{ top: 1, bottom: 1, left: 1, right: 20 }}
                  xDomain={getMaxMin()}
                />
              }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
