import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGdpByType,
  getGdpCompany,
  getGdpMonthly,
  getGdpSegmented,
  getGdpYearly,
} from "../../../store/action/nlActions";
import LineChartGeneric from "../../layout/LineChartGeneric";
import StackBar from "./laylout/StackBar";
import IndicationCard from "./laylout/IndicationCard";
import GdpCard from "./laylout/GdpCard";
import FilterBlock from "./laylout/FilterBlock";
import moment from "moment";
import PieChartLegend from "../../layout/PieChartLegend";
import BarTable from "./laylout/BarTable";
import { specialColorMap } from "../../../config/constants";

export default function PeerDashboard() {
  const dispatch = useDispatch();

  const [myCompany, setMyCompany] = useState(null);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [segment, setSegment] = useState("");

  const assessToken = useSelector((state) => state?.auth?.accessToken);
  const userCompany = useSelector((state) => state?.auth?.config?.userCompany);
  const config = useSelector((state) => state?.auth?.config);
  const sectors = useSelector((state) => state?.SM?.sectorList);

  const gdpYearly = useSelector((state) => state?.nl?.gdpYearly);
  const gdpYearlyLoading = useSelector((state) => state?.nl?.gdpYearlyLoading);
  const gdpMonthly = useSelector((state) => state?.nl?.gdpMonthly);
  const gdpMonthlyLoading = useSelector(
    (state) => state?.nl?.gdpMonthlyLoading,
  );
  const gdpSegmented = useSelector((state) => state?.nl?.gdpSegmented);
  const gdpSegmentedLoading = useSelector(
    (state) => state?.nl?.gdpSegmentedLoading,
  );
  const gdpCompany = useSelector((state) => state?.nl?.gdpCompany);
  const gdpCompanyLoading = useSelector(
    (state) => state?.nl?.gdpCompanyLoading,
  );
  const gdpByType = useSelector((state) => state?.nl?.gdpByType);
  const gdpByTypeLoading = useSelector((state) => state?.nl?.gdpByTypeLoading);

  const nlSegs = useSelector((state) => state?.nl?.nlSegs);
  const nlYears = useSelector((state) => state?.nl?.nlStatYears);

  useEffect(() => {
    let curYear = null;
    if (nlYears && nlYears.length > 0) {
      curYear = nlYears[0].year;
      setYear(curYear);
    }
    let insSector = sectors?.find(
      (sec) => sec?.name?.toLowerCase() === "insurance",
    );
    let com = userCompany?.find(
      (com) => com?.detail?.sector_id === insSector?.id,
    );
    setMyCompany(com);
    fetchData(curYear, com, segment, month);
  }, []);

  useEffect(() => {
    fetchData(year, myCompany, segment, month);
  }, [year, myCompany, segment, month]);

  const getSecondaryCompanyWiseFiltered = (data) => {
    if (!myCompany) {
      return [];
    }
    let secs = myCompany?.secondaries?.map((sec) => {
      return sec.id;
    });
    return data?.filter((d) => secs.includes(d.companyId));
  };

  const getGrowthWithIndication = (data, com, year) => {
    console.log("INDICATION", data, com, year);
    let diff = 0.0;
    let type = "neutral";
    let cur = 0.0;
    let prev = 0.0;
    if (!data || (data && data?.length < 2)) {
      return { diff, type };
    } else {
      if (com) {
        cur = data?.find((d) => Number(d.year) === Number(year))?.company;
        prev = data?.find((d) => Number(d.year) === Number(year) - 1)?.company;
      } else {
        cur = data?.find((d) => Number(d.year) === Number(year))?.all;
        prev = data?.find((d) => Number(d.year) === Number(year) - 1)?.all;
      }

      if (cur && prev && prev !== 0) {
        diff = (((cur - prev) / prev) * 100).toFixed(2);
        if (diff < 0) {
          type = "down";
        } else {
          type = "up";
        }
        return { diff, type };
      } else {
        return { diff, type };
      }
    }
  };

  const onMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const onYearChange = (e) => {
    setYear(e.target.value);
  };
  const onSegmentChange = (e) => {
    setSegment(e.target.value);
  };

  const fetchData = (curYear, com, segmentId, month) => {
    dispatch(
      getGdpYearly(
        {
          month: month,
          segment_id: segmentId,
          year: curYear,
          company_id: com?.detail?.id,
        },
        assessToken,
      ),
    );
    dispatch(
      getGdpMonthly(
        {
          month: month,
          segment_id: segmentId,
          year: curYear,
          company_id: com?.detail?.id,
        },
        assessToken,
      ),
    );
    dispatch(
      getGdpSegmented(
        { month: month, segment_id: segmentId, year: curYear },
        assessToken,
      ),
    );
    dispatch(
      getGdpCompany(
        { month: month, segment_id: segmentId, year: curYear },
        assessToken,
      ),
    );
    dispatch(
      getGdpByType(
        { month: month, segment_id: segmentId, year: curYear },
        assessToken,
      ),
    );
  };
  console.log("USER COM", gdpCompany);

  return (
    <div className="col s12">
      <div id="sector-pane" className="col s4">
        <div className="card">
          <div className="card-content" style={{ padding: 10 }}>
            <div className="center-align">
              <strong>Sector Level Data</strong>
            </div>
            <div>
              <IndicationCard
                label="Sector Growth"
                value={getGrowthWithIndication(gdpYearly, null, year)}
              />
            </div>
            <div>
              <BarTable id="sec-gdp" data={gdpMonthly?.all || []} year={year} />
            </div>
            <div>
              <div style={{ marginTop: 25 }}>
                <strong>Growth Rate</strong>
              </div>
              <LineChartGeneric
                id="trend-all"
                data={
                  gdpYearly ? gdpYearly.slice(0, gdpYearly?.length - 1) : []
                }
                dimensions={[{ text: "year" }]}
                measures={[{ text: "all" }]}
                can_width={350}
                can_height={150}
                tooltip={false}
                margins={{ right: 1, bottom: 50, left: 1 }}
                legend={false}
                noYTick={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div id="company-pane" className="col s4">
        <div className="card">
          <div className="card-content" style={{ padding: 10 }}>
            <div className="center-align">
              <strong>My Data</strong>
            </div>
            <div>
              <IndicationCard
                label="My Growth"
                value={getGrowthWithIndication(gdpYearly, true, year)}
              />
            </div>
            <div>
              <BarTable
                id="com-gdp"
                data={gdpMonthly?.company || []}
                year={year}
              />
            </div>
            <div>
              <div style={{ marginTop: 25 }}>
                <strong>Growth Rate</strong>
              </div>
              <LineChartGeneric
                id="trend-comp"
                data={
                  gdpYearly ? gdpYearly.slice(0, gdpYearly?.length - 1) : []
                }
                dimensions={[{ text: "year" }]}
                measures={[{ text: "company" }]}
                can_width={350}
                can_height={150}
                tooltip={false}
                labelInBar={true}
                margins={{ right: 1, bottom: 50, left: 1 }}
                legend={false}
                noYTick={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="other-pane" className="col s4">
        <div className="card">
          <div className="card-content" style={{ padding: 10 }}>
            <div className="row" style={{ marginBottom: 0 }}>
              <FilterBlock
                months={moment.months().map((month, i) => {
                  return { value: i + 1, label: month };
                })}
                years={nlYears}
                segments={nlSegs}
                onMonthChange={onMonthChange}
                onYearChange={onYearChange}
                onSegmentChange={onSegmentChange}
                selectedMonth={month}
                selectedYear={year}
                selectedSegment={segment}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-content" style={{ padding: 10 }}>
            <div className="center-align">
              <strong>Peer data</strong>
            </div>
            <div>
              <table className="tableFixed">
                <thead>
                  <tr>
                    <th style={{ fontWeight: "normal" }}>Top 10 Insurers</th>
                    <th style={{ fontWeight: "normal" }}>Growth</th>
                  </tr>
                </thead>
                <tbody style={{ height: 150 }}>
                  {gdpCompany?.map((data) => (
                    <tr>
                      <td>{data.company}</td>
                      <td>{data?.growth?.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div style={{ paddingTop: 10 }}>
              <strong>Portfolio mix</strong>
            </div>
            <div>
              <StackBar
                id="top-5-stateData"
                data={getSecondaryCompanyWiseFiltered(gdpSegmented)}
                dimensions={[{ text: "company" }]}
                measures={[{ text: "data" }]}
                group={"segment"}
                can_height={220}
                can_width={360}
                hideLegend={false}
                noYTick={true}
                margins={{ left: 1 }}
                colorMap={specialColorMap}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col s3" style={{ marginTop: -50 }}>
        <PieChartLegend
          id="gdp-by-type"
          height={200}
          width={200}
          data={
            gdpByType
              ? gdpByType?.map((d) => {
                  return { type: d?.type, data: d?.data?.toFixed(2) };
                })
              : []
          }
          dimensions={[{ text: "type" }]}
          measures={[{ text: "data" }]}
          fontSize={8}
          legendSize={8}
          //   noLegend={true}
        />
      </div> */}
    </div>
  );
}
