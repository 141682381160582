const initState = {
  statLoading: false,
  statSuccess: false,
  statError: false,
  stat: null,

  userStatLoading: false,
  userStatSuccess: false,
  userStatError: false,
  userStat: null,

  newsStatLoading: false,
  newsStatSuccess: false,
  newsStatError: false,
  newsStat: null,
  crawlStart: false,
  newsAnalysisStart: false,

  addCompanyLoading: false,
  addCompanySuccess: false,
  addCompanyError: false,
  addCompanyMessage: null,
  addedCompanyNews:[],

  customArLoading:false,
  customArSuccess:false,
  customArError:false,
  customArMessage:null,
  customAr:null,

  addCompanyKeywordLoading: false,
  addCompanyKeywordSuccess: false,
  addCompanyKeywordError: false,
  addCompanyKeywordMessage: null,


  removeCompanyKeywordLoading: false,
  removeCompanyKeywordSuccess: false,
  removeCompanyKeywordError: false,
  removeCompanyKeywordMessage: null,

  editCompanyKeywordLoading: false,
  editCompanyKeywordSuccess: false,
  editCompanyKeywordError: false,
  editCompanyKeywordMessage: null,
  
  editCompanyLoading: false,
  editCompanySuccess: false,
  editCompanyError: false,
  editCompanyMessage: null,

  sentenceBank: [],
  sentenceBankLoading: false,
  sentenceBankSuccess: false,
  sentenceBankError: false,
  sentenceBankMessage: null,

  updateSentenceBankLoading: false,
  updateSentenceBankSuccess: false,
  updateSentenceBankError: false,
  updateSentenceBankMessage: "",
};

const AdminReducer = (state = initState, action) => {
  switch (action.type) {
    case "STAT_FETCH_LOADING":
      return {
        ...state,
        statLoading: true,
        statSuccess: false,
        statError: false,
        stat: null,
      };

    case "STAT_FETCH_SUCCESS":
      return {
        ...state,
        statLoading: false,
        statSuccess: true,
        statError: false,
        stat: action.payload,
      };

    case "STAT_FETCH_ERROR":
      return {
        ...state,
        extraStatLoading: false,
        extraStatSuccess: false,
        extraStatError: true,
        extraStat: null,
      };
      
    case "EXTRA_STAT_FETCH_LOADING":
      return {
        ...state,
        extraStatLoading: true,
        extraStatSuccess: false,
        extraStatError: false,
        extraStat: null,
      };

    case "EXTRA_STAT_FETCH_SUCCESS":
      return {
        ...state,
        extraStatLoading: false,
        extraStatSuccess: true,
        extraStatError: false,
        extraStat: action.payload,
      };

    case "EXTRA_STAT_FETCH_ERROR":
      return {
        ...state,
        statLoading: false,
        statSuccess: false,
        statError: true,
        stat: null,
      };

    case "USERS_STAT_LOADING":
      return {
        ...state,
        userStatLoading: true,
        userStatSuccess: false,
        userStatError: false,
        userStat: null,
      };

    case "USERS_STAT_SUCCESS":
      return {
        ...state,
        userStatLoading: false,
        userStatSuccess: true,
        userStatError: false,
        userStat: action.payload,
      };

    case "USERS_STAT_ERROR":
      return {
        ...state,
        userStatLoading: false,
        userStatSuccess: false,
        userStatError: true,
        userStat: null,
      };
    case "NEWS_STAT_FETCH_LOADING":
      return {
        ...state,
        newsStatLoading: true,
        newsStatSuccess: false,
        newsStatError: false,
        newsStat: null,
      };

    case "NEWS_STAT_FETCH_SUCCESS":
      return {
        ...state,
        newsStatLoading: false,
        newsStatSuccess: true,
        newsStatError: false,
        newsStat: action.payload,
      };

    case "NEWS_STAT_FETCH_ERROR":
      return {
        ...state,
        newsStatLoading: false,
        newsStatSuccess: false,
        newsStatError: true,
        newsStat: null,
      };

    case "API_TRACK_FETCH_LOADING":
      return {
        ...state,
        apiStatLoading: true,
        apiStatSuccess: false,
        apiStatError: false,
        apiStat: null,
      };

    case "API_TRACK_FETCH_SUCCESS":
      return {
        ...state,
        apiStatLoading: false,
        apiStatSuccess: true,
        apiStatError: false,
        apiStat: action.payload,
      };

    case "API_TRACK_FETCH_ERROR":
      return {
        ...state,
        apiStatLoading: false,
        apiStatSuccess: false,
        apiStatError: true,
        apiStat: null,
      };

    case "ADD_COMPANY_LOADING":
      return {
        ...state,
        addCompanyLoading: true,
        addCompanySuccess: false,
        addCompanyError: false,
        addCompanyMessage: action.payload.message,
        addedCompanyNews:[]
      };

    case "ADD_COMPANY_SUCCESS":
      return {
        ...state,
        addCompanyLoading: false,
        addCompanySuccess: true,
        addCompanyError: false,
        addCompanyMessage: action.payload.message,
        addedCompanyNews: action.payload.newsList
      };

    case "ADD_COMPANY_ERROR":
      return {
        ...state,
        addCompanyLoading: false,
        addCompanySuccess: false,
        addCompanyError: true,
        addCompanyMessage: action.message,
        addedCompanyNews:[]
      };

    case "UPLOAD_AR_LOADING":
      return {
        ...state,
        customArLoading: true,
        customArSuccess: false,
        customArError: false,
      };

    case "UPLOAD_AR_SUCCESSFUL":
      return {
        ...state,
        customArLoading: false,
        customArSuccess: true,
        customArError: false,
        customArMessage: action.data.message,
        customAr: action.data.file,
      };

    case "UPLOAD_AR_ERROR":
      return {
        ...state,
        customArLoading: false,
        customArSuccess: false,
        customArError: true,
        customArMessage: action.message,
      };


    case "COMPANY_REQUESTS_FETCH_LOADING":
      return {
        ...state,
        companyRequestsLoading: true,
        companyRequestsSuccess: false,
        companyRequestsError: false,
        companyRequests: null,
      };

    case "COMPANY_REQUESTS_FETCH_SUCCESS":
      return {
        ...state,
        companyRequestsLoading: false,
        companyRequestsSuccess: true,
        companyRequestsError: false,
        companyRequests: action.payload,
      };

    case "COMPANY_REQUESTS_FETCH_ERROR":
      return {
        ...state,
        companyRequestsLoading: false,
        companyRequestsSuccess: false,
        companyRequestsError: true,
        companyRequests: null,
      };

    case "VERIFY_COMPANY_LOADING":
      return {
        ...state,
        verifyCompanyLoading: true,
        verifyCompanySuccess: false,
        verifyCompanyError: false,
        verifyCompanyMessage: action.message,
      };

    case "VERIFY_COMPANY_SUCCESS":
      return {
        ...state,
        verifyCompanyLoading: false,
        verifyCompanySuccess: true,
        verifyCompanyError: false,
        verifyCompanyMessage: action.message,
      };

    case "VERIFY_COMPANY_ERROR":
      return {
        ...state,
        verifyCompanyLoading: false,
        verifyCompanySuccess: false,
        verifyCompanyError: true,
        verifyCompanyMessage: action.message,
      };
  
      
    case "COMPANY_KEYWORD_LIST_FETCH_LOADING":
      return {
        ...state,
        companiesLoading: true,
        companiesSuccess: false,
        companiesError: false,
        companies: null,
      };

    case "COMPANY_KEYWORD_LIST_FETCH_SUCCESS":
      return {
        ...state,
        companiesLoading: false,
        companiesSuccess: true,
        companiesError: false,
        companies: action.payload,
      };

    case "COMPANY_KEYWORD_LIST_FETCH_ERROR":
      return {
        ...state,
        companiesLoading: false,
        companiesSuccess: false,
        companiesError: true,
        companies: null,
      };

    case "EDIT_COMPANY_LOADING":
      return {
        ...state,
        editCompanyLoading: true,
        editCompanySuccess: false,
        editCompanyError: false,
        editCompanyMessage: action.message,
      };

    case "EDIT_COMPANY_SUCCESS":
      return {
        ...state,
        editCompanyLoading: false,
        editCompanySuccess: true,
        editCompanyError: false,
        editCompanyMessage: action.message,
      };

    case "EDIT_COMPANY_ERROR":
      return {
        ...state,
        editCompanyLoading: false,
        editCompanySuccess: false,
        editCompanyError: true,
        editCompanyMessage: action.message,
      };

    
    case "EDIT_COMPANY_KEYWORD_LOADING":
      return {
        ...state,
        editCompanyKeywordLoading: true,
        editCompanyKeywordSuccess: false,
        editCompanyKeywordError: false,
        editCompanyKeywordMessage: action.message,
      };

    case "EDIT_COMPANY_KEYWORD_SUCCESS":
      return {
        ...state,
        editCompanyKeywordLoading: false,
        editCompanyKeywordSuccess: true,
        editCompanyKeywordError: false,
        editCompanyKeywordMessage: action.message,
      };

    case "EDIT_COMPANY_KEYWORD_ERROR":
      return {
        ...state,
        editCompanyKeywordLoading: false,
        editCompanyKeywordSuccess: false,
        editCompanyKeywordError: true,
        editCompanyKeywordMessage: action.message,
      };

    
    case "REMOVE_COMPANY_KEYWORD_LOADING":
      return {
        ...state,
        removeCompanyKeywordLoading: true,
        removeCompanyKeywordSuccess: false,
        removeCompanyKeywordError: false,
        removeCompanyKeywordMessage: action.message,
      };

    case "REMOVE_COMPANY_KEYWORD_SUCCESS":
      return {
        ...state,
        removeCompanyKeywordLoading: false,
        removeCompanyKeywordSuccess: true,
        removeCompanyKeywordError: false,
        removeCompanyKeywordMessage: action.message,
      };

    case "REMOVE_COMPANY_KEYWORD_ERROR":
      return {
        ...state,
        removeCompanyKeywordLoading: false,
        removeCompanyKeywordSuccess: false,
        removeCompanyKeywordError: true,
        removeCompanyKeywordMessage: action.message,
      };


    case "ADD_COMPANY_KEYWORD_LOADING":
      return {
        ...state,
        addCompanyKeywordLoading: true,
        addCompanyKeywordSuccess: false,
        addCompanyKeywordError: false,
        addCompanyKeywordMessage: action.message,
      };

    case "ADD_COMPANY_KEYWORD_SUCCESS":
      return {
        ...state,
        addCompanyKeywordLoading: false,
        addCompanyKeywordSuccess: true,
        addCompanyKeywordError: false,
        addCompanyKeywordMessage: action.message,
      };

    case "ADD_COMPANY_KEYWORD_ERROR":
      return {
        ...state,
        addCompanyKeywordLoading: false,
        addCompanyKeywordSuccess: false,
        addCompanyKeywordError: true,
        addCompanyKeywordMessage: action.message,
      };

      
    case "FETCH_SENTENCE_BANK_LOADING":
      return {
        ...state,
        sentenceBank: [],
        sentenceBankLoading: true,
        sentenceBankSuccess: false,
        sentenceBankError: false,
        sentenceBankMessage: action.message,
      };

    case "FETCH_SENTENCE_BANK_SUCCESS":
      return {
        ...state,
        sentenceBank: action.data,
        sentenceBankLoading: false,
        sentenceBankSuccess: true,
        sentenceBankError: false,
        sentenceBankMessage: action.message,
      };

    case "FETCH_SENTENCE_BANK_ERROR":
      return {
        ...state,
        sentenceBankLoading: false,
        sentenceBankSuccess: false,
        sentenceBankError: true,
        sentenceBankMessage: action.message,
      };


    case "UPDATE_SENTENCE_BANK_LOADING":
      return {
        ...state,
        updateSentenceBankLoading: true,
        updateSentenceBankSuccess: false,
        updateSentenceBankError: false,
        updateSentenceBankMessage: action.message,
      };

    case "UPDATE_SENTENCE_BANK_SUCCESS":
      return {
        ...state,
        updateSentenceBankLoading: false,
        updateSentenceBankSuccess: true,
        updateSentenceBankError: false,
        updateSentenceBankMessage: action.message,
      };

    case "UPDATE_SENTENCE_BANK_ERROR":
      return {
        ...state,
        updateSentenceBankLoading: false,
        updateSentenceBankSuccess: false,
        updateSentenceBankError: true,
        updateSentenceBankMessage: action.message,
      };


    case "CRAWL_STARTED":
      alert("Crawling stared... refresh api tracks to see progress.");
      return {
        ...state,
        crawlStart: true,
      };
    case "NEWS_ANALYSIS_STARTED":
      alert("Analysis stared... refresh api tracks to see progress.");
      return {
        ...state,
        newsAnalysisStart: true,
      };
    case "INITIAL_STATE":
      return initState;
    case "HOME_CLICK":
      return initState;
    default:
      return state;
  }
};

export default AdminReducer;
