import React, { Component } from "react";
import BarChart from "../layout/BarChart";
import { connect } from "react-redux";
import { initResponse, submitPD } from "../../store/action/uploadAction";
import Dashboard from "./Dashboard";
import { Redirect } from "react-router-dom";
import moment from "moment";
import BarChartAlert from "../layout/BarChartAlert";
import AuditModal from "../layout/AuditModal";
import M from "materialize-css";
import News from "../layout/News";
import {
  getAccPolChange,
  getAuditYears,
  submitAudit,
} from "../../store/action/auditAction";
import "./style.css";
import PolicyChange from "../layout/PolicyChange";

class AccPolicyDashboard extends Component {
  state = {
    width: 700,
    height: 500,
    clickType: null,
    name: "",
    year: null,
  };

  componentDidMount() {
    var elems = document.querySelectorAll(".modal");
    var instances = M.Modal.init(elems, {});
    document.body.style.overflow = "visible";
    if (this.props.initData && this.props.initData.year) {
      this.setState({
        year: this.props.initData.year,
      });
    }

    if (this.props.auditData) {
      this.props.GetAuditYears(
        this.props.auditData.Company.id,
        this.props.auth.accessToken,
      );
    }
  }
  handleBack = () => {
    this.props.initResponse();
    this.props.history.push("/");
  };
  onYearChange = (year) => {
    const { auth } = this.props;
    this.setState({ year });
    if (
      this.props.yearData &&
      this.props.yearData.years &&
      this.props.yearData.years.includes(Number(year))
    ) {
      this.props.SubmitPD(this.state.companyData, year, auth.accessToken);
    } else {
      alert("Data Not found...");
      // //console.log(this.props.yearData.years, year);
    }
  };

  OnClickAudit = () => {
    this.setState({ clickType: "firm", name: "S.B. Billimoria" });
  };
  OnClickAuditPartner = () => {
    this.setState({ clickType: "partner", name: "J.F. Billimoria" });
  };

  onSwitchCompany = (id) => {
    var cId = id;
    var secIds = [this.props.auditData.Company.id];
    var year = this.props.auditData.year;
    console.log("Error", cId, "Error", secIds, "Error", year);
    for (var i = 0; i < this.props.peerData.length; i++) {
      if (this.props.peerData[i].company_id != cId) {
        secIds.push(this.props.peerData[i].company_id);
      }
    }
    if (cId && secIds.length < 5) {
      this.props.SubmitAudit(
        cId,
        year,
        "company",
        {},
        this.props.auth.accessToken,
      );
      this.props.GetAccPolChange(
        cId,
        secIds,
        year,
        this.props.auth.accessToken,
      );
    } else {
      console.log("Error", cId, "Error", secIds, "Error", year);
      alert("PLease select company, year and max 5 secondary companies");
    }
  };

  render() {
    const { auth, auditData, auditYear, polChange, company, polUrl } =
      this.props;
    if (!auth.uid) return <Redirect to="/SignIn" />;
    console.log("props", this.props);
    return (
      <div className="container valign-wrapper" style={{ width: "90%" }}>
        {/* {auditData&& */}
        {
          <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
            <div className="col s12 m12 l12">
              <div
                className="card cardDiv"
                style={{
                  // backgroundColor: "#eee",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div className="card-content">
                  {/* <h4 className="card-title" style={{ color: "#23431b",fontWeight:'bold', marginTop:0, marginBottom:0 }}>
                      <center >{uploadResponse?uploadResponse.company_name:''}</center>
                    </h4> */}
                  <div className="row">
                    <div
                      className="col s8 card-title"
                      style={{ marginBottom: 0 }}
                    >
                      <strong style={{ color: "#2d49a6" }}>
                        Accounting Policy:
                        {auditData ? " " + auditData.Company.name : ""}{" "}
                      </strong>
                    </div>
                    <div className="col s4">
                      <div className="col s4 acc-pol-audit-detail ">
                        Audit Firm
                      </div>
                      <div className="col s8 acc-pol-audit-detail">
                        <a
                          className="modal-trigger"
                          href="#audit-modal"
                          onClick={this.OnClickAudit}
                        >
                          {auditData ? auditData.AuditFirm.name : ""}
                        </a>
                      </div>

                      <div
                        className="col s4 acc-pol-audit-detail"
                        style={{ fontWeight: "bold" }}
                      >
                        Partner
                      </div>
                      <div className="col s8 acc-pol-audit-detail">
                        <a
                          className="modal-trigger"
                          href="#audit-modal"
                          onClick={this.OnClickAuditPartner}
                        >
                          {auditData ? auditData.AuditPartner.name : ""}
                        </a>
                      </div>

                      {/* <div className="col s4 acc-pol-audit-detail" style={{fontWeight:'bold'}}>Place</div>
                      <div className="col s6">{auditData.audit_location}</div>

                      <div className="col s4 acc-pol-audit-detail" style={{fontWeight:'bold'}}>Date</div>
                      <div className="col s6">{
                        auditData.audit_date?
                      moment( auditData.audit_date).format('DD/MM/YYYY'):""
                      }</div> */}

                      {/* <div className="col s4 acc-pol-audit-detail" style={{fontWeight:'bold'}}>No. of Year</div>
                      <div className="col s8">{auditYear.filter(ay => ay.audit_firm_id == auditData.audit_firm_id).length}</div> */}
                    </div>
                    <div
                      className="col s12 m12 l12"
                      style={{ marginBottom: 0 }}
                    >
                      <table
                        className="pdTableY"
                        style={{ marginLeft: 0, fontSize: 14 }}
                      >
                        <thead style={{ width: "100%", position: "sticky" }}>
                          {this.props.yearData &&
                            this.props.yearData.years &&
                            this.props.yearData.years.map((year) => (
                              <th
                                key={year}
                                style={
                                  Number(year) == Number(this.state.year)
                                    ? {
                                        backgroundColor: "#2d49a6",
                                        color: "white",
                                      }
                                    : { color: "#2d49a6" }
                                }
                              >
                                <b>{year}</b>
                              </th>
                            ))}
                        </thead>
                        <tbody>
                          <tr
                            style={{
                              border: 1,
                              borderStyle: "solid",
                              borderColor: "#2d49a6",
                            }}
                          >
                            {this.props.yearData &&
                              this.props.yearData.years &&
                              this.props.yearData.years.map((year) => (
                                <td
                                  key={year}
                                  style={
                                    Number(year) == Number(this.state.year)
                                      ? {
                                          backgroundColor: "#2d49a6",
                                          color: "white",
                                        }
                                      : { color: "#2d49a6" }
                                  }
                                  id={year}
                                >
                                  <i
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      this.onYearChange(year);
                                    }}
                                    className="material-icons"
                                  >
                                    chevron_right
                                  </i>
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="row"
                    // style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <div
                      className="col s7"
                      // style={{ marginTop: 0, marginBottom: 0 }}
                    >
                      <iframe src={polUrl} width="100%" height="600px"></iframe>
                    </div>
                    <div className="col s5">
                      <div className="row">
                        <p>
                          <strong>Added Sentences</strong>
                        </p>
                      </div>
                      <div
                        className="row add-acc-policy"
                        style={{ height: 240, overflowY: "auto" }}
                      >
                        {polChange && polChange.added
                          ? Object.entries(polChange.added).map(
                              ([key, sens]) => (
                                <p>
                                  <p>
                                    <strong>{key}</strong>
                                  </p>
                                  {sens.map((s) => (
                                    <p>{s}</p>
                                  ))}
                                </p>
                              ),
                            )
                          : null}
                      </div>

                      <div className="row">
                        <p>
                          <strong>Removed Sentences</strong>
                        </p>
                      </div>
                      <div
                        className="row remove-acc-policy"
                        style={{ height: 240, overflowY: "auto" }}
                      >
                        {polChange && polChange.removed
                          ? Object.entries(polChange.removed).map(
                              ([key, sens]) => (
                                <p>
                                  <p>
                                    <strong>{key}</strong>
                                  </p>
                                  {sens.map((s) => (
                                    <p>{s}</p>
                                  ))}
                                </p>
                              ),
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {this.props.peerData.map((p) => (
                      <PolicyChange
                        onSwitch={this.onSwitchCompany}
                        id={p.company_id}
                        headings={
                          p.change
                            ? Object.keys(p.change.added).concat(
                                Object.keys(p.change.removed),
                              )
                            : []
                        }
                        company={p.name}
                        percentage={p.change_count}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditData: state.audit.auditData
      ? state.audit.auditData.find((d) => d.id == state.audit.auditScoreId)
      : null,
    auth: state.auth,
    initData: state.NIS.initData,
    auditYear: state.audit.auditYearsData,
    polUrl: state.audit.accPolChange
      ? state.audit.accPolChange.accPolicy.acc_pol_url
      : "",
    polChange: state.audit.accPolChange
      ? state.audit.accPolChange.accPolicy.change_in_acc_pol
      : "",
    company: state.audit.accPolChange ? state.audit.accPolChange.company : "",
    TopNews:
      state.NIS && state.NIS.NewsNISTopNews ? state.NIS.NewsNISTopNews : null,
    peerData:
      state.audit.accPolChange && state.audit.accPolChange.secondary
        ? state.audit.accPolChange.secondary
        : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initResponse: () => {
      dispatch(initResponse());
    },
    SubmitPD: (company, year, token) => {
      dispatch(submitPD(company, year, token));
    },
    GetAuditYears: (company, token) => {
      dispatch(getAuditYears(company, token));
    },
    SubmitAudit: (id, year, type, extra, token) => {
      dispatch(submitAudit(id, year, type, extra, token));
    },
    GetAccPolChange: (cid, secIds, year, token) => {
      dispatch(getAccPolChange(cid, secIds, year, token));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccPolicyDashboard);
